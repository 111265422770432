export default {
	style: function(state){
		return state.style;
	},
  sidebar: function(state){
    return state.sidebar;
  },
  tab: function(state){
    return state.tab;
  }
}
