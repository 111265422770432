export default () => ({
  indx: {}, /// all the main data loaded by default
  conf: {}, /// project global configuration
  cache: {}, /// all the data tahat comes while website runing
  tmp: {}, /// temporary data for short use

  showSite: false, /// start showing site// waits for page loades

  content:{}, /// to store all the content types data
  current_content: {}, /// to store all the content types data
  current_content_buffer: {}, /// to store current content before use new values
  current_content_request_status: '', /// status for current content loaded/// 'pending' while request; 'done' after loaded content from server; 'error' if there is server error
  current_content_last_request: '', /// latest current content request stringify. /// to controll request dublications

  loader: { main: 0, form: 0, button: 0, loan_calculator: 0, currency_calculator: 0 ,deposit_calculator: 0,compare_products: 0}, /// loaders statuses over components

  selected_menu: {},
  selected_menu_locales: {},
  locale: false,
  translations: '',
  siteMode: 'production',
  xRates: [],

  menuStatus: { level1: 1, level2: 4, level3: 0, level4: 0  },
  breadcrumbsLeveled: { }, /// lvl1: 0, lvl2: 0, lvl3: 0, lvl4: 0
  menuIndexById: { }, /// object with menu id: menu index /// just for fast searching
  offset: 0,
  fixedHead: false,
  showMorePopup: false,
  showSocialPopup: false,
  showFormPopup: {
    state: false,
    to: null,
  },
  popupDoctorInfo: {},
});
