<template>
  <div class="home" :class="animateElements ? 'animate-elements' : ''">
    <div
      class="thanks-container"
      :class="$store.state.layout.sidebar ? 'active' : ''"
    >
      <Sidebar />
      <Tabs />
      <div class="thanks-container__pages-container">
        <transition-group name="fade" mode="out-in">
          <Doctors v-if="$store.state.layout.tab == 0" :key="0" />
ccc        </transition-group>
      </div>
    </div>
    <portal-target name="destination">

    </portal-target>
    <!--<popupWrapper />-->
  </div>
</template>

<script>
import Sidebar from "../components/Sidebar";
import Tabs from "../components/Tabs";
import popupWrapper from "../components/popupWrapper";
import Doctors from "../components/Doctors";
import ListPage from "../components/ListPage";

export default {
  name: "Home",
  components: {
    Sidebar,
    Tabs,
    popupWrapper,
    Doctors,
    ListPage
  },
  data() {
    return {
      animateElements: false
    };
  },
  mounted() {
    setTimeout(() => {
      this.animateElements = true
    },300)
  }
};
</script>
<style>
  .fade-enter-active, .fade-leave-active {
    transition: opacity .6s cubic-bezier(0.7, 0.01, 0.29, 1);
  }

  .fade-enter, .fade-leave-to
  {
    transition: opacity .6s cubic-bezier(0.7, 0.01, 0.29, 1);
    opacity: 0;
  }
  .fade-enter-active{
    transition-delay: .6s;
  }
</style>
