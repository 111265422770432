import Vue from 'vue';
import Vuex from 'vuex';
import header from './header';
import layout from './layout';
import modal from './modal';
import main from './main';
import popup from './popup';


Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    main,
    header,
    layout,
    modal,
    popup
  }
});
