export default {
	SET_LAYOUT_STYLE(state, payload){
		state.style = payload;
	},
  SET_SIDEBAR(state, stat){
    state.sidebar = stat;
  },
  SET_TAB(state, tab){
    state.tab = tab;
  },
}
