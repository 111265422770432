<template>
  <div class="tab-width" @click="tabClicker(3)">
    <div
      class="doctor-tab-title noto-bold f-14"
      :class="{ current: $store.state.layout.tab == 3 }"
    >
      {{$fn.tr('პაციენტების სტატისტიკა')}}
    </div>
    <div
      class="patient-statistic"
      :class="{ current: $store.state.layout.tab == 3 }"
    >
      <div class="patient-info">
        <svg xmlns="http://www.w3.org/2000/svg" width="45.919" height="40">
          <g fill="#00aea9">
            <path
              data-name="Path 14"
              d="M22.957 15.605a7.8 7.8 0 1 0-7.8-7.8 7.812 7.812 0 0 0 7.8 7.8zm0-12.915a5.112 5.112 0 1 1-5.112 5.112 5.118 5.118 0 0 1 5.112-5.111z"
            />
            <path
              data-name="Path 15"
              d="M38.745 15.605a4.933 4.933 0 1 0-4.933-4.932 4.938 4.938 0 0 0 4.933 4.932zm0-7.175a2.242 2.242 0 1 1-2.242 2.242 2.245 2.245 0 0 1 2.242-2.241z"
            />
            <path
              data-name="Path 16"
              d="M39.83 18.655h-2.081a6.1 6.1 0 0 0-5.533 3.529 10.828 10.828 0 0 0-8-3.529h-2.51a10.828 10.828 0 0 0-8 3.529 6.1 6.1 0 0 0-5.536-3.529H6.09A6.078 6.078 0 0 0 0 24.708v9.795a2.636 2.636 0 0 0 2.638 2.628h8.227a3.145 3.145 0 0 0 3.129 2.87h17.932a3.145 3.145 0 0 0 3.129-2.87h8.133a2.729 2.729 0 0 0 2.732-2.721v-9.7a6.078 6.078 0 0 0-6.09-6.055zM2.691 24.708a3.385 3.385 0 0 1 3.4-3.362H8.17a3.385 3.385 0 0 1 3.4 3.362v.921c-.9 2.356-.717 3.767-.717 8.811H2.691zm29.686 12.147a.451.451 0 0 1-.45.45H13.993a.451.451 0 0 1-.45-.45v-7.35a8.173 8.173 0 0 1 8.163-8.163h2.507a8.173 8.173 0 0 1 8.163 8.163zm10.852-2.45c0 .046.5.031-8.161.031 0-5.081.186-6.459-.717-8.811v-.921a3.385 3.385 0 0 1 3.4-3.362h2.079a3.385 3.385 0 0 1 3.4 3.362z"
            />
            <path
              data-name="Path 17"
              d="M7.175 15.605a4.933 4.933 0 1 0-4.933-4.932 4.938 4.938 0 0 0 4.933 4.932zm0-7.175a2.242 2.242 0 1 1-2.242 2.242 2.245 2.245 0 0 1 2.242-2.241z"
            />
          </g>
        </svg>
        <animated-number
          :value="patientsCount"
          :formatValue="formatedNumber"
          :duration="2000"
        />
        <div class="patient-number noto-bold"></div>
        <div class="ft-16 noto-bold">   {{$fn.tr('გამოჯამთელებული პაციენტი')}}</div>
      </div>
     <!--  <div class="about-statistic">
        <div class="fn-14 noto-bold statistic-title">{{$fn.tr('სტატისტიკის შესახებ')}}</div>
        <div class="fn-14 noto-regular">
          {{$fn.tr('სტატისტიკის შესახებ ტექსტი')}}
        </div>
      </div> -->
    </div>
  </div>
</template>
<script>
import AnimatedNumber from "animated-number-vue";
import { mapMutations } from "vuex";
export default {
  components: {
    AnimatedNumber
  },
  data() {
    return {
      value: _.get(this.$store.state.main.indx.feedback_counts,'patients_count.count')
    };
  },
  computed:{
    patientsCount(){
      return _.get(this.$store.state.main.indx.feedback_counts,'patients_count.count').replaceAll(',', '')

    }
  },
  methods: {
    formatedNumber(value) {
      return `<div class="patient-number noto-bold">${_.get(this.$store.state.main.indx.feedback_counts,'patients_count.count')}</div>`;
    },
    ...mapMutations({
      setTab: "layout/SET_TAB"
    }),
    tabClicker(num){
      if(this.$store.state.layout.tab != 1){
        this.setTab(num)
      }
    }
  }
};
</script>
<style>
.patient-number {
  color: #1aaa9d;
  font-size: 60px;
}
</style>
<style scoped>
.patient-statistic {
  border: 2px solid #d4d4d4;
  opacity: 0.5;
  border-radius: 10px;
  padding: 10px 10px 10px 20px;
  width: 100%;
  height: 100%;
  max-height: 800px;
  overflow: hidden;
}
.patient-statistic.current {
  opacity: 1;
}
.doctor-tab-title {
  opacity: 0.5;
}
.doctor-tab-title.current {
  opacity: 1;
}
.patient-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
  padding-bottom: 100px;
  border-bottom: 2px solid #f7f8f8;
}

.about-statistic {
  text-align: center;
  margin-top: 100px;
  margin-bottom: 100px;
}
.statistic-title {
  margin-bottom: 20px;
}
@media (max-width: 1599px) {
  .patient-info {
    margin-top: 50px;
    padding-bottom: 50px;
  }
  .about-statistic {
    margin-top: 50px;
    margin-bottom: 50px;
  }
}
@media (max-width: 1365px) {
  .patient-statistic {
    height: auto;
  }
}
@media (max-width: 767px) {
  .patient-statistic {
    border: none;
    padding: 0;
  }
}
</style>
