<template>
  <div class="thanks-container__header" :class="noDelay ? 'noDelay' : ''">
    <div class="thanks-container__header-inner">
      <div @click="tabClicker(0)" class="thanks-container__header-item " :class="$store.state.layout.tab == 0 ? 'active' : ''">
        <div class="grad"></div>
        <div class="tabArrow">
          <svg xmlns="http://www.w3.org/2000/svg" width="14.188" height="8.761" viewBox="0 0 14.188 8.761">
            <path id="Icon_material-keyboard-arrow-down" data-name="Icon material-keyboard-arrow-down" d="M10.667,11.76l5.427,5.415,5.427-5.415,1.667,1.667-7.094,7.094L9,13.427Z" transform="translate(-9 -11.76)" fill="#1aaa9d"/>
          </svg>

        </div>
        <div class="thanks-container__header-item-inner">
          <svg xmlns="http://www.w3.org/2000/svg" width="19.336" height="30" viewBox="0 0 19.336 30">
            <g id="wedding" transform="translate(-91.005 0)">
              <path id="Path_35" data-name="Path 35" d="M158.944,192.388a.879.879,0,0,0,1.243,0l4.35-4.35a3.516,3.516,0,1,0-4.972-4.972,3.516,3.516,0,1,0-4.972,4.972Zm-3.107-8.079a1.76,1.76,0,0,1,2.486,0l.621.621a.879.879,0,0,0,1.243,0l.621-.621a1.758,1.758,0,1,1,2.486,2.486l-3.729,3.729-3.729-3.729A1.76,1.76,0,0,1,155.837,184.309Z" transform="translate(-58.893 -171.371)" fill="#00aea9"/>
              <path id="Path_36" data-name="Path 36" d="M109.462,5.391h-.879V.88A.879.879,0,0,0,107.42.048L91.6,5.438l-.02.008A.878.878,0,0,0,91,6.27V29.121a.879.879,0,0,0,.879.879h17.578a.879.879,0,0,0,.879-.879V6.27a.879.879,0,0,0-.879-.879Zm-2.637-3.284V5.391H97.188Zm1.758,26.135H92.763V7.149h15.82Z" transform="translate(0 0)" fill="#00aea9"/>
              <path id="Path_37" data-name="Path 37" d="M188.915,392.01h-7.031a.879.879,0,1,0,0,1.758h7.031a.879.879,0,1,0,0-1.758Z" transform="translate(-84.727 -369.041)" fill="#00aea9"/>
            </g>
          </svg>
          <div class="descr">
            <div class="ttl upper-1">{{_.get($store.state.main.indx,'home_content.home_content.original.primary.data.listCount')}} <div class="ttlInTtl upper-1">{{$fn.tr('ისტორია')}}</div></div>
            <div class="subttl">{{$fn.tr('ისტორია')}}</div>
          </div>
        </div>
      </div>
      <div @click="tabClicker(1)" class="thanks-container__header-item" :class="$store.state.layout.tab == 1 ? 'active' : ''">
        <div class="grad"></div>
        <div class="tabArrow">
          <svg xmlns="http://www.w3.org/2000/svg" width="14.188" height="8.761" viewBox="0 0 14.188 8.761">
            <path id="Icon_material-keyboard-arrow-down" data-name="Icon material-keyboard-arrow-down" d="M10.667,11.76l5.427,5.415,5.427-5.415,1.667,1.667-7.094,7.094L9,13.427Z" transform="translate(-9 -11.76)" fill="#1aaa9d"/>
          </svg>
        </div>
        <div class="thanks-container__header-item-inner">
          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30.004" viewBox="0 0 30 30.004">
            <g id="hospital" transform="translate(-0.036)">
              <ellipse id="Ellipse_1" data-name="Ellipse 1" cx="0.879" cy="0.879" rx="0.879" ry="0.879" transform="translate(3.419 19.426)" fill="#00aea9"/>
              <ellipse id="Ellipse_2" data-name="Ellipse 2" cx="0.879" cy="0.879" rx="0.879" ry="0.879" transform="translate(3.419 15.655)" fill="#00aea9"/>
              <ellipse id="Ellipse_3" data-name="Ellipse 3" cx="0.879" cy="0.879" rx="0.879" ry="0.879" transform="translate(3.419 11.883)" fill="#00aea9"/>
              <ellipse id="Ellipse_4" data-name="Ellipse 4" cx="0.879" cy="0.879" rx="0.879" ry="0.879" transform="translate(24.753 19.426)" fill="#00aea9"/>
              <ellipse id="Ellipse_5" data-name="Ellipse 5" cx="0.879" cy="0.879" rx="0.879" ry="0.879" transform="translate(24.753 15.655)" fill="#00aea9"/>
              <ellipse id="Ellipse_6" data-name="Ellipse 6" cx="0.879" cy="0.879" rx="0.879" ry="0.879" transform="translate(24.753 11.883)" fill="#00aea9"/>
              <path id="Path_6" data-name="Path 6" d="M207.909,264.676a.879.879,0,0,0-.879.879v.985a.879.879,0,0,0,1.758,0v-.985A.879.879,0,0,0,207.909,264.676Z" transform="translate(-194.864 -249.165)" fill="#00aea9"/>
              <path id="Path_7" data-name="Path 7" d="M276.085,264.676a.879.879,0,0,0-.879.879v.985a.879.879,0,0,0,1.758,0v-.985A.879.879,0,0,0,276.085,264.676Z" transform="translate(-259.044 -249.165)" fill="#00aea9"/>
              <path id="Path_8" data-name="Path 8" d="M276.085,193.488a.879.879,0,0,0-.879.879v.985a.879.879,0,0,0,1.758,0v-.985A.879.879,0,0,0,276.085,193.488Z" transform="translate(-259.044 -182.149)" fill="#00aea9"/>
              <path id="Path_9" data-name="Path 9" d="M207.909,193.488a.879.879,0,0,0-.879.879v.985a.879.879,0,0,0,1.758,0v-.985A.879.879,0,0,0,207.909,193.488Z" transform="translate(-194.864 -182.149)" fill="#00aea9"/>
              <path id="Path_10" data-name="Path 10" d="M201.873,63.957h1.3v1.3a.879.879,0,0,0,1.758,0v-1.3h1.3a.879.879,0,1,0,0-1.757h-1.3V60.9a.879.879,0,0,0-1.758,0v1.3h-1.3a.879.879,0,1,0,0,1.757Z" transform="translate(-189.181 -56.507)" fill="#00aea9"/>
              <path id="Path_11" data-name="Path 11" d="M.036,29.116a.879.879,0,0,0,.879.879L29.151,30a.887.887,0,0,0,.879-.888l.006-20a.879.879,0,0,0-.879-.879H23.085V.879A.879.879,0,0,0,22.206,0H7.849A.879.879,0,0,0,6.97.879V8.237H.915a.879.879,0,0,0-.879.879v20ZM28.278,9.995l0,13.218H23.085V9.995ZM21.327,28.237H18.688V20.8a.879.879,0,0,0-.879-.879H12.277a.879.879,0,0,0-.879.879v7.436H8.728V1.758h12.6Zm-4.4,0H13.156V21.68H16.93ZM6.97,9.995V23.213H1.794V9.995ZM1.794,24.97H6.97v3.267H1.794Zm21.291,3.266V24.97h5.189v3.267Z" fill="#00aea9"/>
            </g>
          </svg>
          <div class="descr">
            <div class="ttl upper-1">{{_.get($store.state.main.indx.terms,'hospital.length')}}<div class="ttlInTtl upper-1">{{$fn.tr('ჰოსპიტალი')}}</div></div>
            <div class="subttl">{{$fn.tr('ჰოსპიტალი')}}</div>
          </div>
        </div>
      </div>
      <div @click="tabClicker(2)"  class="thanks-container__header-item"  :class="$store.state.layout.tab == 2 ? 'active' : ''">
        <div class="grad"></div>
        <div class="tabArrow">
          <svg xmlns="http://www.w3.org/2000/svg" width="14.188" height="8.761" viewBox="0 0 14.188 8.761">
            <path id="Icon_material-keyboard-arrow-down" data-name="Icon material-keyboard-arrow-down" d="M10.667,11.76l5.427,5.415,5.427-5.415,1.667,1.667-7.094,7.094L9,13.427Z" transform="translate(-9 -11.76)" fill="#1aaa9d"/>
          </svg>

        </div>
        <div class="thanks-container__header-item-inner">
          <svg id="Group_160" data-name="Group 160" xmlns="http://www.w3.org/2000/svg" width="25.195" height="30" viewBox="0 0 25.195 30">
            <g id="Group_159" data-name="Group 159">
              <path id="Path_12" data-name="Path 12" d="M62.506,17.166a12.537,12.537,0,0,0-5.1-3.107,6.819,6.819,0,0,0,.942-.781A6.692,6.692,0,0,0,60.336,8.5V2.637A2.64,2.64,0,0,0,57.7,0H49.5a2.64,2.64,0,0,0-2.637,2.637V8.423a6.839,6.839,0,0,0,2.955,5.63A12.615,12.615,0,0,0,41,26.074V30H66.2V26.074A12.515,12.515,0,0,0,62.506,17.166ZM48.617,2.637a.88.88,0,0,1,.879-.879h8.2a.88.88,0,0,1,.879.879v.879H56.083a2.636,2.636,0,0,0-4.971,0H48.617Zm5.859,1.758a.879.879,0,1,1-.879-.879A.88.88,0,0,1,54.477,4.395ZM48.617,8.423V5.273h2.495a2.636,2.636,0,0,0,4.971,0h2.495V8.5a4.98,4.98,0,0,1-5.018,4.98A5.031,5.031,0,0,1,48.617,8.423Zm4.1,19.82H42.758V26.074a10.854,10.854,0,0,1,6.21-9.8l3.751,3.751ZM50.785,15.605a10.852,10.852,0,0,1,5.621,0L53.6,18.415ZM64.438,28.242H54.477V20.02l3.747-3.747a10.854,10.854,0,0,1,6.214,9.8Z" transform="translate(-41)" fill="#00aea9"/>
            </g>
          </svg>
          <div class="descr">
            <div class="ttl upper-1">{{ _.get($store.state.main.indx,'personal.length')}} <div class="ttlInTtl upper-1">{{$fn.tr('მედპერსონალი')}}</div></div>
            <div class="subttl">{{$fn.tr('მედპერსონალი')}}</div>
          </div>
        </div>
      </div>
      <div @click="tabClicker(3)"  class="thanks-container__header-item" :class="$store.state.layout.tab == 3 ? 'active' : ''">
        <div class="grad"></div>
        <div class="tabArrow">
          <svg xmlns="http://www.w3.org/2000/svg" width="14.188" height="8.761" viewBox="0 0 14.188 8.761">
            <path id="Icon_material-keyboard-arrow-down" data-name="Icon material-keyboard-arrow-down" d="M10.667,11.76l5.427,5.415,5.427-5.415,1.667,1.667-7.094,7.094L9,13.427Z" transform="translate(-9 -11.76)" fill="#1aaa9d"/>
          </svg>

        </div>
        <div class="thanks-container__header-item-inner">
          <svg xmlns="http://www.w3.org/2000/svg" width="34.44" height="30" viewBox="0 0 34.44 30">
            <g id="customer" transform="translate(0 -33)">
              <path id="Path_14" data-name="Path 14" d="M174.852,44.7A5.852,5.852,0,1,0,169,38.852,5.859,5.859,0,0,0,174.852,44.7Zm0-9.686a3.834,3.834,0,1,1-3.834,3.834A3.838,3.838,0,0,1,174.852,35.018Z" transform="translate(-157.632)" fill="#00aea9"/>
              <path id="Path_15" data-name="Path 15" d="M380.7,104.4a3.7,3.7,0,1,0-3.7-3.7A3.7,3.7,0,0,0,380.7,104.4Zm0-5.381a1.682,1.682,0,1,1-1.682,1.682A1.684,1.684,0,0,1,380.7,99.018Z" transform="translate(-351.641 -59.695)" fill="#00aea9"/>
              <path id="Path_16" data-name="Path 16" d="M29.872,241H28.312a4.574,4.574,0,0,0-4.15,2.647,8.121,8.121,0,0,0-6-2.647H16.28a8.121,8.121,0,0,0-6,2.647A4.574,4.574,0,0,0,6.128,241H4.567A4.559,4.559,0,0,0,0,245.54v7.346a1.977,1.977,0,0,0,1.978,1.971h6.17a2.359,2.359,0,0,0,2.346,2.152h13.45a2.359,2.359,0,0,0,2.346-2.152h6.1a2.047,2.047,0,0,0,2.049-2.041V245.54A4.559,4.559,0,0,0,29.872,241ZM2.018,245.54a2.539,2.539,0,0,1,2.549-2.522H6.128a2.539,2.539,0,0,1,2.549,2.522v.691C8,248,8.139,249.056,8.139,252.839H2.018Zm22.265,9.114a.338.338,0,0,1-.338.338H10.495a.338.338,0,0,1-.338-.338V249.14a6.129,6.129,0,0,1,6.122-6.123H18.16a6.129,6.129,0,0,1,6.123,6.122Zm8.139-1.838c0,.035.376.023-6.121.023,0-3.811.139-4.844-.538-6.608v-.691a2.539,2.539,0,0,1,2.549-2.522h1.561a2.539,2.539,0,0,1,2.549,2.522Z" transform="translate(0 -194.009)" fill="#00aea9"/>
              <path id="Path_17" data-name="Path 17" d="M28.7,104.4a3.7,3.7,0,1,0-3.7-3.7A3.7,3.7,0,0,0,28.7,104.4Zm0-5.381a1.682,1.682,0,1,1-1.682,1.682A1.684,1.684,0,0,1,28.7,99.018Z" transform="translate(-23.318 -59.695)" fill="#00aea9"/>
            </g>
          </svg>
          <div class="descr">
            <div class="ttl upper-1">{{_.get($store.state.main.indx.feedback_counts,'patients_count.count')}}<div class="ttlInTtl upper-1">{{$fn.tr('პაციენტი')}}</div></div>
            <div class="subttl">{{$fn.tr('პაციენტი')}}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="mobile-tab-switcher" :class="{active: mobileDropdown}">
        <ul>
          <li @click="tabClicker(0)">
            <svg xmlns="http://www.w3.org/2000/svg" width="19.336" height="30" viewBox="0 0 19.336 30">
              <g id="wedding" transform="translate(-91.005 0)">
                <path id="Path_35" data-name="Path 35" d="M158.944,192.388a.879.879,0,0,0,1.243,0l4.35-4.35a3.516,3.516,0,1,0-4.972-4.972,3.516,3.516,0,1,0-4.972,4.972Zm-3.107-8.079a1.76,1.76,0,0,1,2.486,0l.621.621a.879.879,0,0,0,1.243,0l.621-.621a1.758,1.758,0,1,1,2.486,2.486l-3.729,3.729-3.729-3.729A1.76,1.76,0,0,1,155.837,184.309Z" transform="translate(-58.893 -171.371)" fill="#00aea9"/>
                <path id="Path_36" data-name="Path 36" d="M109.462,5.391h-.879V.88A.879.879,0,0,0,107.42.048L91.6,5.438l-.02.008A.878.878,0,0,0,91,6.27V29.121a.879.879,0,0,0,.879.879h17.578a.879.879,0,0,0,.879-.879V6.27a.879.879,0,0,0-.879-.879Zm-2.637-3.284V5.391H97.188Zm1.758,26.135H92.763V7.149h15.82Z" transform="translate(0 0)" fill="#00aea9"/>
                <path id="Path_37" data-name="Path 37" d="M188.915,392.01h-7.031a.879.879,0,1,0,0,1.758h7.031a.879.879,0,1,0,0-1.758Z" transform="translate(-84.727 -369.041)" fill="#00aea9"/>
              </g>
            </svg>
            <div class="tab-desc">
              <div class="descr">
                <div class="ttl upper-1">{{_.get($store.state.main.current_content.primary,'data.list').lenght}} hhi</div>
                <div class="subttl">ისტორია</div>
              </div>
            </div>
          </li>
          <li @click="tabClicker(1)">
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30.004" viewBox="0 0 30 30.004">
            <g id="hospital" transform="translate(-0.036)">
              <ellipse id="Ellipse_1" data-name="Ellipse 1" cx="0.879" cy="0.879" rx="0.879" ry="0.879" transform="translate(3.419 19.426)" fill="#00aea9"/>
              <ellipse id="Ellipse_2" data-name="Ellipse 2" cx="0.879" cy="0.879" rx="0.879" ry="0.879" transform="translate(3.419 15.655)" fill="#00aea9"/>
              <ellipse id="Ellipse_3" data-name="Ellipse 3" cx="0.879" cy="0.879" rx="0.879" ry="0.879" transform="translate(3.419 11.883)" fill="#00aea9"/>
              <ellipse id="Ellipse_4" data-name="Ellipse 4" cx="0.879" cy="0.879" rx="0.879" ry="0.879" transform="translate(24.753 19.426)" fill="#00aea9"/>
              <ellipse id="Ellipse_5" data-name="Ellipse 5" cx="0.879" cy="0.879" rx="0.879" ry="0.879" transform="translate(24.753 15.655)" fill="#00aea9"/>
              <ellipse id="Ellipse_6" data-name="Ellipse 6" cx="0.879" cy="0.879" rx="0.879" ry="0.879" transform="translate(24.753 11.883)" fill="#00aea9"/>
              <path id="Path_6" data-name="Path 6" d="M207.909,264.676a.879.879,0,0,0-.879.879v.985a.879.879,0,0,0,1.758,0v-.985A.879.879,0,0,0,207.909,264.676Z" transform="translate(-194.864 -249.165)" fill="#00aea9"/>
              <path id="Path_7" data-name="Path 7" d="M276.085,264.676a.879.879,0,0,0-.879.879v.985a.879.879,0,0,0,1.758,0v-.985A.879.879,0,0,0,276.085,264.676Z" transform="translate(-259.044 -249.165)" fill="#00aea9"/>
              <path id="Path_8" data-name="Path 8" d="M276.085,193.488a.879.879,0,0,0-.879.879v.985a.879.879,0,0,0,1.758,0v-.985A.879.879,0,0,0,276.085,193.488Z" transform="translate(-259.044 -182.149)" fill="#00aea9"/>
              <path id="Path_9" data-name="Path 9" d="M207.909,193.488a.879.879,0,0,0-.879.879v.985a.879.879,0,0,0,1.758,0v-.985A.879.879,0,0,0,207.909,193.488Z" transform="translate(-194.864 -182.149)" fill="#00aea9"/>
              <path id="Path_10" data-name="Path 10" d="M201.873,63.957h1.3v1.3a.879.879,0,0,0,1.758,0v-1.3h1.3a.879.879,0,1,0,0-1.757h-1.3V60.9a.879.879,0,0,0-1.758,0v1.3h-1.3a.879.879,0,1,0,0,1.757Z" transform="translate(-189.181 -56.507)" fill="#00aea9"/>
              <path id="Path_11" data-name="Path 11" d="M.036,29.116a.879.879,0,0,0,.879.879L29.151,30a.887.887,0,0,0,.879-.888l.006-20a.879.879,0,0,0-.879-.879H23.085V.879A.879.879,0,0,0,22.206,0H7.849A.879.879,0,0,0,6.97.879V8.237H.915a.879.879,0,0,0-.879.879v20ZM28.278,9.995l0,13.218H23.085V9.995ZM21.327,28.237H18.688V20.8a.879.879,0,0,0-.879-.879H12.277a.879.879,0,0,0-.879.879v7.436H8.728V1.758h12.6Zm-4.4,0H13.156V21.68H16.93ZM6.97,9.995V23.213H1.794V9.995ZM1.794,24.97H6.97v3.267H1.794Zm21.291,3.266V24.97h5.189v3.267Z" fill="#00aea9"/>
            </g>
          </svg>
            <div class="tab-desc">
              <div class="descr">
                <div class="ttl upper-1">1563</div>
                <div class="subttl">ისტორია</div>
              </div>
            </div>
          </li>
          <li @click="tabClicker(2)">
            <svg id="Group_160" data-name="Group 160" xmlns="http://www.w3.org/2000/svg" width="25.195" height="30" viewBox="0 0 25.195 30">
              <g id="Group_159" data-name="Group 159">
                <path id="Path_12" data-name="Path 12" d="M62.506,17.166a12.537,12.537,0,0,0-5.1-3.107,6.819,6.819,0,0,0,.942-.781A6.692,6.692,0,0,0,60.336,8.5V2.637A2.64,2.64,0,0,0,57.7,0H49.5a2.64,2.64,0,0,0-2.637,2.637V8.423a6.839,6.839,0,0,0,2.955,5.63A12.615,12.615,0,0,0,41,26.074V30H66.2V26.074A12.515,12.515,0,0,0,62.506,17.166ZM48.617,2.637a.88.88,0,0,1,.879-.879h8.2a.88.88,0,0,1,.879.879v.879H56.083a2.636,2.636,0,0,0-4.971,0H48.617Zm5.859,1.758a.879.879,0,1,1-.879-.879A.88.88,0,0,1,54.477,4.395ZM48.617,8.423V5.273h2.495a2.636,2.636,0,0,0,4.971,0h2.495V8.5a4.98,4.98,0,0,1-5.018,4.98A5.031,5.031,0,0,1,48.617,8.423Zm4.1,19.82H42.758V26.074a10.854,10.854,0,0,1,6.21-9.8l3.751,3.751ZM50.785,15.605a10.852,10.852,0,0,1,5.621,0L53.6,18.415ZM64.438,28.242H54.477V20.02l3.747-3.747a10.854,10.854,0,0,1,6.214,9.8Z" transform="translate(-41)" fill="#00aea9"/>
              </g>
            </svg>
            <div class="tab-desc">
              <div class="descr">
                <div class="ttl upper-1">1556</div>
                <div class="subttl">ისტორია</div>
              </div>
            </div>
          </li>
          <li @click="tabClicker(3)">
            <svg xmlns="http://www.w3.org/2000/svg" width="34.44" height="30" viewBox="0 0 34.44 30">
              <g id="customer" transform="translate(0 -33)">
                <path id="Path_14" data-name="Path 14" d="M174.852,44.7A5.852,5.852,0,1,0,169,38.852,5.859,5.859,0,0,0,174.852,44.7Zm0-9.686a3.834,3.834,0,1,1-3.834,3.834A3.838,3.838,0,0,1,174.852,35.018Z" transform="translate(-157.632)" fill="#00aea9"/>
                <path id="Path_15" data-name="Path 15" d="M380.7,104.4a3.7,3.7,0,1,0-3.7-3.7A3.7,3.7,0,0,0,380.7,104.4Zm0-5.381a1.682,1.682,0,1,1-1.682,1.682A1.684,1.684,0,0,1,380.7,99.018Z" transform="translate(-351.641 -59.695)" fill="#00aea9"/>
                <path id="Path_16" data-name="Path 16" d="M29.872,241H28.312a4.574,4.574,0,0,0-4.15,2.647,8.121,8.121,0,0,0-6-2.647H16.28a8.121,8.121,0,0,0-6,2.647A4.574,4.574,0,0,0,6.128,241H4.567A4.559,4.559,0,0,0,0,245.54v7.346a1.977,1.977,0,0,0,1.978,1.971h6.17a2.359,2.359,0,0,0,2.346,2.152h13.45a2.359,2.359,0,0,0,2.346-2.152h6.1a2.047,2.047,0,0,0,2.049-2.041V245.54A4.559,4.559,0,0,0,29.872,241ZM2.018,245.54a2.539,2.539,0,0,1,2.549-2.522H6.128a2.539,2.539,0,0,1,2.549,2.522v.691C8,248,8.139,249.056,8.139,252.839H2.018Zm22.265,9.114a.338.338,0,0,1-.338.338H10.495a.338.338,0,0,1-.338-.338V249.14a6.129,6.129,0,0,1,6.122-6.123H18.16a6.129,6.129,0,0,1,6.123,6.122Zm8.139-1.838c0,.035.376.023-6.121.023,0-3.811.139-4.844-.538-6.608v-.691a2.539,2.539,0,0,1,2.549-2.522h1.561a2.539,2.539,0,0,1,2.549,2.522Z" transform="translate(0 -194.009)" fill="#00aea9"/>
                <path id="Path_17" data-name="Path 17" d="M28.7,104.4a3.7,3.7,0,1,0-3.7-3.7A3.7,3.7,0,0,0,28.7,104.4Zm0-5.381a1.682,1.682,0,1,1-1.682,1.682A1.684,1.684,0,0,1,28.7,99.018Z" transform="translate(-23.318 -59.695)" fill="#00aea9"/>
              </g>
            </svg>
            <div class="tab-desc">
              <div class="descr">
                <div class="ttl upper-1">1565</div>
                <div class="subttl">ისტორია</div>
              </div>
            </div>
          </li>
        </ul>
    </div> -->
  </div>
</template>
<script>
  import { mapMutations } from "vuex";
  import url from "@/mixins/url";
  export default {
    data(){
      return{
        mobileDropdown: false,
        noDelay: false,

        tabNames: [ 'story', 'hospital', 'doctor', 'patient' ]
      }
    },

    mixins: [url],

    watch:{
      "$store.state.layout.tab": function (){
        let num = this.$store.state.layout.tab;
        if(this.tabNames[num] && this.tabNames[num]!=this.$route.params.type) this.smartSingleUrl(this.tabNames[num]);
      }
    },

    methods: {
      ...mapMutations({
        setTab: "layout/SET_TAB"
      }),
      tabClicker(num){
        if(window.innerWidth <= 1023){
          this.mobileDropdown = !this.mobileDropdown
        }
        this.setTab(num)

      }
    },
    mounted() {
      setTimeout(() => {
        this.noDelay = true
      },1000)

      let index = this.tabNames.indexOf(this.$route.params.type);
      if(index >= 0)this.tabClicker(index);

    }
  }
</script>
