<template>
  <div class="thanks-container__doctorsContainer tabs">
    <HospitalList v-if="hospital" />

    <StuffList v-if="stuff" />

    <PatientStatistic v-if="patient" />
  </div>
</template>
<script>
import HospitalList from "../components/HospitalList";
import StuffList from "../components/StuffList";
import PatientStatistic from "../components/PatientStatistic";

export default {
  components: {
    HospitalList,
    StuffList,
    PatientStatistic
  },
  data() {
    return{
      hospital: true,
      stuff: true,
      patient: true
    }
  },
  // created() {
  //   window.addEventListener("resize", this.myEventHandler);
  // },
  // destroyed() {
  //   window.removeEventListener("resize", this.myEventHandler);
  // },
  // methods: {
  //   myEventHandler(e) {
  //     if(window.innerWidth > 1023 && this.$store.state.layout.tab == 1){
  //       this.hospital = true,
  //       this.stuff = false,
  //       this.patient = false
  //     }
  //     if(window.innerWidth > 1023 && this.$store.state.layout.tab == 2){
  //       this.hospital = false,
  //       this.stuff = true,
  //       this.patient = false
  //     }
  //     if(window.innerWidth > 1023 && this.$store.state.layout.tab == 3){
  //       this.hospital = false,
  //       this.stuff = false,
  //       this.patient = true
  //     }
  //   }
  // }
};
</script>
<style scoped>
.tabs {
  display: flex;
  justify-content: space-between;
  min-height: 90vh;
}
@media (max-width: 767px) {
  .tabs {
    flex-direction: column;
  }
}
</style>
