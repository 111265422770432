import Vue from 'vue'
import _ from 'lodash';

export default {
  setMenuStatus(state, data){

    Vue.set(state.menuStatus, "level"+data.level, data.val);
  },
  setState : function (state, data) {
    // Validate data key & value not equal false
    if (! data.key) return false;
    Vue.set(state, data.key, data.val);
  },
  SET_URL(state, modalType) {
    state.selected_menu.full_url = modalType;
  },
  setStateDeep : function (state, data){
    // Validate data key & value not equal false
    if (! data.key ) return false;
    if(! data.val ) data.val = '';
    _.set(state, data.key, data.val);

    let stateVar = data.key.split(".")[0];
    Vue.set(state, stateVar, JSON.parse(JSON.stringify(state[stateVar])))
  },

  /// update list object or add new one
  setListOb: function (state, data) {
    //// formData , list (list name), id
    if(!data.formData || !data.formData.id || !data.listTitle )return false;

    if(!_.get(state, data.listTitle))this.commit('setStateDeep', {key: data.listTitle, val: [] });
    let list = _.get(state, data.listTitle);

    data.formData = JSON.parse(JSON.stringify(data.formData));

    for (let i in list) {
      if( parseInt(list[i].id) != parseInt(data.formData.id) )continue;
      Vue.set(list, i, data.formData);
      return true;
    }

    list.push(data.formData);

  },

  delListOb: function (state, data) {
    //// formData , list (list name), id
    if(!data.id || !data.listTitle || !_.get(state, data.listTitle))return false;

    let list = _.get(state, data.listTitle);

    for (let i in list) {
      if( parseInt(list[i].id) != parseInt(data.id) )continue;
      Vue.delete(list, i);
      return true;
    }

    return false;

  },

  ///////// routing helpers
  setMenuFullUrls(state){

    for (let i in state.indx.menus){

      let loop = 0;
      let tmp = state.indx.menus[i];
      let lopMenu = state.indx.menus[i];
      // let lastPid = tmp.pid;
      state.indx.menus[i].secondary_content_template = _.get(state.indx.menus[i], ['secondary_template']);

      let paths = [_.get(tmp, 'url_slug')];
      state.indx.menus[i].shortlink = 0; /// 0: regular link; 1: in site redirect; 2 outer redirect with http
      state.indx.menus[i].regular_path = ''; /// regular path build from slugs /// it will be individual /// used to find exact menu from browser url path
      state.indx.menus[i].regular_path_localized = '';

      /// find full path of menu item
      while (loop++ < 20){
        tmp = _.find(state.indx.menus, { id: tmp.pid });
        if(!tmp) break;
        paths.unshift( _.get(tmp, 'url_slug') )
        if(!tmp.pid || tmp.pid == 0)break;
      }

      /// generate regular urls
      state.indx.menus[i].regular_path = '/'+paths.join('/');
      state.indx.menus[i].regular_path_localized = '/'+state.locale+state.indx.menus[i].regular_path;

      ///// menu is redirectable
      if(_.get(state.indx.menus[i], 'redirect_url') && _.size(state.indx.menus[i].redirect_url)){

        if(state.indx.menus[i].redirect_url.indexOf('ttp://') > 0){
          /// menu is redirectable to some http address
          state.indx.menus[i].shortlink = 2;

          state.indx.menus[i].full_url = _.get(lopMenu, 'redirect_url');
          state.indx.menus[i].full_url_clean = _.get(lopMenu, 'redirect_url');

        }else{
          /// menu redirects inside website
          state.indx.menus[i].shortlink = 1;
          state.indx.menus[i].full_url = '/'+state.locale+'/'+_.get(lopMenu, 'redirect_url');
          state.indx.menus[i].full_url_clean = _.get(lopMenu, 'redirect_url');
          // console.log(state.indx.menus[i].full_url);
        }

      }else{
        state.indx.menus[i].full_url = state.indx.menus[i].regular_path_localized;
        state.indx.menus[i].full_url_clean = state.indx.menus[i].regular_path;
      }

    }

    state.indx.menus = _.orderBy(state.indx.menus, ['sort'], ['asc']);
    return false;

  },

  setCurrentMenuLocalePaths(state){
    if(!state.indx.menus)return false;
    //
    // let paths = {};
    // let loop = 0;
    //
    let tmp = _.get(_.find(state.indx.menus, { id: state.selected_menu.id }), 'full_url_clean');

    state.selected_menu_locales = {};

    for (let locale in state.indx.locales){
      Vue.set(state.selected_menu_locales, locale, '/'+locale+tmp );
    }

    return false;

  },

  setContentSingleUrls(state, params){
    // state.current_content
  },

  setCurrentContentFromBuffer(state){
    state.current_content = JSON.parse(JSON.stringify(state.current_content_buffer));
  }
}
