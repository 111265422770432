<template>
  <div class="tab-width" @click="tabClicker(1)" :class="{ current: $store.state.layout.tab == 1 }">
    <div
      class="doctor-tab-title noto-bold f-14"
    >
      {{$fn.tr('ჰოსპიტალის დასახელება')}}
    </div>
    <div
      class="hospital-list"
    >
      <ul>
        <li v-for="(hospital, index) in _.get($store.state.main.indx.terms,'hospital')" :key="index">{{hospital.title}}</li>
      </ul>
    </div>
  </div>
</template>
<script>
import { mapMutations } from "vuex";
export default {
  methods: {
    ...mapMutations({
      setTab: "layout/SET_TAB"
    }),
    tabClicker(num){
      if(this.$store.state.layout.tab != 1){
        this.setTab(num)
      }
    }
  }
  }
</script>
<style scoped>
.hospital-list {
  border: 2px solid #d4d4d4;
  border-radius: 10px;
  padding: 0px 0 0 20px;
  width: 100%;
  max-height: 800px;
  height: 100%;
  opacity: 0.5;
  overflow: hidden;
}
.tab-width.current .hospital-list {
  border: 2px solid #1aaa9d;
  opacity: 1;
}
.tab-width.current .hospital-list ul::-webkit-scrollbar {
  width: 10px;
  border-radius: 10px;
}
.tab-width.current .hospital-list ul::-webkit-scrollbar-thumb {
  background: linear-gradient(
    62deg,
    rgba(14, 78, 140, 1) 0%,
    rgba(0, 89, 86, 1) 100%
  );
  border-radius: 10px;
}
.tab-width.current .hospital-list ul::-webkit-scrollbar-track {
  background: #d9d9d9;
  border-radius: 10px;
}
.doctor-tab-title {
  opacity: 0.5;
}
.tab-width.current .doctor-tab-title {
  opacity: 1;
}
.hospital-list ul {
  max-height: 790px;
  overflow-y: hidden;
  padding: 10px;
}
.tab-width.current .hospital-list ul {
  overflow-y: auto;
}
.hospital-list ul li{
  padding: 30px 20px;
  border-radius: 10px;
}
.hospital-list ul.current li:hover {
  background: #f5f7f8;
  font-weight: bold;
}
@media (max-width: 1599px) {
  .hospital-list {
    padding: 0;
  }
  .hospital-list ul li{
    padding: 20px 10px;
    border-radius: 10px;
  }
}
@media (max-width: 1365px) {
  .hospital-list {
    padding: 0 0 0 20px;
    height: auto;
  }
}
@media (max-width: 1023px) {
  .hospital-list {
    border: none;
    /* padding: 10px 10px 10px 20px; */
    padding: 0;
    width: 100%;
  }
}
</style>
