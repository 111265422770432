import Vue from 'vue';
import VueRouter from 'vue-router';

import store from '../store';

import PageViewRouter from '../views/view-router/page';
const Home = () => import("../views/Home.vue");


Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/en/:path1?/:path2?/:path3?/:path4?/:path5?/:path6?/:path7?/:path8?/:path9?/',
  //   name: 'PageViewRouter_en',
  //   meta: { bodyClass: 'page' },
  //   component: PageViewRouter,
  //   props: true
  // },
  // {
  //   path: '/ge/:path1?/:path2?/:path3?/:path4?/:path5?/:path6?/:path7?/:path8?/:path9?/',
  //   name: 'PageViewRouter_ge',
  //   meta: { bodyClass: 'page' },
  //   component: PageViewRouter,
  //   props: true
  // },

  {
    path: '/:type?/:id?/:slug?/:path1?/:path2?/:path3?/:path4?/:path5?/:path6?/:path7?/:path8?/:path9?/',
    name: 'home',
    component: Home,
    props: { ss:11 }
  },


  // {
  //   path: '/about/',
  //   name: 'about',
  //   component: About
  // },


]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  store,
  scrollBehavior(to, from, savedPosition) {
    // store.commit('setState', {key: 'offset', val: 0});
    if (to.hash) {
      return {
        selector: to.hash,
        offset: { x: 0, y: 0 }

      }
    } else {
      return { x: 0, y: 0 }
    }
  },
});

export default router
