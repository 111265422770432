export default {
    computed: {
        isMobile() {
            return this.$mq === 'phone';
        },
        isTablet() {
            return this.$mq === 'tablet';
        },
        isMd() {
            return this.$mq === 'md';
        },
        isLg() {
            return this.$mq === 'lg';
        }
    }
}
