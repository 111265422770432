<template>
  <div class="clap-animation__container active">
    <div class="clap-thanks-container__doctor-btn">
      <div class="thanks-container__doctor-btn">
        <div class="svgContainer">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="30.765" height="28" viewBox="0 0 30.765 28">
            <defs>
              <clipPath id="clip-path">
                <rect id="Rectangle_363" data-name="Rectangle 363" width="30.765" height="28" transform="translate(-8258 -10583)" fill="red"/>
              </clipPath>
            </defs>
            <g id="Mask_Group_14" data-name="Mask Group 14" transform="translate(8258 10583)" clip-path="url(#clip-path)">
              <g id="Group_4816" data-name="Group 4816" transform="translate(-8263.279 -10592.398)">
                <g id="open-hand" transform="translate(0 23.46) rotate(-60)">
                  <path id="Path_243" data-name="Path 243" d="M2.139,5.7A2.138,2.138,0,0,1,4.278,7.841V4.989a2.139,2.139,0,1,1,4.277,0V2.139a2.139,2.139,0,0,1,4.277,0V4.989a2.139,2.139,0,1,1,4.277,0V19.957l4.95-2.475a3.565,3.565,0,0,1,1.594-.376,2.01,2.01,0,0,1,2.01,2.01v.012a2.01,2.01,0,0,1-.588,1.426l-9.22,9.218a10.322,10.322,0,0,1-7.3,3.02h0A8.554,8.554,0,0,1,0,24.235V7.841A2.138,2.138,0,0,1,2.139,5.7Z" transform="translate(0.713 0.713)" fill="#fff"/>
                  <path id="Path_247" data-name="Path 247" d="M9.268,34.216a10.96,10.96,0,0,0,7.8-3.232l9.218-9.218a2.729,2.729,0,0,0-1.925-4.658,4.3,4.3,0,0,0-1.912.449l-3.919,1.96V5.7a2.852,2.852,0,0,0-4.277-2.469V2.852a2.852,2.852,0,1,0-5.7,0v.384A2.851,2.851,0,0,0,4.277,5.7v.383A2.851,2.851,0,0,0,0,8.556V24.95a9.278,9.278,0,0,0,9.268,9.267Zm15.1-15.682a1.3,1.3,0,0,1,.918,2.224l-9.22,9.219a9.543,9.543,0,0,1-6.8,2.814A7.851,7.851,0,0,1,1.427,24.95V8.555a1.426,1.426,0,1,1,2.851,0v6.414a.713.713,0,1,0,1.426,0V5.7a1.426,1.426,0,0,1,2.851,0v9.264a.713.713,0,1,0,1.426,0V2.852a1.426,1.426,0,0,1,2.851,0V14.969a.713.713,0,1,0,1.426,0V5.7a1.426,1.426,0,0,1,2.851,0V20.672a.713.713,0,0,0,1.032.637L23.1,18.835a2.863,2.863,0,0,1,1.27-.3Z" transform="translate(0 0)" fill="#1aaa9d"/>
                </g>
              </g>
            </g>
          </svg>
          <div class="clapsInner">
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="30.765" height="28" viewBox="0 0 30.765 28">
              <defs>
                <clipPath id="clip-path">
                  <rect id="Rectangle_363" data-name="Rectangle 363" width="30.765" height="28" transform="translate(-8258 -10583)" fill="red"/>
                </clipPath>
              </defs>
              <g id="Mask_Group_14" data-name="Mask Group 14" transform="translate(8258 10583)" clip-path="url(#clip-path)">
                <g id="Group_4816" data-name="Group 4816" transform="translate(-8263.279 -10592.398)">
                  <g id="open-hand" transform="translate(0 23.46) rotate(-60)">
                    <path id="Path_243" data-name="Path 243" d="M2.139,5.7A2.138,2.138,0,0,1,4.278,7.841V4.989a2.139,2.139,0,1,1,4.277,0V2.139a2.139,2.139,0,0,1,4.277,0V4.989a2.139,2.139,0,1,1,4.277,0V19.957l4.95-2.475a3.565,3.565,0,0,1,1.594-.376,2.01,2.01,0,0,1,2.01,2.01v.012a2.01,2.01,0,0,1-.588,1.426l-9.22,9.218a10.322,10.322,0,0,1-7.3,3.02h0A8.554,8.554,0,0,1,0,24.235V7.841A2.138,2.138,0,0,1,2.139,5.7Z" transform="translate(0.713 0.713)" fill="#fff"/>
                    <path id="Path_247" data-name="Path 247" d="M9.268,34.216a10.96,10.96,0,0,0,7.8-3.232l9.218-9.218a2.729,2.729,0,0,0-1.925-4.658,4.3,4.3,0,0,0-1.912.449l-3.919,1.96V5.7a2.852,2.852,0,0,0-4.277-2.469V2.852a2.852,2.852,0,1,0-5.7,0v.384A2.851,2.851,0,0,0,4.277,5.7v.383A2.851,2.851,0,0,0,0,8.556V24.95a9.278,9.278,0,0,0,9.268,9.267Zm15.1-15.682a1.3,1.3,0,0,1,.918,2.224l-9.22,9.219a9.543,9.543,0,0,1-6.8,2.814A7.851,7.851,0,0,1,1.427,24.95V8.555a1.426,1.426,0,1,1,2.851,0v6.414a.713.713,0,1,0,1.426,0V5.7a1.426,1.426,0,0,1,2.851,0v9.264a.713.713,0,1,0,1.426,0V2.852a1.426,1.426,0,0,1,2.851,0V14.969a.713.713,0,1,0,1.426,0V5.7a1.426,1.426,0,0,1,2.851,0V20.672a.713.713,0,0,0,1.032.637L23.1,18.835a2.863,2.863,0,0,1,1.27-.3Z" transform="translate(0 0)" fill="#1aaa9d"/>
                  </g>
                </g>
              </g>
            </svg>
          </div>
        </div>
      </div>
    </div>
    <div class="clap-thanks-container__doctor-btn">
      <div class="thanks-container__doctor-btn">
        <div class="svgContainer">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="30.765" height="28" viewBox="0 0 30.765 28">
            <defs>
              <clipPath id="clip-path">
                <rect id="Rectangle_363" data-name="Rectangle 363" width="30.765" height="28" transform="translate(-8258 -10583)" fill="red"/>
              </clipPath>
            </defs>
            <g id="Mask_Group_14" data-name="Mask Group 14" transform="translate(8258 10583)" clip-path="url(#clip-path)">
              <g id="Group_4816" data-name="Group 4816" transform="translate(-8263.279 -10592.398)">
                <g id="open-hand" transform="translate(0 23.46) rotate(-60)">
                  <path id="Path_243" data-name="Path 243" d="M2.139,5.7A2.138,2.138,0,0,1,4.278,7.841V4.989a2.139,2.139,0,1,1,4.277,0V2.139a2.139,2.139,0,0,1,4.277,0V4.989a2.139,2.139,0,1,1,4.277,0V19.957l4.95-2.475a3.565,3.565,0,0,1,1.594-.376,2.01,2.01,0,0,1,2.01,2.01v.012a2.01,2.01,0,0,1-.588,1.426l-9.22,9.218a10.322,10.322,0,0,1-7.3,3.02h0A8.554,8.554,0,0,1,0,24.235V7.841A2.138,2.138,0,0,1,2.139,5.7Z" transform="translate(0.713 0.713)" fill="#fff"/>
                  <path id="Path_247" data-name="Path 247" d="M9.268,34.216a10.96,10.96,0,0,0,7.8-3.232l9.218-9.218a2.729,2.729,0,0,0-1.925-4.658,4.3,4.3,0,0,0-1.912.449l-3.919,1.96V5.7a2.852,2.852,0,0,0-4.277-2.469V2.852a2.852,2.852,0,1,0-5.7,0v.384A2.851,2.851,0,0,0,4.277,5.7v.383A2.851,2.851,0,0,0,0,8.556V24.95a9.278,9.278,0,0,0,9.268,9.267Zm15.1-15.682a1.3,1.3,0,0,1,.918,2.224l-9.22,9.219a9.543,9.543,0,0,1-6.8,2.814A7.851,7.851,0,0,1,1.427,24.95V8.555a1.426,1.426,0,1,1,2.851,0v6.414a.713.713,0,1,0,1.426,0V5.7a1.426,1.426,0,0,1,2.851,0v9.264a.713.713,0,1,0,1.426,0V2.852a1.426,1.426,0,0,1,2.851,0V14.969a.713.713,0,1,0,1.426,0V5.7a1.426,1.426,0,0,1,2.851,0V20.672a.713.713,0,0,0,1.032.637L23.1,18.835a2.863,2.863,0,0,1,1.27-.3Z" transform="translate(0 0)" fill="#1aaa9d"/>
                </g>
              </g>
            </g>
          </svg>
          <div class="clapsInner">
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="30.765" height="28" viewBox="0 0 30.765 28">
              <defs>
                <clipPath id="clip-path">
                  <rect id="Rectangle_363" data-name="Rectangle 363" width="30.765" height="28" transform="translate(-8258 -10583)" fill="red"/>
                </clipPath>
              </defs>
              <g id="Mask_Group_14" data-name="Mask Group 14" transform="translate(8258 10583)" clip-path="url(#clip-path)">
                <g id="Group_4816" data-name="Group 4816" transform="translate(-8263.279 -10592.398)">
                  <g id="open-hand" transform="translate(0 23.46) rotate(-60)">
                    <path id="Path_243" data-name="Path 243" d="M2.139,5.7A2.138,2.138,0,0,1,4.278,7.841V4.989a2.139,2.139,0,1,1,4.277,0V2.139a2.139,2.139,0,0,1,4.277,0V4.989a2.139,2.139,0,1,1,4.277,0V19.957l4.95-2.475a3.565,3.565,0,0,1,1.594-.376,2.01,2.01,0,0,1,2.01,2.01v.012a2.01,2.01,0,0,1-.588,1.426l-9.22,9.218a10.322,10.322,0,0,1-7.3,3.02h0A8.554,8.554,0,0,1,0,24.235V7.841A2.138,2.138,0,0,1,2.139,5.7Z" transform="translate(0.713 0.713)" fill="#fff"/>
                    <path id="Path_247" data-name="Path 247" d="M9.268,34.216a10.96,10.96,0,0,0,7.8-3.232l9.218-9.218a2.729,2.729,0,0,0-1.925-4.658,4.3,4.3,0,0,0-1.912.449l-3.919,1.96V5.7a2.852,2.852,0,0,0-4.277-2.469V2.852a2.852,2.852,0,1,0-5.7,0v.384A2.851,2.851,0,0,0,4.277,5.7v.383A2.851,2.851,0,0,0,0,8.556V24.95a9.278,9.278,0,0,0,9.268,9.267Zm15.1-15.682a1.3,1.3,0,0,1,.918,2.224l-9.22,9.219a9.543,9.543,0,0,1-6.8,2.814A7.851,7.851,0,0,1,1.427,24.95V8.555a1.426,1.426,0,1,1,2.851,0v6.414a.713.713,0,1,0,1.426,0V5.7a1.426,1.426,0,0,1,2.851,0v9.264a.713.713,0,1,0,1.426,0V2.852a1.426,1.426,0,0,1,2.851,0V14.969a.713.713,0,1,0,1.426,0V5.7a1.426,1.426,0,0,1,2.851,0V20.672a.713.713,0,0,0,1.032.637L23.1,18.835a2.863,2.863,0,0,1,1.27-.3Z" transform="translate(0 0)" fill="#1aaa9d"/>
                  </g>
                </g>
              </g>
            </svg>
          </div>
        </div>
      </div>
    </div>
    <div class="clap-thanks-container__doctor-btn">
      <div class="thanks-container__doctor-btn">
        <div class="svgContainer">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="30.765" height="28" viewBox="0 0 30.765 28">
            <defs>
              <clipPath id="clip-path">
                <rect id="Rectangle_363" data-name="Rectangle 363" width="30.765" height="28" transform="translate(-8258 -10583)" fill="red"/>
              </clipPath>
            </defs>
            <g id="Mask_Group_14" data-name="Mask Group 14" transform="translate(8258 10583)" clip-path="url(#clip-path)">
              <g id="Group_4816" data-name="Group 4816" transform="translate(-8263.279 -10592.398)">
                <g id="open-hand" transform="translate(0 23.46) rotate(-60)">
                  <path id="Path_243" data-name="Path 243" d="M2.139,5.7A2.138,2.138,0,0,1,4.278,7.841V4.989a2.139,2.139,0,1,1,4.277,0V2.139a2.139,2.139,0,0,1,4.277,0V4.989a2.139,2.139,0,1,1,4.277,0V19.957l4.95-2.475a3.565,3.565,0,0,1,1.594-.376,2.01,2.01,0,0,1,2.01,2.01v.012a2.01,2.01,0,0,1-.588,1.426l-9.22,9.218a10.322,10.322,0,0,1-7.3,3.02h0A8.554,8.554,0,0,1,0,24.235V7.841A2.138,2.138,0,0,1,2.139,5.7Z" transform="translate(0.713 0.713)" fill="#fff"/>
                  <path id="Path_247" data-name="Path 247" d="M9.268,34.216a10.96,10.96,0,0,0,7.8-3.232l9.218-9.218a2.729,2.729,0,0,0-1.925-4.658,4.3,4.3,0,0,0-1.912.449l-3.919,1.96V5.7a2.852,2.852,0,0,0-4.277-2.469V2.852a2.852,2.852,0,1,0-5.7,0v.384A2.851,2.851,0,0,0,4.277,5.7v.383A2.851,2.851,0,0,0,0,8.556V24.95a9.278,9.278,0,0,0,9.268,9.267Zm15.1-15.682a1.3,1.3,0,0,1,.918,2.224l-9.22,9.219a9.543,9.543,0,0,1-6.8,2.814A7.851,7.851,0,0,1,1.427,24.95V8.555a1.426,1.426,0,1,1,2.851,0v6.414a.713.713,0,1,0,1.426,0V5.7a1.426,1.426,0,0,1,2.851,0v9.264a.713.713,0,1,0,1.426,0V2.852a1.426,1.426,0,0,1,2.851,0V14.969a.713.713,0,1,0,1.426,0V5.7a1.426,1.426,0,0,1,2.851,0V20.672a.713.713,0,0,0,1.032.637L23.1,18.835a2.863,2.863,0,0,1,1.27-.3Z" transform="translate(0 0)" fill="#1aaa9d"/>
                </g>
              </g>
            </g>
          </svg>
          <div class="clapsInner">
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="30.765" height="28" viewBox="0 0 30.765 28">
              <defs>
                <clipPath id="clip-path">
                  <rect id="Rectangle_363" data-name="Rectangle 363" width="30.765" height="28" transform="translate(-8258 -10583)" fill="red"/>
                </clipPath>
              </defs>
              <g id="Mask_Group_14" data-name="Mask Group 14" transform="translate(8258 10583)" clip-path="url(#clip-path)">
                <g id="Group_4816" data-name="Group 4816" transform="translate(-8263.279 -10592.398)">
                  <g id="open-hand" transform="translate(0 23.46) rotate(-60)">
                    <path id="Path_243" data-name="Path 243" d="M2.139,5.7A2.138,2.138,0,0,1,4.278,7.841V4.989a2.139,2.139,0,1,1,4.277,0V2.139a2.139,2.139,0,0,1,4.277,0V4.989a2.139,2.139,0,1,1,4.277,0V19.957l4.95-2.475a3.565,3.565,0,0,1,1.594-.376,2.01,2.01,0,0,1,2.01,2.01v.012a2.01,2.01,0,0,1-.588,1.426l-9.22,9.218a10.322,10.322,0,0,1-7.3,3.02h0A8.554,8.554,0,0,1,0,24.235V7.841A2.138,2.138,0,0,1,2.139,5.7Z" transform="translate(0.713 0.713)" fill="#fff"/>
                    <path id="Path_247" data-name="Path 247" d="M9.268,34.216a10.96,10.96,0,0,0,7.8-3.232l9.218-9.218a2.729,2.729,0,0,0-1.925-4.658,4.3,4.3,0,0,0-1.912.449l-3.919,1.96V5.7a2.852,2.852,0,0,0-4.277-2.469V2.852a2.852,2.852,0,1,0-5.7,0v.384A2.851,2.851,0,0,0,4.277,5.7v.383A2.851,2.851,0,0,0,0,8.556V24.95a9.278,9.278,0,0,0,9.268,9.267Zm15.1-15.682a1.3,1.3,0,0,1,.918,2.224l-9.22,9.219a9.543,9.543,0,0,1-6.8,2.814A7.851,7.851,0,0,1,1.427,24.95V8.555a1.426,1.426,0,1,1,2.851,0v6.414a.713.713,0,1,0,1.426,0V5.7a1.426,1.426,0,0,1,2.851,0v9.264a.713.713,0,1,0,1.426,0V2.852a1.426,1.426,0,0,1,2.851,0V14.969a.713.713,0,1,0,1.426,0V5.7a1.426,1.426,0,0,1,2.851,0V20.672a.713.713,0,0,0,1.032.637L23.1,18.835a2.863,2.863,0,0,1,1.27-.3Z" transform="translate(0 0)" fill="#1aaa9d"/>
                  </g>
                </g>
              </g>
            </svg>
          </div>
        </div>
      </div>
    </div>
    <div class="clap-thanks-container__doctor-btn">
      <div class="thanks-container__doctor-btn">
        <div class="svgContainer">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="30.765" height="28" viewBox="0 0 30.765 28">
            <defs>
              <clipPath id="clip-path">
                <rect id="Rectangle_363" data-name="Rectangle 363" width="30.765" height="28" transform="translate(-8258 -10583)" fill="red"/>
              </clipPath>
            </defs>
            <g id="Mask_Group_14" data-name="Mask Group 14" transform="translate(8258 10583)" clip-path="url(#clip-path)">
              <g id="Group_4816" data-name="Group 4816" transform="translate(-8263.279 -10592.398)">
                <g id="open-hand" transform="translate(0 23.46) rotate(-60)">
                  <path id="Path_243" data-name="Path 243" d="M2.139,5.7A2.138,2.138,0,0,1,4.278,7.841V4.989a2.139,2.139,0,1,1,4.277,0V2.139a2.139,2.139,0,0,1,4.277,0V4.989a2.139,2.139,0,1,1,4.277,0V19.957l4.95-2.475a3.565,3.565,0,0,1,1.594-.376,2.01,2.01,0,0,1,2.01,2.01v.012a2.01,2.01,0,0,1-.588,1.426l-9.22,9.218a10.322,10.322,0,0,1-7.3,3.02h0A8.554,8.554,0,0,1,0,24.235V7.841A2.138,2.138,0,0,1,2.139,5.7Z" transform="translate(0.713 0.713)" fill="#fff"/>
                  <path id="Path_247" data-name="Path 247" d="M9.268,34.216a10.96,10.96,0,0,0,7.8-3.232l9.218-9.218a2.729,2.729,0,0,0-1.925-4.658,4.3,4.3,0,0,0-1.912.449l-3.919,1.96V5.7a2.852,2.852,0,0,0-4.277-2.469V2.852a2.852,2.852,0,1,0-5.7,0v.384A2.851,2.851,0,0,0,4.277,5.7v.383A2.851,2.851,0,0,0,0,8.556V24.95a9.278,9.278,0,0,0,9.268,9.267Zm15.1-15.682a1.3,1.3,0,0,1,.918,2.224l-9.22,9.219a9.543,9.543,0,0,1-6.8,2.814A7.851,7.851,0,0,1,1.427,24.95V8.555a1.426,1.426,0,1,1,2.851,0v6.414a.713.713,0,1,0,1.426,0V5.7a1.426,1.426,0,0,1,2.851,0v9.264a.713.713,0,1,0,1.426,0V2.852a1.426,1.426,0,0,1,2.851,0V14.969a.713.713,0,1,0,1.426,0V5.7a1.426,1.426,0,0,1,2.851,0V20.672a.713.713,0,0,0,1.032.637L23.1,18.835a2.863,2.863,0,0,1,1.27-.3Z" transform="translate(0 0)" fill="#1aaa9d"/>
                </g>
              </g>
            </g>
          </svg>
          <div class="clapsInner">
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="30.765" height="28" viewBox="0 0 30.765 28">
              <defs>
                <clipPath id="clip-path">
                  <rect id="Rectangle_363" data-name="Rectangle 363" width="30.765" height="28" transform="translate(-8258 -10583)" fill="red"/>
                </clipPath>
              </defs>
              <g id="Mask_Group_14" data-name="Mask Group 14" transform="translate(8258 10583)" clip-path="url(#clip-path)">
                <g id="Group_4816" data-name="Group 4816" transform="translate(-8263.279 -10592.398)">
                  <g id="open-hand" transform="translate(0 23.46) rotate(-60)">
                    <path id="Path_243" data-name="Path 243" d="M2.139,5.7A2.138,2.138,0,0,1,4.278,7.841V4.989a2.139,2.139,0,1,1,4.277,0V2.139a2.139,2.139,0,0,1,4.277,0V4.989a2.139,2.139,0,1,1,4.277,0V19.957l4.95-2.475a3.565,3.565,0,0,1,1.594-.376,2.01,2.01,0,0,1,2.01,2.01v.012a2.01,2.01,0,0,1-.588,1.426l-9.22,9.218a10.322,10.322,0,0,1-7.3,3.02h0A8.554,8.554,0,0,1,0,24.235V7.841A2.138,2.138,0,0,1,2.139,5.7Z" transform="translate(0.713 0.713)" fill="#fff"/>
                    <path id="Path_247" data-name="Path 247" d="M9.268,34.216a10.96,10.96,0,0,0,7.8-3.232l9.218-9.218a2.729,2.729,0,0,0-1.925-4.658,4.3,4.3,0,0,0-1.912.449l-3.919,1.96V5.7a2.852,2.852,0,0,0-4.277-2.469V2.852a2.852,2.852,0,1,0-5.7,0v.384A2.851,2.851,0,0,0,4.277,5.7v.383A2.851,2.851,0,0,0,0,8.556V24.95a9.278,9.278,0,0,0,9.268,9.267Zm15.1-15.682a1.3,1.3,0,0,1,.918,2.224l-9.22,9.219a9.543,9.543,0,0,1-6.8,2.814A7.851,7.851,0,0,1,1.427,24.95V8.555a1.426,1.426,0,1,1,2.851,0v6.414a.713.713,0,1,0,1.426,0V5.7a1.426,1.426,0,0,1,2.851,0v9.264a.713.713,0,1,0,1.426,0V2.852a1.426,1.426,0,0,1,2.851,0V14.969a.713.713,0,1,0,1.426,0V5.7a1.426,1.426,0,0,1,2.851,0V20.672a.713.713,0,0,0,1.032.637L23.1,18.835a2.863,2.863,0,0,1,1.27-.3Z" transform="translate(0 0)" fill="#1aaa9d"/>
                  </g>
                </g>
              </g>
            </svg>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
  };
</script>
<style>
    .clap-thanks-container__doctor-btn .svgContainer{
      position: relative;
      transform: scale(.8);
    }
   .clap-thanks-container__doctor-btn .clapsInner {
     -webkit-animation-name: claps-animation;
     animation-name: claps-animation;
     -webkit-animation-duration: .6s;
     animation-duration: .6s;
     -webkit-animation-iteration-count: infinite;
     animation-iteration-count: infinite;
   }
  .clap-thanks-container__doctor-btn{
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .clap-animation__container .thanks-container__doctor-btn{
    display: flex;
  }
  .clap-animation__container{
    position: absolute;
    height: 100px;
    width: 100px;
    /*background: red;*/
    bottom: 0px;
    left: -33px;
  }
  .clap-animation__container.active .clap-thanks-container__doctor-btn:nth-child(1) .thanks-container__doctor-btn{
    animation-name: clap-el1;
    animation-duration: .8s;
    animation-iteration-count: initial;
    animation-timing-function: linear;
  }
    .clap-animation__container.active .clap-thanks-container__doctor-btn:nth-child(2) .thanks-container__doctor-btn{
      animation-name: clap-el2;
      animation-delay: .1s;
      animation-duration: 1s;
      animation-iteration-count: initial;
      animation-timing-function: linear;
    }
    .clap-animation__container.active .clap-thanks-container__doctor-btn:nth-child(3) .thanks-container__doctor-btn{
      animation-name: clap-el3;
      animation-delay: .2s;
      animation-duration: 1s;
      animation-iteration-count: initial;
      animation-timing-function: linear;
    }
    .clap-animation__container.active .clap-thanks-container__doctor-btn:nth-child(4) .thanks-container__doctor-btn{
      animation-name: clap-el4;
      animation-delay: .3s;
      animation-duration: 1s;
      animation-iteration-count: initial;
      animation-timing-function: linear;
    }
    .clap-animation__container.active .clap-thanks-container__doctor-btn:nth-child(5) .thanks-container__doctor-btn{
      animation-name: clap-el5;
      animation-delay: .6s;
      animation-duration: .8s;
      animation-iteration-count: initial;
      animation-timing-function: linear;
    }

    @keyframes clap-el1 {
      0%   {
        transform: translate3d(0px, 0px, 0px);
      }
      50%  {
        opacity: 1;
        transform : translate3d(-100%, -175%, 0px);
      }
      100% {
        opacity: 0;
        transform : translate3d(-200%, -175%, 0px);
      }
    }
    @keyframes clap-el2 {
      0%   {
        transform: translate3d(0px, 0px, 0px);
      }
      50%  {
        opacity: 1;
        transform : translate3d(-100%, -175%, 0px);
      }
      100% {
        opacity: 0;
        transform : translate3d(-200%, -350%, 0px);
      }
    }
    @keyframes clap-el3 {
      0%   {
        transform: translate3d(0px, 0px, 0px);
      }
      50%  {
        opacity: 1;
        transform : translate3d(100%, -175%, 0px);
      }
      100% {
        opacity: 0;
        transform : translate3d(200%, -350%, 0px);
      }
    }
    @keyframes clap-el4 {
      0%   {
        transform: translate3d(0px, 0px, 0px);
      }
      50%  {
        opacity: 1;
        transform : translate3d(150%, -220%, 0px);
      }
      100% {
        opacity: 0;
        transform : translate3d(250%, -400%, 0px);
      }
    }
    @keyframes clap-el5 {
      0%   {
        transform: translate3d(0px, 0px, 0px);
      }
      50%  {
        opacity: 1;
        transform : translate3d(0%, 120%, 0px);
      }
      100% {
        opacity: 0;
        transform : translate3d(250%, -300%, 0px);
      }
    }
</style>
