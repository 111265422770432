import _ from "lodash";

export default {
  data() {
    return {


    }
  },

  watch: {


  },

  methods: {

    smartSingleUrl(type, contentObject, paths){

      // if(this.$route.params.id == contentObject.id) return false;

      let singlePath = '/';

      if(type){
        singlePath += type;
      }
      if(contentObject && contentObject.id){
        singlePath += '/'+contentObject.id+'/'+contentObject.slug;
      }
      if(paths){
        singlePath += '/'+paths;
      }

      if(this.$route.path == singlePath)return false;

      this.$router.push(singlePath);

      return false;

    },

    resetUrl(){
      if(this.$route.path == '/')return false;
      this.$router.push('/');
    }

  }

};
