<template>
  <li class="listitem">
    {{source.title}}
    <div class="stuff-list-svg">
      <div class="svg" @click="sendApplause()">
        <div class="thanks-container__doctor-btn">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="26.517"
            height="28"
            viewBox="0 0 26.517 28"
          >
            <path
              id="Path_1"
              data-name="Path 1"
              d="M123.288,46.4V43.347a2.887,2.887,0,0,0-5.774,0V43.8l-4.43-4.43a2.853,2.853,0,0,0-4.631.869,2.887,2.887,0,0,0-4.308,2.35,2.886,2.886,0,0,0-2.722,2.722,2.887,2.887,0,0,0-1.881,4.924l.8.8a2.886,2.886,0,0,0-.8,4.647l9.073,9.073a6.1,6.1,0,0,0,8.62,0l6.945-6.945a3.5,3.5,0,0,0,1.034-2.5V49.122a2.892,2.892,0,0,0-1.925-2.721Zm-12.881-5.668a.931.931,0,0,1,1.316,0l6.073,6.073a.962.962,0,0,0,1.643-.681V43.347a.962.962,0,0,1,1.925,0V46.4h0a2.892,2.892,0,0,0-1.925,2.722V51.08l-9.032-9.032a.932.932,0,0,1,0-1.316Zm-4.06,1.338a.963.963,0,0,1,1.361,0l.8.8a2.878,2.878,0,0,0-1.361,1.361l-.8-.8a.963.963,0,0,1,0-1.361Zm16.941,13.244a1.594,1.594,0,0,1-.47,1.134l-6.945,6.945a4.175,4.175,0,0,1-5.9,0l-4.99-4.991L100.9,54.32a.962.962,0,0,1,1.361-1.361l4.083,4.083a.962.962,0,1,0,1.361-1.361L100.9,48.876a.962.962,0,1,1,1.361-1.361l6.805,6.8a.948.948,0,0,0,.073.066.963.963,0,0,0,1.215,0,.948.948,0,0,0,.073-.066.962.962,0,0,0,0-1.361l-6.8-6.805a.962.962,0,0,1,1.361-1.361l2.722,2.722L111.79,51.6a.962.962,0,0,0,1.361-1.361l-4.083-4.083a.962.962,0,1,1,1.361-1.361l4.083,4.083,5.208,5.208a.962.962,0,0,0,1.643-.68V49.122a.962.962,0,1,1,1.925,0Zm0,0"
              transform="translate(-98.695 -38.537)"
              fill="#1aaa9d"
            />
          </svg>
          <div class="thanks-container__doctor-btn-stat upper-1">
            {{applause}}
          </div>
          <div class="tooltip">
            <div> {{$fn.tr('დაუკარი ტაში')}}</div>
            <span><i></i></span>
          </div>
        </div>
      </div>
      <div class="svg" @click="showFormAction('sms')">

        <div class="thanks-container__doctor-btn">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22.584"
            height="28"
            viewBox="0 0 22.584 28"
          >
            <path
              id="Path_2"
              data-name="Path 2"
              d="M71.121,6.283A3.347,3.347,0,0,0,66.9,5.866V2.461A2.464,2.464,0,0,0,64.444,0H51.975a2.464,2.464,0,0,0-2.461,2.461V25.539A2.464,2.464,0,0,0,51.975,28H64.444A2.464,2.464,0,0,0,66.9,25.539V15.226l4.217-4.217A3.345,3.345,0,0,0,71.121,6.283ZM51.975,1.641h5.414v.984a.82.82,0,0,0,1.641,0V1.641h5.414a.821.821,0,0,1,.82.82V5.866a3.341,3.341,0,0,0-4.217,5.143l4.217,4.217V22.8H51.155V2.461A.821.821,0,0,1,51.975,1.641Zm13.289,23.9a.821.821,0,0,1-.82.82H51.975a.821.821,0,0,1-.82-.82V24.445H65.264Zm4.7-15.69-3.877,3.877L62.207,9.849a1.7,1.7,0,0,1,2.405-2.405l.891.891a.821.821,0,0,0,.991.129c.135-.078.123-.083,1.06-1.02a1.7,1.7,0,0,1,2.405,2.405Z"
              transform="translate(-49.514 0)"
              fill="#1aaa9d"
            />
          </svg>
          <div class="thanks-container__doctor-btn-stat upper-1">
            {{source.sms_thanks}}
          </div>
          <div class="tooltip">
            <div>{{$fn.tr('გაგზავნე სმს')}}</div>
            <span><i></i></span>
          </div>
        </div>
      </div>
      <div class="svg" @click="showFormAction('email')">

        <div class="thanks-container__doctor-btn">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22.553"
            height="28"
            viewBox="0 0 22.553 28"
          >
            <path
              id="Path_3"
              data-name="Path 3"
              d="M71.534,21.2H69.4V.82a.82.82,0,0,0-.82-.82H50.621a.82.82,0,0,0-.82.82V24.23A3.775,3.775,0,0,0,53.571,28H68.584a3.775,3.775,0,0,0,3.77-3.77V22.021A.82.82,0,0,0,71.534,21.2Zm-15.833.82V24.23a2.13,2.13,0,1,1-4.259,0V1.641H67.763V21.2H56.521A.82.82,0,0,0,55.7,22.021ZM70.713,24.23a2.132,2.132,0,0,1-2.13,2.13h-11.9a3.749,3.749,0,0,0,.661-2.13V22.842H70.713Z"
              transform="translate(-49.801)"
              fill="#1aaa9d"
            />
          </svg>
          <div class="thanks-container__doctor-btn-stat upper-1">
            {{source.sent_thanks}}
          </div>
          <div class="tooltip">
            <div>{{$fn.tr('გაგზავნე ემაილი')}}</div>
            <span><i></i></span>
          </div>
        </div>
      </div>
    </div>
  </li>
</template>

<script>
  import url from "@/mixins/url";

  export default {
    name: 'item-component',
    props: {
      index: {
        type: Number
      },
      source: {
        type: Object,
        default () {
          return {}
        }
      }
    },
    mixins: [url],

    data() {
      return {
        applause: this.source.applause,
      };
    },
    mounted() {
      if(this.$route.params.id == this.source.id) {
        this.showFormAction()
      }
    },
    methods: {
      showFormAction(to) {

        this.smartSingleUrl('doctor', this.source );

        this.$store.commit('main/setState', {key: 'popupDoctorInfo', val: this.source});
        this.$store.commit('main/setState', {key: 'showFormPopup', val: {state: true, to: to}});
      },
      async sendApplause(){
        let tmp = await this.$fn.postData('main/applause', {id: this.source.id });

        this.applause = tmp.data

        this.$store.dispatch('main/getFeedbackCounts')
      }
    }
  }
</script>
