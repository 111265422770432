<template>
  <div class="main-page-router-view">
    <component :is="loadedComponent" :key="enableComponent" />
  </div>
</template>

<script>
/// !!!!!! template names here should be same as in laravel config/adminpanel.php:layouts configuration keys
////// layout templates
import Home from '../Home'


////// 404 page
import mainMixin from "@/mixins/main";

let myBody = null;

export default {
  mixins: [mainMixin],
  data() {
    return {

      pageLoadStatus: 'none',

      enableComponent: false,
      animateTxt: true,
      activeTab: 1,
      aboutTabHeight: 0,
      selectedMenu: {},

      defaultComponent: 'Home',
      componentToLoad: '', /// template to load
      loadedComponent: '', /// template to load


      routeParams: {},
      fullPath: '',

      innerTemplates: {
        Home: 'Home',
      }
    }
  },
  components: {
    Home
  },
  created() {
    // this.index();
    // console.log(this.$route.params);
  },

  watch: {

    "$route.params": {
      immediate: true,
      handler: function () {
        this.index();
      }
    },

    '$store.state.main.current_content_request_status': {
      immediate: true,
      handler: function () {
        // console.log('current_content_request_status', this.$store.state.main.current_content_request_status);
        if(this.$store.state.main.current_content_request_status === 'done') {

          this.loadNewPage();

        }

      }
    },

    'pageLoadStatus': function (){
      if(this.pageLoadStatus === 'waitContent' && this.$store.state.main.current_content_request_status === 'done') this.loadNewPage();
    }

  },

  computed: {

  },

  methods: {

    index() {
      this.pageLoadStatus = 'started';
      // console.log('pageLoadStatus: ',this.pageLoadStatus);

      // console.log(this.enableComponent, '--------');

      //// if not loaded main index data return false;
      if (!_.get(this.$store.state.main.indx, 'menus.0.id')) return false;

      /// set route params and path
      this.getRouteParams();
      // console.log(this.fullPath, this.routeParams);

      //// GET MENU FROM PATH !!!!!!!!!!!!!!!
      this.selectedMenu = this.getMenuFromPath();
      // console.log(this.selectedMenu.id);
      this.findDynamicComponent();

      //// load homepage by default
      if (!this.selectedMenu) {//&& _.size(this.$route.params)==0
        this.selectedMenu = this.$store.getters['main/getFromList']({
          key: 'indx.menus',
          val: _.get(this.$store.state.main.indx, 'home_content.home_id')
        });

        // console.log(this.selectedMenu);
        // console.log(breadCrumbs);
        // console.log(this.$store.state.menuStatus);
      }

      if (!this.selectedMenu) return false;
      // this.enableComponent = false;

      let breadCrumbs = this.getBreadCrumbs(this.selectedMenu);
      this.$store.commit('main/setStateDeep', {key: 'breadcrumbsLeveled', val: {}});
      for (let i in breadCrumbs) {
        this.$store.commit('main/setStateDeep', {
          key: 'menuStatus.level' + (parseInt(i) + 1),
          val: breadCrumbs[i].id
        });
        this.$store.commit('main/setStateDeep', {
          key: 'breadcrumbsLeveled.lvl' + (parseInt(i) + 1),
          val: breadCrumbs[i].id
        });
      }

      setTimeout(() => {
        this.addBodyClass(breadCrumbs);
      }, 0)

      // this.selectedMenu['routeParams'] = this.routeParams;
      this.$set(this.selectedMenu, 'routeParams', this.routeParams);
      // console.log(this.selectedMenu);
      this.$store.commit('main/setCurrentMenuLocalePaths');

      this.$store.dispatch('main/getCurrentContentProto', this.selectedMenu);
      // console.log('page index: loaded current content');

      this.pageLoadStatus = 'waitContent';
      // console.log('pageLoadStatus: ',this.pageLoadStatus);



    },

    loadNewPage(){
      this.pageLoadStatus = 'startChanging';
      // console.log('pageLoadStatus: ',this.pageLoadStatus);

      this.enableComponent = false;

      this.$store.commit('main/setState', {key: 'selected_menu', val: this.selectedMenu});
      this.$store.commit('main/setCurrentContentFromBuffer');
      this.loadedComponent = this.componentToLoad;


      setTimeout(() => {
        this.enableComponent = true;
        // this.$store.commit('main/setState', {key: 'showSite', val: true });
      }, 1);

      setTimeout(() => {
        // this.enableComponent = true;
        this.$store.commit('main/setState', {key: 'showSite', val: true });
      }, 10);


      this.pageLoadStatus = 'pageLoaded';
      // console.log('pageLoadStatus: ',this.pageLoadStatus);


    },

    getRouteParams() {
      this.routeParams = {};
      let ready = false;
      let contentId = 0;

      for (let i in this.$route.params) {

        if (this.pathEndKeywords.indexOf(this.$route.params[i]) >= 0) {
          ready = this.$route.params[i];
          continue;
        }

        if (ready) {
          this.$set(this.routeParams, ready, parseInt(this.$route.params[i]));
          ready = false;
        }

      }

      this.fullPath = this.getPath();

      // this.$store.commit('setState', {key: 'routeParams', val: this.routeParams })
      // console.log(this.routeParams);
      // console.log(contentId);
      return false;

    },

    addBodyClass(breadcrumb) {
      let myBody = document.getElementsByTagName('body')[0];
      // document.body.className = document.body.className.replace("no-javascript","");
      document.body.className = '';
      // myBody.classList.remove('');
      for (let i in breadcrumb) {
        myBody.classList.add(_.get(breadcrumb[i], 'url'));
      }

    },

    /// find component depend on selected menu
    findDynamicComponent() {

      let template = this.defaultComponent;

      if (_.get(this.selectedMenu, 'secondary_template') && this.$options.components[this.selectedMenu.secondary_template]) {
        // console.log(3333);
        template = this.selectedMenu.secondary_template;
      }

      if (parseInt(_.get(this.routeParams, 'singleview')) || parseInt(_.get(this.routeParams, 'productview'))) {
        // console.log(55555);
        template = this.getSingleViewTemplate(template); //_.get( this.innerTemplates, template);
      }

      console.log('loading template: ', template, '; selected menu: ', _.get(this.selectedMenu, 'title'), ':', _.get(this.selectedMenu, 'id'));
      this.componentToLoad = template;
      return template;

    },

    getSingleViewTemplate(template) {

      let singleViewTemplate = _.get(this.$store.state.main.indx, ['contentTypes', _.get(this.$store.state.main.current_content, 'primary.data.list.0.content_type'), 'settings', 'single_template']);

      // console.log(_.get(this.$store.state.main.current_content, 'primary.data.list.0.content_type'), singleViewTemplate);

      if (!singleViewTemplate && _.get(this.innerTemplates, template)) singleViewTemplate = _.get(this.innerTemplates, template);

      if (this.$options.components[singleViewTemplate]) template = singleViewTemplate;

      return template;
    },

  },
}
</script>


<style>
.fade-enter-active, .fade-leave-active {
  transition: opacity .4s;
}

.fade-enter, .fade-leave-to
{
  transition: opacity .2s;
  opacity: 0;
}

</style>
