import _ from 'lodash';

export default {
  menuStatus(state){
    return function (params) {
      return state.menuStatus[params]
    }
  },
  getMenu(state) {
    return state.menu
  },

  getLinks(state) {
    return state.links
  },
  getFooter(state) {
    return state.footer
  },

  ///  get item from any list(key) using value(val(id...))
  getFromList: function (state) {
    return function (params) {
      if(!_.get(state, params.key) || !_.get(params,'val'))return {};
      params.field = params.field?params.field:'id';

      for (let i in _.get(state, params.key)){
        if( _.get(state, params.key+'.'+i+'.'+params.field) == params.val )return JSON.parse(JSON.stringify( _.get(state, params.key+'.'+i) ));
      }

      return {};

    }
  },

  getList: function (state) {
    return function (params) {
      if(!_.get(state, params.key))return {};
      return JSON.parse(JSON.stringify( _.get(state, params.key ) ));
    }
  },

  filterListBy: function (state) {
    return function (params) {
      if(!_.get(state, params.key) || !_.get(params,'val'))return {};
      params.field = params.field?params.field:'id';

      const ret = [];
      for (const i in _.get(state, params.key)){
        if( _.get(state, params.key+'.'+i+'.'+params.field) == params.val )ret.push( JSON.parse(JSON.stringify( _.get(state, params.key+'.'+i) )) );
      }

      return ret;

    }
  },

  getStateItem: function (state) {
    return function (params){
      if(!_.get(state, params.key))return false;
      return JSON.parse(JSON.stringify( _.get(state, params.key) ));
    }
  },

  /// get single url for inner pages
  /// set currentPage: 1 to open content under current menu; 0 to open content under content type default location
  getSingleUrl(state, getters){
    return function (params, currentPage){
      if(!parseInt(_.get(params, 'id')))return '#';
      let singlePath = '/singleview/'+params.id+'-'+params.slug;

      /// searchs if exists related menu to this content; if exists return menu url
      let relatedMenu = getters.getContentRelatedMenu(params.id);
      if(relatedMenu)return relatedMenu;

      if (currentPage==1){
        return state.selected_menu.full_url+''+singlePath;
      }

      /// find default page for given content type
      let defaultPageId =         _.get(state.indx, ['contentTypes', params.content_type, 'settings', 'default_page']);
      let defaultSingleTemplate = _.get(state.indx, ['contentTypes', params.content_type, 'settings', 'single_template']);
      let defaultListTemplate =   _.get(state.indx, ['contentTypes', params.content_type, 'settings', 'list_template']);

      /// find nearest child with related default template

      let tmp = getters.searchSecondaryTemplate(_.get(state.selected_menu, 'pid'), defaultSingleTemplate, defaultListTemplate);

      if(tmp)return tmp.full_url+''+singlePath;

      /// find default menu for this content
      let defaultPage = _.find(state.indx.menus, { id: defaultPageId });

      /// if not set default page for post type use current page as inner template
      if(!defaultPage) return state.selected_menu.full_url+''+singlePath;

      let full_url = _.get(defaultPage, 'full_url');

      return full_url+''+singlePath;
    }
  },



  /// recursive method to find desired template inside parent id
  searchSecondaryTemplate(state, getters){
    return function (parentId, defaultSingleTemplate, defaultListTemplate){
      let siblings = _.filter(state.indx.menus, { pid: parentId });

      for (let i in siblings){
        // console.log(parentId, _.get(siblings[i], ['secondary_content','template']), defaultSingleTemplate, defaultListTemplate);
        if(_.get(siblings[i], ['secondary_content','template']) && (_.get(siblings[i], ['secondary_content','template']) == defaultSingleTemplate || _.get(siblings[i], ['secondary_content','template']) == defaultListTemplate )){
          return siblings[i];
        }

        let ret = getters.searchSecondaryTemplate(siblings[i].id, defaultSingleTemplate, defaultListTemplate);
        if(ret.id)return ret;

      }

      return false;

    }
  },

  /// get taxonomy term url under current url
  getCategoryUrl(state){
    return function (categoryId, currentPage){
      if(!parseInt(categoryId))return '#';
      let singlePath = '/content-category/'+categoryId;

      // if (currentPage){
      //   return state.selected_menu.full_url+''+singlePath;
      // }

      return state.selected_menu.full_url+''+singlePath;

    }
  },

  getContentRelatedMenu(state){
    return function (contentId) {
      for (let i in state.indx.menus){
        if(_.get(state.indx.menus[i], ['primary_content', 'page']) == contentId)return state.indx.menus[i].full_url;
      }
      return false;
    }
  },

  getPopupData: function(state){
    return state.popupDoctorInfo;
  }
}
