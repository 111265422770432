import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import AOS from 'aos';
import axios from "axios"

import 'element-ui/lib/theme-chalk/index.css';
import 'aos/dist/aos.css'
import "./assets/css/main.css"
import 'swiper/swiper-bundle.css';


import './RegisterPlugins';


AOS.init({
  duration: 800,
  startEvent: 'DOMContentLoaded',
  useClassNames: false,
  once: true,
})


import VueMeta from 'vue-meta'
Vue.use(VueMeta)


Vue.config.productionTip = false

import fn from "./helpers/func";
Vue.prototype.$fn = fn;

import cf from "./helpers/conf";
Vue.prototype.$cf = cf;

import _ from 'lodash'
Vue.use(_);
Vue.prototype._ = _;


import SlideUpDown from 'vue-slide-up-down'
Vue.component('slide-up-down', SlideUpDown)

new Vue({
  router: router,
  store,
  beforeCreate: function () {
    if (this.$route.query.preview) this.$cookies.set("siteMode", this.$route.query.preview, "1d");
    this.$store.dispatch('main/indx');
  },

  mounted() {
    window.scrollTo(0, 0);
  },
  watch: {
    '$route.name': {
      immediate: true,
      handler() {

        /// set selected locale
        /// set axios default header for selected language
        /// send main request to server

        if (!this.$route.name) return false;

        this.$store.commit('main/setState', { key: 'locale', val: this.$route.name });
        this.$store.commit('main/setMenuFullUrls');

        axios.defaults.headers.common['lang'] = this.$store.state.locale;

        // if (this.$cookies.get("siteMode")) axios.defaults.headers.common['siteMode'] = this.$cookies.get("siteMode");

        /// load indx part after every language change
        this.$store.dispatch('main/indxTranslatable');

        /// loads only once
        this.$store.dispatch('main/getTranslations');

      }
    },
  },
  render: h => h(App)
}).$mount('#app')
