<template>
  <portal to="destination">
    <div class="popup" :class="{ show: showMore || thankYou || isForm || isSocial , 'small': isSocial}">
      <div class="popup-wrapper stl_scrl">
        <div class="cross" @click="closePopup">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="15.424"
            height="15.424"
            viewBox="0 0 15.424 15.424"
          >
            <path
              id="Icon_metro-cancel"
              data-name="Icon metro-cancel"
              d="M22.815,9.64l-4.82,4.82-4.82-4.82-2.892,2.892,4.82,4.82-4.82,4.82,2.892,2.892,4.82-4.82,4.82,4.82,2.892-2.892-4.82-4.82,4.82-4.82Z"
              transform="translate(-10.283 -9.64)"
              fill="#1aaa9d"
            />
          </svg>
        </div>
        <div class="main-content max-width-container padding-center" v-if="!isSocial">
          <div class="top-sector">
            <div class="doctor-image" v-if="_.get(infoData,'images.0.devices.desktop') && !thankYou">
              <img :src=" _.get(infoData,'images.0.devices.desktop')" alt="doctor" />
            </div>

            <div class="doctor-info" v-if="!isForm && !thankYou && showMore">
              <div class="doctor-info-wrapper">
                <div class="doctor-info-title noto-bold">
                 {{_.get(infoData,'title')}}
                </div>
                <div class="doctor-info-description noto-regular">
                  {{_.get(infoData,'teaser')}}
                </div>
              </div>
              <div class="doctor-action-buttons">
                <div class="thanks-container__doctor-dashboard" v-if="isMobile">
                  <div class="thanks-container__doctor-dashboard-inner">
                    <div class="thanks-container__doctor-btn-outer" @click="sendApplause">
                      <div class="thanks-container__doctor-btn">
                        <ClapAnimation v-if="anim"/>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="26.517"
                          height="28"
                          viewBox="0 0 26.517 28"
                        >
                          <path
                            id="Path_1"
                            data-name="Path 1"
                            d="M123.288,46.4V43.347a2.887,2.887,0,0,0-5.774,0V43.8l-4.43-4.43a2.853,2.853,0,0,0-4.631.869,2.887,2.887,0,0,0-4.308,2.35,2.886,2.886,0,0,0-2.722,2.722,2.887,2.887,0,0,0-1.881,4.924l.8.8a2.886,2.886,0,0,0-.8,4.647l9.073,9.073a6.1,6.1,0,0,0,8.62,0l6.945-6.945a3.5,3.5,0,0,0,1.034-2.5V49.122a2.892,2.892,0,0,0-1.925-2.721Zm-12.881-5.668a.931.931,0,0,1,1.316,0l6.073,6.073a.962.962,0,0,0,1.643-.681V43.347a.962.962,0,0,1,1.925,0V46.4h0a2.892,2.892,0,0,0-1.925,2.722V51.08l-9.032-9.032a.932.932,0,0,1,0-1.316Zm-4.06,1.338a.963.963,0,0,1,1.361,0l.8.8a2.878,2.878,0,0,0-1.361,1.361l-.8-.8a.963.963,0,0,1,0-1.361Zm16.941,13.244a1.594,1.594,0,0,1-.47,1.134l-6.945,6.945a4.175,4.175,0,0,1-5.9,0l-4.99-4.991L100.9,54.32a.962.962,0,0,1,1.361-1.361l4.083,4.083a.962.962,0,1,0,1.361-1.361L100.9,48.876a.962.962,0,1,1,1.361-1.361l6.805,6.8a.948.948,0,0,0,.073.066.963.963,0,0,0,1.215,0,.948.948,0,0,0,.073-.066.962.962,0,0,0,0-1.361l-6.8-6.805a.962.962,0,0,1,1.361-1.361l2.722,2.722L111.79,51.6a.962.962,0,0,0,1.361-1.361l-4.083-4.083a.962.962,0,1,1,1.361-1.361l4.083,4.083,5.208,5.208a.962.962,0,0,0,1.643-.68V49.122a.962.962,0,1,1,1.925,0Zm0,0"
                            transform="translate(-98.695 -38.537)"
                            fill="#1aaa9d"
                          />
                        </svg>
                        <div class="thanks-container__doctor-btn-stat upper-1">
                          {{applause}}
                        </div>
                        <div class="tooltip">
                          <div>{{$fn.tr('დაუკარი ტაში')}}</div>
                          <span><i></i></span>
                        </div>
                      </div>
                    </div>
                    <div class="thanks-container__doctor-btn-outer" @click="showFormAction('sms')">
                      <div class="thanks-container__doctor-btn">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="22.584"
                          height="28"
                          viewBox="0 0 22.584 28"
                        >
                          <path
                            id="Path_2"
                            data-name="Path 2"
                            d="M71.121,6.283A3.347,3.347,0,0,0,66.9,5.866V2.461A2.464,2.464,0,0,0,64.444,0H51.975a2.464,2.464,0,0,0-2.461,2.461V25.539A2.464,2.464,0,0,0,51.975,28H64.444A2.464,2.464,0,0,0,66.9,25.539V15.226l4.217-4.217A3.345,3.345,0,0,0,71.121,6.283ZM51.975,1.641h5.414v.984a.82.82,0,0,0,1.641,0V1.641h5.414a.821.821,0,0,1,.82.82V5.866a3.341,3.341,0,0,0-4.217,5.143l4.217,4.217V22.8H51.155V2.461A.821.821,0,0,1,51.975,1.641Zm13.289,23.9a.821.821,0,0,1-.82.82H51.975a.821.821,0,0,1-.82-.82V24.445H65.264Zm4.7-15.69-3.877,3.877L62.207,9.849a1.7,1.7,0,0,1,2.405-2.405l.891.891a.821.821,0,0,0,.991.129c.135-.078.123-.083,1.06-1.02a1.7,1.7,0,0,1,2.405,2.405Z"
                            transform="translate(-49.514 0)"
                            fill="#1aaa9d"
                          />
                        </svg>
                        <div class="thanks-container__doctor-btn-stat upper-1">
                          {{sendSms}}
                        </div>
                        <div class="tooltip">
                          <div>{{$fn.tr('გაგზავნე სმს')}}</div>
                          <span><i></i></span>
                        </div>
                      </div>
                    </div>
                    <div class="thanks-container__doctor-btn-outer" @click="showFormAction('email')">
                      <div class="thanks-container__doctor-btn">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="22.553"
                          height="28"
                          viewBox="0 0 22.553 28"
                        >
                          <path
                            id="Path_3"
                            data-name="Path 3"
                            d="M71.534,21.2H69.4V.82a.82.82,0,0,0-.82-.82H50.621a.82.82,0,0,0-.82.82V24.23A3.775,3.775,0,0,0,53.571,28H68.584a3.775,3.775,0,0,0,3.77-3.77V22.021A.82.82,0,0,0,71.534,21.2Zm-15.833.82V24.23a2.13,2.13,0,1,1-4.259,0V1.641H67.763V21.2H56.521A.82.82,0,0,0,55.7,22.021ZM70.713,24.23a2.132,2.132,0,0,1-2.13,2.13h-11.9a3.749,3.749,0,0,0,.661-2.13V22.842H70.713Z"
                            transform="translate(-49.801)"
                            fill="#1aaa9d"
                          />
                        </svg>
                        <div class="thanks-container__doctor-btn-stat upper-1">
                          {{sendEmail}}
                        </div>
                        <div class="tooltip">
                          <div>{{$fn.tr('გაგზავნე ემაილი')}}</div>
                          <span><i></i></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="doctor-action-buttons__share">
                  <div class="share-title noto-bold" :title="shareFullUrl">
                    {{$fn.tr('გააზიარე ისტორია')}}
                  </div>

                  <div class="share-buttons">
                    <ShareNetwork
                      network="facebook"
                      :url="shareFullUrl"
                      :title="_.get(infoData,'title')"
                      :description="_.get(infoData,'teaser')"
                      :quote="_.get(infoData,'title')"
                      hashtags="miceremadloba">
                     <svg
                        id="Group_4725"
                        data-name="Group 4725"
                        xmlns="http://www.w3.org/2000/svg"
                        width="30"
                        height="30.002"
                        viewBox="0 0 30 30.002">
                        <path
                          id="Subtraction_1"
                          data-name="Subtraction 1"
                          d="M15.258,30h0L3.137,30A3.141,3.141,0,0,1,0,26.863V3.137A3.141,3.141,0,0,1,3.137,0H26.863A3.141,3.141,0,0,1,30,3.137V26.863A3.141,3.141,0,0,1,26.863,30H20.188V19.34h3.677l.7-4.559H20.188V11.82a2.274,2.274,0,0,1,2.571-2.464h1.991V5.473a24.268,24.268,0,0,0-3.532-.308c-3.731,0-5.958,2.3-5.958,6.139V14.78H11.252V19.34h4.006V30Z"
                          fill="#1aaa9d"
                        />
                      </svg>
                  </ShareNetwork>

                  <ShareNetwork
                      network="Twitter"
                      :url="shareFullUrl"
                      :title="_.get(infoData,'title')"
                      :description="_.get(infoData,'teaser')"
                      :quote="_.get(infoData,'title')"
                      hashtags="miceremadloba">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="30"
                      viewBox="0 0 30 30">
                      <path
                        id="Subtraction_2"
                        data-name="Subtraction 2"
                        d="M26.864,30H3.138A3.142,3.142,0,0,1,0,26.862V3.138A3.142,3.142,0,0,1,3.138,0H26.864A3.141,3.141,0,0,1,30,3.138V26.862A3.141,3.141,0,0,1,26.864,30ZM4.419,21.172v0a12.528,12.528,0,0,0,6.79,1.983A12.46,12.46,0,0,0,23.79,10.575c0-.188,0-.383-.013-.576a9.444,9.444,0,0,0,2.217-2.286,8.806,8.806,0,0,1-2.546.684A4.4,4.4,0,0,0,25.392,5.96a8.778,8.778,0,0,1-2.806,1.069,4.426,4.426,0,0,0-7.654,3.025,4.925,4.925,0,0,0,.11,1.013A12.6,12.6,0,0,1,5.922,6.44a4.37,4.37,0,0,0-.6,2.232,4.416,4.416,0,0,0,1.972,3.682,4.493,4.493,0,0,1-2-.561v.054a4.442,4.442,0,0,0,3.546,4.34,4.711,4.711,0,0,1-1.163.15,5.539,5.539,0,0,1-.835-.067,4.432,4.432,0,0,0,4.135,3.067,8.817,8.817,0,0,1-5.49,1.888A9.036,9.036,0,0,1,4.419,21.172Z"
                        fill="#1aaa9d"
                      />
                    </svg>
                  </ShareNetwork>

                  <ShareNetwork
                      network="LinkedIn"
                      :url="shareFullUrl"
                      :title="_.get(infoData,'title')"
                      :description="_.get(infoData,'teaser')"
                      :quote="_.get(infoData,'title')"
                      hashtags="miceremadloba">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="30"
                      viewBox="0 0 30 30">
                      <path
                        id="Icon_awesome-linkedin"
                        data-name="Icon awesome-linkedin"
                        d="M27.857,2.25H2.136A2.152,2.152,0,0,0,0,4.413V30.087A2.152,2.152,0,0,0,2.136,32.25H27.857A2.158,2.158,0,0,0,30,30.087V4.413A2.158,2.158,0,0,0,27.857,2.25ZM9.067,27.964H4.621V13.647H9.074V27.964ZM6.844,11.692A2.578,2.578,0,1,1,9.422,9.114,2.579,2.579,0,0,1,6.844,11.692ZM25.734,27.964H21.288V21c0-1.661-.033-3.8-2.31-3.8-2.317,0-2.672,1.808-2.672,3.676v7.085H11.859V13.647h4.266V15.6h.06a4.683,4.683,0,0,1,4.212-2.31c4.5,0,5.337,2.967,5.337,6.824Z"
                        transform="translate(0 -2.25)"
                        fill="#1aaa9d"
                      />
                    </svg>
                  </ShareNetwork>

                    <span @click="copyUrl">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="30"
                        height="30"
                        viewBox="0 0 30 30">
                        <path
                          id="Subtraction_3"
                          data-name="Subtraction 3"
                          d="M26.864,30H3.138A3.142,3.142,0,0,1,0,26.862V3.138A3.142,3.142,0,0,1,3.138,0H26.864A3.141,3.141,0,0,1,30,3.138V26.862A3.141,3.141,0,0,1,26.864,30ZM16.07,17.417v1.714h3.608a4.51,4.51,0,0,0,0-9.021H16.07v1.714h3.608a2.8,2.8,0,0,1,0,5.593ZM10.656,10.11a4.51,4.51,0,0,0,0,9.021h3.61V17.417h-3.61a2.8,2.8,0,0,1,0-5.593h3.61V10.11Zm.9,3.608v1.8h7.217v-1.8Z"
                          fill="#9f9f9f"
                        />
                      </svg>
                      <input type="hidden" id="copy-url" :value="fullUrl">
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <!--  Form Container-->
            <form @submit.prevent="sendForm" class="doctor-info" v-if="isForm && !thankYou && !showMore">
              <div class="doctor-info-wrapper">
                <div class="doctor-info-title noto-bold">
                  {{_.get(infoData,'title')}}
                </div>
              </div>

              <div class="send-message-form">
                <div class="send-message-form-title noto-bold">
                  {{$store.state.main.showFormPopup.to === 'email' ? $fn.tr('მიწერეთ შეტყობინება ექიმს') : $fn.tr('გაუგზავნე შეტყობინება მობილურზე')}}

                </div>
                <input
                  type="text"
                  v-model="name"
                  :maxlength="nameMax"
                  class="send-message-form-input noto-regular"
                  placeholder="სახელი და გვარი"
                />
                <div class="label error noto-regular" v-if="nameError"> {{$fn.tr('სახელი აუცილებელი ველია')}}</div>
                <textarea
                  type="textarea"
                  v-model="message"
                  :maxlength="max"
                  class="send-message-form-input noto-regular"
                  name="description"
                  cols="70"
                  rows="50"
                  placeholder="შეტყობინება..."
                  v-if="$store.state.main.showFormPopup.to !== 'email'"
                ></textarea>
                <textarea
                  v-else
                  type="textarea"
                  v-model="message"
                  class="send-message-form-input noto-regular"
                  name="description"
                  cols="70"
                  rows="50"
                  placeholder="შეტყობინება..."
                ></textarea>
                <div class="label error noto-regular" v-if="messageError"> {{$fn.tr('შეტყობინება აუცილებელი ველია')}}</div>
                <div class="input-info-texts">
                  <div class="info-item">
                    <input type="checkbox" v-model="showMyName"/>
                    <span class="noto-regular">{{$fn.tr('გამოჩნდეს ჩემი სახელი')}}</span>
                  </div>
                  <div class="info-item">
                    <span v-if="$store.state.main.showFormPopup.to !== 'email'" class="noto-regular">{{  $fn.tr('მაქს. 300 სიმბოლო')}}</span>
                  </div>
                </div>
                <button class="send-form noto-bold" type="submit">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18.747"
                    height="16.734"
                    viewBox="0 0 18.747 16.734"
                  >
                    <path
                      id="Icon_feather-heart"
                      data-name="Icon feather-heart"
                      d="M16.855,5.711a4.143,4.143,0,0,0-5.861,0l-.8.8-.8-.8a4.144,4.144,0,1,0-5.861,5.861l.8.8L10.2,18.231l5.861-5.861.8-.8a4.143,4.143,0,0,0,0-5.861Z"
                      transform="translate(-0.823 -2.997)"
                      fill="none"
                      stroke="#00aea9"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="3"
                    />
                  </svg>
                  {{$fn.tr('გაგზავნა')}}
                </button>
              </div>
            </form>

            <!--  Thank You  Container-->
            <div class="doctor-info" v-if="thankYou">
              <div class="doctor-info-wrapper">
                <div class="doctor-info-title noto-bold">
                  {{_.get(infoData,'title')}}
                </div>
                <div class="doctor-info-thank-you">
                  <div class="thank-you-svg">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="36"
                      height="26.846"
                      viewBox="0 0 36 26.846"
                    >
                      <path
                        id="Icon_awesome-check"
                        data-name="Icon awesome-check"
                        d="M12.227,30.9.527,19.2a1.8,1.8,0,0,1,0-2.546L3.073,14.1a1.8,1.8,0,0,1,2.546,0L13.5,21.986,30.382,5.1a1.8,1.8,0,0,1,2.546,0L35.473,7.65a1.8,1.8,0,0,1,0,2.546l-20.7,20.7A1.8,1.8,0,0,1,12.227,30.9Z"
                        transform="translate(0 -4.577)"
                        fill="#1aaa9d"
                      />
                    </svg>
                  </div>
                  <span class="noto-bold">
                          {{$fn.tr('გმადლობთ, თქვენი შეტყობინება გაგზავნილია !')}}</span
                  >
                </div>
                <div class="doctor-info-description noto-regular">
                  {{$fn.tr('მადლობის ტექსტი')}}
                </div>
              </div>
              <div class="doctor-action-buttons">
                <div class="test"></div>
                <div class="doctor-action-buttons__share">
                  <div class="share-title noto-bold" :title="shareFullUrl">
                    {{$fn.tr('გააზიარე ისტორია')}}

                  </div>
                  <div class="share-buttons">
                    <svg
                      id="Group_4725"
                      data-name="Group 4725"
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="30.002"
                      viewBox="0 0 30 30.002"
                    >
                      <path
                        id="Subtraction_1"
                        data-name="Subtraction 1"
                        d="M15.258,30h0L3.137,30A3.141,3.141,0,0,1,0,26.863V3.137A3.141,3.141,0,0,1,3.137,0H26.863A3.141,3.141,0,0,1,30,3.137V26.863A3.141,3.141,0,0,1,26.863,30H20.188V19.34h3.677l.7-4.559H20.188V11.82a2.274,2.274,0,0,1,2.571-2.464h1.991V5.473a24.268,24.268,0,0,0-3.532-.308c-3.731,0-5.958,2.3-5.958,6.139V14.78H11.252V19.34h4.006V30Z"
                        fill="#1aaa9d"
                      />
                    </svg>

                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="30"
                      viewBox="0 0 30 30"
                    >
                      <path
                        id="Subtraction_2"
                        data-name="Subtraction 2"
                        d="M26.864,30H3.138A3.142,3.142,0,0,1,0,26.862V3.138A3.142,3.142,0,0,1,3.138,0H26.864A3.141,3.141,0,0,1,30,3.138V26.862A3.141,3.141,0,0,1,26.864,30ZM4.419,21.172v0a12.528,12.528,0,0,0,6.79,1.983A12.46,12.46,0,0,0,23.79,10.575c0-.188,0-.383-.013-.576a9.444,9.444,0,0,0,2.217-2.286,8.806,8.806,0,0,1-2.546.684A4.4,4.4,0,0,0,25.392,5.96a8.778,8.778,0,0,1-2.806,1.069,4.426,4.426,0,0,0-7.654,3.025,4.925,4.925,0,0,0,.11,1.013A12.6,12.6,0,0,1,5.922,6.44a4.37,4.37,0,0,0-.6,2.232,4.416,4.416,0,0,0,1.972,3.682,4.493,4.493,0,0,1-2-.561v.054a4.442,4.442,0,0,0,3.546,4.34,4.711,4.711,0,0,1-1.163.15,5.539,5.539,0,0,1-.835-.067,4.432,4.432,0,0,0,4.135,3.067,8.817,8.817,0,0,1-5.49,1.888A9.036,9.036,0,0,1,4.419,21.172Z"
                        fill="#1aaa9d"
                      />
                    </svg>

                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="30"
                      viewBox="0 0 30 30"
                    >
                      <path
                        id="Icon_awesome-linkedin"
                        data-name="Icon awesome-linkedin"
                        d="M27.857,2.25H2.136A2.152,2.152,0,0,0,0,4.413V30.087A2.152,2.152,0,0,0,2.136,32.25H27.857A2.158,2.158,0,0,0,30,30.087V4.413A2.158,2.158,0,0,0,27.857,2.25ZM9.067,27.964H4.621V13.647H9.074V27.964ZM6.844,11.692A2.578,2.578,0,1,1,9.422,9.114,2.579,2.579,0,0,1,6.844,11.692ZM25.734,27.964H21.288V21c0-1.661-.033-3.8-2.31-3.8-2.317,0-2.672,1.808-2.672,3.676v7.085H11.859V13.647h4.266V15.6h.06a4.683,4.683,0,0,1,4.212-2.31c4.5,0,5.337,2.967,5.337,6.824Z"
                        transform="translate(0 -2.25)"
                        fill="#1aaa9d"
                      />
                    </svg>

                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="30"
                      viewBox="0 0 30 30"
                    >
                      <path
                        id="Subtraction_3"
                        data-name="Subtraction 3"
                        d="M26.864,30H3.138A3.142,3.142,0,0,1,0,26.862V3.138A3.142,3.142,0,0,1,3.138,0H26.864A3.141,3.141,0,0,1,30,3.138V26.862A3.141,3.141,0,0,1,26.864,30ZM16.07,17.417v1.714h3.608a4.51,4.51,0,0,0,0-9.021H16.07v1.714h3.608a2.8,2.8,0,0,1,0,5.593ZM10.656,10.11a4.51,4.51,0,0,0,0,9.021h3.61V17.417h-3.61a2.8,2.8,0,0,1,0-5.593h3.61V10.11Zm.9,3.608v1.8h7.217v-1.8Z"
                        fill="#9f9f9f"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="bottom-sector" v-if="!isMobile">
            <div class="thanks-container__modal-counter" v-if="false">
              <swiper
                ref="usefulToolsSlider"
                class="swp"
                :options="swiperOptions"
              >
                <swiper-slide>
                  <div class="modal-counter__item"  @click="sendApplause">
                    <i>
                      <ClapAnimation v-if="anim"/>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="26.517"
                        height="28"
                        viewBox="0 0 26.517 28">
                        <g id="clapping" transform="translate(-23.844 -9.31)">
                          <path
                            id="Path_1"
                            data-name="Path 1"
                            d="M123.288,46.4V43.347a2.887,2.887,0,0,0-5.774,0V43.8l-4.43-4.43a2.853,2.853,0,0,0-4.631.869,2.887,2.887,0,0,0-4.308,2.35,2.886,2.886,0,0,0-2.722,2.722,2.887,2.887,0,0,0-1.881,4.924l.8.8a2.886,2.886,0,0,0-.8,4.647l9.073,9.073a6.1,6.1,0,0,0,8.62,0l6.945-6.945a3.5,3.5,0,0,0,1.034-2.5V49.122a2.892,2.892,0,0,0-1.925-2.721Zm-12.881-5.668a.931.931,0,0,1,1.316,0l6.073,6.073a.962.962,0,0,0,1.643-.681V43.347a.962.962,0,0,1,1.925,0V46.4h0a2.892,2.892,0,0,0-1.925,2.722V51.08l-9.032-9.032a.932.932,0,0,1,0-1.316Zm-4.06,1.338a.963.963,0,0,1,1.361,0l.8.8a2.878,2.878,0,0,0-1.361,1.361l-.8-.8a.963.963,0,0,1,0-1.361Zm16.941,13.244a1.594,1.594,0,0,1-.47,1.134l-6.945,6.945a4.175,4.175,0,0,1-5.9,0l-4.99-4.991L100.9,54.32a.962.962,0,0,1,1.361-1.361l4.083,4.083a.962.962,0,1,0,1.361-1.361L100.9,48.876a.962.962,0,1,1,1.361-1.361l6.805,6.8a.948.948,0,0,0,.073.066.963.963,0,0,0,1.215,0,.948.948,0,0,0,.073-.066.962.962,0,0,0,0-1.361l-6.8-6.805a.962.962,0,0,1,1.361-1.361l2.722,2.722L111.79,51.6a.962.962,0,0,0,1.361-1.361l-4.083-4.083a.962.962,0,1,1,1.361-1.361l4.083,4.083,5.208,5.208a.962.962,0,0,0,1.643-.68V49.122a.962.962,0,1,1,1.925,0Zm0,0"
                            transform="translate(-74.851 -29.227)"
                            fill="#1aaa9d"
                          />
                        </g>
                      </svg>
                    </i>
                    <div class="ttl upper-1">{{applause}}</div>
                    <div class="descr">{{$fn.tr('დაკრული ტაში')}}</div>
                  </div>
                </swiper-slide>
                <swiper-slide>
                  <div class="modal-counter__item" @click="showFormAction('sms')">
                    <i>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="22.584"
                        height="28"
                        viewBox="0 0 22.584 28"
                      >
                        <g
                          id="smartphone_1_"
                          data-name="smartphone (1)"
                          transform="translate(-49.514)"
                        >
                          <path
                            id="Path_2"
                            data-name="Path 2"
                            d="M71.121,6.283A3.347,3.347,0,0,0,66.9,5.866V2.461A2.464,2.464,0,0,0,64.444,0H51.975a2.464,2.464,0,0,0-2.461,2.461V25.539A2.464,2.464,0,0,0,51.975,28H64.444A2.464,2.464,0,0,0,66.9,25.539V15.226l4.217-4.217A3.345,3.345,0,0,0,71.121,6.283ZM51.975,1.641h5.414v.984a.82.82,0,0,0,1.641,0V1.641h5.414a.821.821,0,0,1,.82.82V5.866a3.341,3.341,0,0,0-4.217,5.143l4.217,4.217V22.8H51.155V2.461A.821.821,0,0,1,51.975,1.641Zm13.289,23.9a.821.821,0,0,1-.82.82H51.975a.821.821,0,0,1-.82-.82V24.445H65.264Zm4.7-15.69-3.877,3.877L62.207,9.849a1.7,1.7,0,0,1,2.405-2.405l.891.891a.821.821,0,0,0,.991.129c.135-.078.123-.083,1.06-1.02a1.7,1.7,0,0,1,2.405,2.405Z"
                            fill="#1aaa9d"
                          />
                        </g>
                      </svg>
                    </i>
                    <div class="ttl upper-1">{{sendEmail}}</div>
                    <div class="descr">{{$fn.tr('მიწერილი მადლობა')}}</div>
                  </div>
                </swiper-slide>
                <swiper-slide>
                  <div class="modal-counter__item" @click="showFormAction('email')">
                    <i>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="22.553"
                        height="28"
                        viewBox="0 0 22.553 28"
                      >
                        <g id="love-letter" transform="translate(-49.801)">
                          <path
                            id="Path_3"
                            data-name="Path 3"
                            d="M71.534,21.2H69.4V.82a.82.82,0,0,0-.82-.82H50.621a.82.82,0,0,0-.82.82V24.23A3.775,3.775,0,0,0,53.571,28H68.584a3.775,3.775,0,0,0,3.77-3.77V22.021A.82.82,0,0,0,71.534,21.2Zm-15.833.82V24.23a2.13,2.13,0,1,1-4.259,0V1.641H67.763V21.2H56.521A.82.82,0,0,0,55.7,22.021ZM70.713,24.23a2.132,2.132,0,0,1-2.13,2.13h-11.9a3.749,3.749,0,0,0,.661-2.13V22.842H70.713Z"
                            transform="translate(0)"
                            fill="#1aaa9d"
                          />
                          <path
                            id="Path_4"
                            data-name="Path 4"
                            d="M130.52,97.732H120.164a.82.82,0,0,0,0,1.641H130.52a.82.82,0,0,0,0-1.641Z"
                            transform="translate(-65.74 -92.387)"
                            fill="#1aaa9d"
                          />
                          <path
                            id="Path_5"
                            data-name="Path 5"
                            d="M152.641,179.526a2.948,2.948,0,0,0-1.537.427A2.973,2.973,0,0,0,146.6,182.5a2.916,2.916,0,0,0,.86,2.073l3.07,3.073a.82.82,0,0,0,1.164,0l0,0,3.071-3.074a2.91,2.91,0,0,0,.847-2.073A2.976,2.976,0,0,0,152.641,179.526Zm.963,3.887-2.493,2.5-2.493-2.5a1.269,1.269,0,0,1-.381-.914,1.333,1.333,0,0,1,1.331-1.333,1.319,1.319,0,0,1,.95.4.82.82,0,0,0,1.173,0,1.319,1.319,0,0,1,.95-.4,1.333,1.333,0,0,1,1.331,1.333A1.247,1.247,0,0,1,153.6,183.413Z"
                            transform="translate(-91.502 -169.708)"
                            fill="#1aaa9d"
                          />
                        </g>
                      </svg>
                    </i>
                    <div class="ttl upper-1">{{sendSms}}</div>
                    <div class="descr">{{$fn.tr('მიწერილი მადლობა')}}</div>
                  </div>
                </swiper-slide>
                <div class="swiper-scrollbar" slot="scrollbar">
                  <div
                    class="swiper-scrollbar-drag"
                    style="width: 100px;"
                    slot="scrollbar"
                  ></div>
                </div>
              </swiper>
            </div>
            <div v-else class="thanks-container__modal-counter">
              <div class="modal-counter__item" @click="sendApplause">
                <div class="thanks-container__doctor-btn">
                  <div class="svgContainer">
                    <ClapAnimation v-if="anim"/>
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="30.765" height="28" viewBox="0 0 30.765 28">
                      <defs>
                        <clipPath id="clip-path">
                          <rect id="Rectangle_363" data-name="Rectangle 363" width="30.765" height="28" transform="translate(-8258 -10583)" fill="red"/>
                        </clipPath>
                      </defs>
                      <g id="Mask_Group_14" data-name="Mask Group 14" transform="translate(8258 10583)" clip-path="url(#clip-path)">
                        <g id="Group_4816" data-name="Group 4816" transform="translate(-8263.279 -10592.398)">
                          <g id="open-hand" transform="translate(0 23.46) rotate(-60)">
                            <path id="Path_243" data-name="Path 243" d="M2.139,5.7A2.138,2.138,0,0,1,4.278,7.841V4.989a2.139,2.139,0,1,1,4.277,0V2.139a2.139,2.139,0,0,1,4.277,0V4.989a2.139,2.139,0,1,1,4.277,0V19.957l4.95-2.475a3.565,3.565,0,0,1,1.594-.376,2.01,2.01,0,0,1,2.01,2.01v.012a2.01,2.01,0,0,1-.588,1.426l-9.22,9.218a10.322,10.322,0,0,1-7.3,3.02h0A8.554,8.554,0,0,1,0,24.235V7.841A2.138,2.138,0,0,1,2.139,5.7Z" transform="translate(0.713 0.713)" fill="#fff"/>
                            <path id="Path_247" data-name="Path 247" d="M9.268,34.216a10.96,10.96,0,0,0,7.8-3.232l9.218-9.218a2.729,2.729,0,0,0-1.925-4.658,4.3,4.3,0,0,0-1.912.449l-3.919,1.96V5.7a2.852,2.852,0,0,0-4.277-2.469V2.852a2.852,2.852,0,1,0-5.7,0v.384A2.851,2.851,0,0,0,4.277,5.7v.383A2.851,2.851,0,0,0,0,8.556V24.95a9.278,9.278,0,0,0,9.268,9.267Zm15.1-15.682a1.3,1.3,0,0,1,.918,2.224l-9.22,9.219a9.543,9.543,0,0,1-6.8,2.814A7.851,7.851,0,0,1,1.427,24.95V8.555a1.426,1.426,0,1,1,2.851,0v6.414a.713.713,0,1,0,1.426,0V5.7a1.426,1.426,0,0,1,2.851,0v9.264a.713.713,0,1,0,1.426,0V2.852a1.426,1.426,0,0,1,2.851,0V14.969a.713.713,0,1,0,1.426,0V5.7a1.426,1.426,0,0,1,2.851,0V20.672a.713.713,0,0,0,1.032.637L23.1,18.835a2.863,2.863,0,0,1,1.27-.3Z" transform="translate(0 0)" fill="#1aaa9d"/>
                          </g>
                        </g>
                      </g>
                    </svg>
                    <div class="clapsInner">
                      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="30.765" height="28" viewBox="0 0 30.765 28">
                        <defs>
                          <clipPath id="clip-path">
                            <rect id="Rectangle_363" data-name="Rectangle 363" width="30.765" height="28" transform="translate(-8258 -10583)" fill="red"/>
                          </clipPath>
                        </defs>
                        <g id="Mask_Group_14" data-name="Mask Group 14" transform="translate(8258 10583)" clip-path="url(#clip-path)">
                          <g id="Group_4816" data-name="Group 4816" transform="translate(-8263.279 -10592.398)">
                            <g id="open-hand" transform="translate(0 23.46) rotate(-60)">
                              <path id="Path_243" data-name="Path 243" d="M2.139,5.7A2.138,2.138,0,0,1,4.278,7.841V4.989a2.139,2.139,0,1,1,4.277,0V2.139a2.139,2.139,0,0,1,4.277,0V4.989a2.139,2.139,0,1,1,4.277,0V19.957l4.95-2.475a3.565,3.565,0,0,1,1.594-.376,2.01,2.01,0,0,1,2.01,2.01v.012a2.01,2.01,0,0,1-.588,1.426l-9.22,9.218a10.322,10.322,0,0,1-7.3,3.02h0A8.554,8.554,0,0,1,0,24.235V7.841A2.138,2.138,0,0,1,2.139,5.7Z" transform="translate(0.713 0.713)" fill="#fff"/>
                              <path id="Path_247" data-name="Path 247" d="M9.268,34.216a10.96,10.96,0,0,0,7.8-3.232l9.218-9.218a2.729,2.729,0,0,0-1.925-4.658,4.3,4.3,0,0,0-1.912.449l-3.919,1.96V5.7a2.852,2.852,0,0,0-4.277-2.469V2.852a2.852,2.852,0,1,0-5.7,0v.384A2.851,2.851,0,0,0,4.277,5.7v.383A2.851,2.851,0,0,0,0,8.556V24.95a9.278,9.278,0,0,0,9.268,9.267Zm15.1-15.682a1.3,1.3,0,0,1,.918,2.224l-9.22,9.219a9.543,9.543,0,0,1-6.8,2.814A7.851,7.851,0,0,1,1.427,24.95V8.555a1.426,1.426,0,1,1,2.851,0v6.414a.713.713,0,1,0,1.426,0V5.7a1.426,1.426,0,0,1,2.851,0v9.264a.713.713,0,1,0,1.426,0V2.852a1.426,1.426,0,0,1,2.851,0V14.969a.713.713,0,1,0,1.426,0V5.7a1.426,1.426,0,0,1,2.851,0V20.672a.713.713,0,0,0,1.032.637L23.1,18.835a2.863,2.863,0,0,1,1.27-.3Z" transform="translate(0 0)" fill="#1aaa9d"/>
                            </g>
                          </g>
                        </g>
                      </svg>
                    </div>
                  </div>
                  <div class="thanks-container__doctor-btn-stat upper-1">
                    {{applause}}
                  </div>
<!--                  <div class="tooltip">-->
<!--                    <div>{{$fn.tr('დაუკარი ტაში')}}</div>-->
<!--                    <span><i></i></span>-->
<!--                  </div>-->
                </div>
                <div class="descr">{{$fn.tr('დაუკარი ტაში')}}</div>
              </div>
              <div class="modal-counter__item" @click="showFormAction('sms')">
                <div class="thanks-container__doctor-btn">
                  <div class="svgContainer">
                    <svg xmlns="http://www.w3.org/2000/svg" width="18.306" height="28.5" viewBox="0 0 18.306 28.5">
                      <g id="smartphone_2_" data-name="smartphone (2)" transform="translate(-62.95 0.25)">
                        <path id="Path_243" data-name="Path 243" d="M83.555,6.4H71.684A2.485,2.485,0,0,0,69.2,8.884V30.948a2.485,2.485,0,0,0,2.484,2.484H83.555a2.485,2.485,0,0,0,2.484-2.484V8.852A2.5,2.5,0,0,0,83.555,6.4Z" transform="translate(-5.516 -5.884)" fill="#fff"/>
                        <g id="Group_4816" data-name="Group 4816" transform="translate(63.2)">
                          <path id="Path_246" data-name="Path 246" d="M78.039,0H66.168A2.96,2.96,0,0,0,63.2,2.968V25.032A2.96,2.96,0,0,0,66.168,28H78.039a2.96,2.96,0,0,0,2.968-2.968V2.968A2.981,2.981,0,0,0,78.039,0ZM64.2,4.935H80.006V21.774H64.2ZM66.168,1H78.039a1.955,1.955,0,0,1,1.968,1.968v1H64.2v-1A1.955,1.955,0,0,1,66.168,1ZM78.039,27H66.168A1.955,1.955,0,0,1,64.2,25.032V22.774H80.006v2.258A1.955,1.955,0,0,1,78.039,27Z" transform="translate(-63.2)" fill="#1aaa9d" stroke="#1aaa9d" stroke-width="0.5"/>
                        </g>
                      </g>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" width="11.238" height="10.883" viewBox="0 0 11.238 10.883" class="hearth">
                      <g id="Group_4816" data-name="Group 4816" transform="translate(8077.25 10853.25)">
                        <path id="Icon_ionic-ios-heart" data-name="Icon ionic-ios-heart" d="M10.812,3.938h-.024A2.784,2.784,0,0,0,8.464,5.21,2.784,2.784,0,0,0,6.139,3.938H6.115A2.766,2.766,0,0,0,3.375,6.7,5.955,5.955,0,0,0,4.544,9.948a20.493,20.493,0,0,0,3.919,3.775,20.493,20.493,0,0,0,3.919-3.775A5.955,5.955,0,0,0,13.552,6.7,2.766,2.766,0,0,0,10.812,3.938Z" transform="translate(-8080.095 -10856.668)" fill="#fff"/>
                        <path id="Icon_ionic-ios-heart-empty" data-name="Icon ionic-ios-heart-empty" d="M11.222,3.938H11.2A2.937,2.937,0,0,0,8.744,5.28,2.937,2.937,0,0,0,6.292,3.938H6.266A2.918,2.918,0,0,0,3.375,6.854,6.283,6.283,0,0,0,4.609,10.28a21.622,21.622,0,0,0,4.135,3.983,21.622,21.622,0,0,0,4.135-3.983,6.283,6.283,0,0,0,1.234-3.425A2.918,2.918,0,0,0,11.222,3.938ZM12.3,9.854a19.8,19.8,0,0,1-3.552,3.5,19.827,19.827,0,0,1-3.552-3.5,5.569,5.569,0,0,1-1.094-3A2.19,2.19,0,0,1,6.271,4.663h.023a2.164,2.164,0,0,1,1.061.279,2.255,2.255,0,0,1,.785.736.725.725,0,0,0,1.213,0,2.278,2.278,0,0,1,.785-.736A2.164,2.164,0,0,1,11.2,4.663h.023A2.19,2.19,0,0,1,13.4,6.854,5.639,5.639,0,0,1,12.3,9.854Z" transform="translate(-8080.375 -10856.938)" fill="#1aaa9d" stroke="#1aaa9d" stroke-width="0.5"/>
                      </g>
                    </svg>
                  </div>
                  <div class="thanks-container__doctor-btn-stat upper-1">
                    {{sendSms}}
                  </div>
<!--                  <div class="tooltip">-->
<!--                    <div>{{$fn.tr('გაგზავნე სმს')}}</div>-->
<!--                    <span><i></i></span>-->
<!--                  </div>-->
                </div>
                <div class="descr">{{$fn.tr('გაგზავნე სმს')}}</div>
              </div>
              <div class="modal-counter__item" @click="showFormAction('email')">
                <div class="thanks-container__doctor-btn">
                  <div class="svgContainer smsSvg">
                    <svg xmlns="http://www.w3.org/2000/svg" width="23.251" height="28.686" viewBox="0 0 23.251 28.686">
                      <g id="Group_4817" data-name="Group 4817" transform="translate(8032.25 10875.936)">
                        <rect id="Rectangle_363" data-name="Rectangle 363" width="21.52" height="26.811" transform="translate(-8031.227 -10875.936)" fill="#fff"/>
                        <g id="to-do-list" transform="translate(-8032 -10875.5)">
                          <g id="Group_4824" data-name="Group 4824" transform="translate(0 0)">
                            <path id="Path_254" data-name="Path 254" d="M21.165,20.5H1.586A1.588,1.588,0,0,0,0,22.086V46.914A1.588,1.588,0,0,0,1.586,48.5H21.165a1.588,1.588,0,0,0,1.586-1.586V22.086A1.588,1.588,0,0,0,21.165,20.5Zm.4,26.414a.4.4,0,0,1-.4.4H1.586a.4.4,0,0,1-.4-.4V22.086a.4.4,0,0,1,.4-.4H21.165a.4.4,0,0,1,.4.4Z" transform="translate(0 -20.5)" fill="#1aaa9d" stroke="#1aaa9d" stroke-width="0.5"/>
                            <path class="smsAnim" id="Path_255" data-name="Path 255" d="M92.842,89.7c0-.578-.266-1.047-.594-1.047h-12.1c-.328,0-.594.469-.594,1.047s.266,1.047.594,1.047h12.1C92.576,90.742,92.842,90.274,92.842,89.7Z" transform="translate(-74.82 -84.598)" fill="#1aaa9d"/>
                            <path class="smsAnim" id="Path_257" data-name="Path 257" d="M92.842,89.7c0-.578-.266-1.047-.594-1.047h-12.1c-.328,0-.594.469-.594,1.047s.266,1.047.594,1.047h12.1C92.576,90.742,92.842,90.274,92.842,89.7Z" transform="translate(-74.82 -72.598)" fill="#1aaa9d"/>
                            <path class="smsAnim" id="Path_256" data-name="Path 256" d="M87.1,89.7c0-.578-.151-1.047-.338-1.047H79.887c-.187,0-.338.469-.338,1.047s.151,1.047.338,1.047h6.877C86.95,90.742,87.1,90.274,87.1,89.7Z" transform="translate(-74.82 -78.598)" fill="#1aaa9d"/>
                          </g>
                        </g>
                      </g>
                    </svg>
                  </div>
                  <div class="thanks-container__doctor-btn-stat upper-1">
                    {{sendEmail}}
                  </div>
<!--                  <div class="tooltip">-->
<!--                    <div>{{$fn.tr('გაგზავნე ემაილი')}}</div>-->
<!--                    <span><i></i></span>-->
<!--                  </div>-->
                </div>
                <div class="descr">{{$fn.tr('გაგზავნე ემაილი')}}</div>
              </div>
            </div>
          </div>
        </div>

        <div class="other-messages padding-center" v-if="!isSocial">
          <div class="max-width-container">
            <div class="message-title noto-bold">
              {{$fn.tr('სხვისი წერილები')}}
            </div>
            <div class="message-item">
              <div class="message-owner noto-bold"></div>
              <div class="message-item-content noto-regular">
              </div>
            </div>
<!--             <div class="message-item">
              <div class="message-owner noto-bold"></div>
              <div class="message-item-content noto-regular">
              </div>
            </div> -->
          </div>
        </div>

        <div class="social-section" v-if="isSocial">
          <div class="doctor-action-buttons__share">
            <div class="share-title noto-bold" :title="shareFullUrl">
              {{$fn.tr('გააზიარე ისტორია')}}
            </div>
            <div class="share-buttons">
              <ShareNetwork
                  network="facebook"
                  :url="shareFullUrl"
                  :title="_.get(infoData,'title')"
                  :description="_.get(infoData,'teaser')"
                  :quote="_.get(infoData,'title')"
                  hashtags="">
                 <svg
                    id="Group_4725"
                    data-name="Group 4725"
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30.002"
                    viewBox="0 0 30 30.002">
                    <path
                      id="Subtraction_1"
                      data-name="Subtraction 1"
                      d="M15.258,30h0L3.137,30A3.141,3.141,0,0,1,0,26.863V3.137A3.141,3.141,0,0,1,3.137,0H26.863A3.141,3.141,0,0,1,30,3.137V26.863A3.141,3.141,0,0,1,26.863,30H20.188V19.34h3.677l.7-4.559H20.188V11.82a2.274,2.274,0,0,1,2.571-2.464h1.991V5.473a24.268,24.268,0,0,0-3.532-.308c-3.731,0-5.958,2.3-5.958,6.139V14.78H11.252V19.34h4.006V30Z"
                      fill="#1aaa9d"
                    />
                  </svg>
              </ShareNetwork>

              <ShareNetwork
                  network="Twitter"
                  :url="shareFullUrl"
                  :title="_.get(infoData,'title')"
                  :description="_.get(infoData,'teaser')"
                  :quote="_.get(infoData,'title')"
                  hashtags="">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  viewBox="0 0 30 30">
                  <path
                    id="Subtraction_2"
                    data-name="Subtraction 2"
                    d="M26.864,30H3.138A3.142,3.142,0,0,1,0,26.862V3.138A3.142,3.142,0,0,1,3.138,0H26.864A3.141,3.141,0,0,1,30,3.138V26.862A3.141,3.141,0,0,1,26.864,30ZM4.419,21.172v0a12.528,12.528,0,0,0,6.79,1.983A12.46,12.46,0,0,0,23.79,10.575c0-.188,0-.383-.013-.576a9.444,9.444,0,0,0,2.217-2.286,8.806,8.806,0,0,1-2.546.684A4.4,4.4,0,0,0,25.392,5.96a8.778,8.778,0,0,1-2.806,1.069,4.426,4.426,0,0,0-7.654,3.025,4.925,4.925,0,0,0,.11,1.013A12.6,12.6,0,0,1,5.922,6.44a4.37,4.37,0,0,0-.6,2.232,4.416,4.416,0,0,0,1.972,3.682,4.493,4.493,0,0,1-2-.561v.054a4.442,4.442,0,0,0,3.546,4.34,4.711,4.711,0,0,1-1.163.15,5.539,5.539,0,0,1-.835-.067,4.432,4.432,0,0,0,4.135,3.067,8.817,8.817,0,0,1-5.49,1.888A9.036,9.036,0,0,1,4.419,21.172Z"
                    fill="#1aaa9d"
                  />
                </svg>
              </ShareNetwork>

              <ShareNetwork
                  network="LinkedIn"
                  :url="shareFullUrl"
                  :title="_.get(infoData,'title')"
                  :description="_.get(infoData,'teaser')"
                  :quote="_.get(infoData,'title')"
                  hashtags="">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  viewBox="0 0 30 30">
                  <path
                    id="Icon_awesome-linkedin"
                    data-name="Icon awesome-linkedin"
                    d="M27.857,2.25H2.136A2.152,2.152,0,0,0,0,4.413V30.087A2.152,2.152,0,0,0,2.136,32.25H27.857A2.158,2.158,0,0,0,30,30.087V4.413A2.158,2.158,0,0,0,27.857,2.25ZM9.067,27.964H4.621V13.647H9.074V27.964ZM6.844,11.692A2.578,2.578,0,1,1,9.422,9.114,2.579,2.579,0,0,1,6.844,11.692ZM25.734,27.964H21.288V21c0-1.661-.033-3.8-2.31-3.8-2.317,0-2.672,1.808-2.672,3.676v7.085H11.859V13.647h4.266V15.6h.06a4.683,4.683,0,0,1,4.212-2.31c4.5,0,5.337,2.967,5.337,6.824Z"
                    transform="translate(0 -2.25)"
                    fill="#1aaa9d"
                  />
                </svg>
              </ShareNetwork>

              <span @click="copyUrl">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  viewBox="0 0 30 30">
                  <path
                    id="Subtraction_3"
                    data-name="Subtraction 3"
                    d="M26.864,30H3.138A3.142,3.142,0,0,1,0,26.862V3.138A3.142,3.142,0,0,1,3.138,0H26.864A3.141,3.141,0,0,1,30,3.138V26.862A3.141,3.141,0,0,1,26.864,30ZM16.07,17.417v1.714h3.608a4.51,4.51,0,0,0,0-9.021H16.07v1.714h3.608a2.8,2.8,0,0,1,0,5.593ZM10.656,10.11a4.51,4.51,0,0,0,0,9.021h3.61V17.417h-3.61a2.8,2.8,0,0,1,0-5.593h3.61V10.11Zm.9,3.608v1.8h7.217v-1.8Z"
                    fill="#9f9f9f"
                  />
                </svg>
                <input type="hidden" id="copy-url" :value="fullUrl">
              </span>
            </div>
          </div>
        </div>
      </div>
      <div @click="closePopup" class="dark-bakcground" />
    </div>
  </portal>
</template>
<script>
import ClapAnimation from "../components/ClapAnimation";
import SwiperCore, { Scrollbar } from "swiper";
import Breakpoints from "../mixins/Breakpoints";
SwiperCore.use([Scrollbar]);
export default {
  name: "popup",
  components: {
    ClapAnimation
  },
  props: {
    showMore: {
      type: Boolean,
      default: false
    },
    isForm: {
      type: Boolean,
      default: false
    },
    isSocial: {
      type: Boolean,
      default: false
    },
    infoData: {
      type: [Object,Array],
    }
  },
  mixins: [Breakpoints],
  data() {
    return {

      applause: _.get(this.infoData, 'applause'),
      sendSms :  _.get(this.infoData, 'sms_thanks'),
      sendEmail :  _.get(this.infoData, 'sent_thanks'),
      fullUrl: '',
      anim: false,
      name: "",
      message: "",
      thankYou: false,
      showPopup: false,
      nameError: false,
      messageError: false,
      showMyName: false,
      isClick: false,
      max: 300,
      nameMax: 50,
      swiperOptions: {
        speed: 800,
        watchOverflow: true,
        breakpoints: {
          320: {
            spaceBetween: 10,
            slidesPerView: 2
          }
        },
        scrollbar: {
          el: ".swiper-scrollbar",
          hide: false,
          draggable: true,
          dragSize: 70
        }
      }
    };
  },
  computed: {
    shareFullUrl(){
      return this.$cf.host+this.$route.path;
    },
    usefulToolsSlider() {
      return this.$refs.usefulToolsSlider.$swiper;
    }
  },
  methods: {
    closePopup() {
      this.thankYou = false;
      this.$emit("closePopup");
      this.name = '';
      this.message = '';
      this.isClick = false;
      this.nameError = false;
      this.messageError = false;
    },
    async sendForm() {
      this.isClick = true
      this.name.length <= 0 ?  this.nameError = true : this.nameError = false;
      this.message.length <= 0 ?  this.messageError = true : this.messageError = false;
      let data = {};
      data.name = this.name;
      data.message = this.message;
      data.showMyName = this.showMyName;
      data.id = this.infoData.id;

      let sendTo = this.$store.state.main.showFormPopup.to;
      if (!this.messageError && !this.nameError){
        if(sendTo === 'email'){
         let tmp = await this.$fn.postData('main/sendemail', data);
          console.log(tmp.data,"looog email")
          if(tmp.status == 200){
            this.sendEmail = tmp.data;
            console.log(this.sendEmail,"this.sendEmail email")
          }
        }else{
          let tmp = await this.$fn.postData('main/sendsms', data);
          console.log(tmp.data,"looog")
          if(tmp.status == 200){
            this.sendSms = tmp.data
            console.log(this.sendSms,"this.sendSms")
          }
        }
        this.$store.dispatch('main/getFeedbackCounts')
        console.log(this.sendSms,"this.sendSms");
        console.log( this.sendEmail,"looog  this.sendEmail")

        this.thankYou = true;
      }
    },
     showFormAction(to) {
      this.$store.commit('main/setState', {key: 'showMorePopup', val: false});
      this.$store.commit('main/setState', {key: 'showSocialPopup', val: false});
      this.$store.commit('main/setState', {key: 'popupDoctorInfo', val: this.infoData});
      this.$store.commit('main/setState', {key: 'showFormPopup', val: {state: true, to: to}});
    },
    async sendApplause(){
      let tmp = await this.$fn.postData('main/applause', {id: this.infoData.id });
      this.$store.dispatch('main/getFeedbackCounts')
      if(tmp.status == 200){
        this.applause = tmp.data
        this.anim = true
        setTimeout(() => this.anim = false, 1600);
      }else{

      }
    },
    copyUrl(){
        let testingCodeToCopy = document.querySelector('#copy-url')
          testingCodeToCopy.setAttribute('type', 'text')
          testingCodeToCopy.select()

          try {
            var successful = document.execCommand('copy');
          } catch (err) {
          }

          /* unselect the range */
          testingCodeToCopy.setAttribute('type', 'hidden')
          window.getSelection().removeAllRanges()
    }

  },
  watch: {
    name(val) {
      val.length <= 0 && this.isClick ? this.nameError = true : this.nameError = false;

    },
    message(val) {
      val.length <= 0 && this.isClick ? this.messageError = true : this.messageError = false;

    },
    thankYou(val) {
      // if (val) this.$emit("closePopup");
    },
    infoData: {
      immediate: true,
      deep: true,
      handler: function(){
        this.applause=  _.get(this.infoData, 'applause');
        this.sendSms=  _.get(this.infoData, 'sms_thanks');
        this.sendEmail=  _.get(this.infoData, 'sent_thanks');
      }
    },
    $route:{
      immediate: true,
      deep: true,
      handler: function(){
        this.fullUrl = this.$route.fullPath;
      }
    }
  }
};
</script>

<style scoped>
/*.swiper-container {*/
/*  margin-left: unset;*/
/*  margin-right: unset;*/
/*}*/
.swiper-scrollbar {
  margin-top: 20px;
  background-color: #f7f8f8;
  height: 2px !important;
}
.swiper-scrollbar-drag {
  top: -1px;
  background-color: #1aaa9d;
  height: 3px;
}
.popup {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  visibility: hidden;
  transition: 0.4s ease-in;
}
.dark-bakcground {
  opacity: 0;
  visibility: hidden;
  transition: 0.4s ease-in;
}
.show.popup {
  opacity: 1;
  visibility: visible;
  transition: 0.4s ease-in;
}
.show .dark-bakcground {
  opacity: 0.7;
  visibility: visible;
  transition: 0.4s ease-in;
}

.cross svg {
  transition: 0.3s ease;
}

.cross:hover svg path {
  fill: #1a9a8e;
  transition: 0.3s ease;
}

.dark-bakcground {
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  background-color: #000000;
  opacity: 0.7;
  z-index: 9;
}

.padding-center {
  padding: 0 15%;
}

.label.error {
  font-size: 12px;
  color: #ff3b08;
  padding-left: 5px;
}

.popup-wrapper {
  width: 80vw;
  margin: auto;
  display: flex;
  flex-direction: column;
  height: 90vh;
  overflow: auto;
  background-color: #ffffff;
  z-index: 10;
  position: relative;
}

.popup.small .popup-wrapper{
  width: auto;
  height: auto;
  padding: 40px 70px;
}

.popup-wrapper .cross {
  position: absolute;
  right: 25px;
  top: 25px;
  cursor: pointer;
}

.main-content {
  padding-left: 0;
  padding-right: 0;
  padding-top: 25px;
  height: 620px;
  min-height: 620px;
  max-height: 620px;
  width: 100%;
}

.max-width-container {
  max-width: 840px;
  margin: 0 auto auto;
}

.other-messages {
  background-color: #f5f7f8;
  width: 100%;
  padding-top: 20px;
}

.message-title {
  color: #000000;
  font-size: 16px;
  padding-bottom: 20px;
  border-bottom: 2px solid #d9d9d9;
}

.message-item {
  padding: 24px 0;
  border-bottom: 2px solid #d9d9d9;
}

.message-item:last-child {
  border: unset;
}

.message-owner {
  padding-bottom: 10px;
  color: #000000;
}

.message-owner,
.message-item-content {
  font-size: 14px;
}

/*  doctor*/
.top-sector {
  display: flex;
  padding-bottom: 30px;
  border-bottom: 2px solid #f7f8f8;
}

.bottom-sector {
  padding-top: 40px;
}

.doctor-info {
  padding-left: 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.doctor-action-buttons {
  padding-top: 30px;
  border-top: 2px solid #f7f8f8;
}

.doctor-info-title {
  color: #000000;
  font-size: 16px;
  padding-bottom: 30px;
  border-bottom: 2px solid #f7f8f8;
}

.doctor-info-description {
  font-size: 14px;
  line-height: 17px;
  padding-top: 20px;
  color: #000000;
}

.doctor-image {
  width: 282px;
  min-width: 282px;
  height: 425px;
  position: relative;

}

.doctor-image img {
  width: 100%;
  border-radius: 10px;
  object-fit: cover;
  position: absolute;
  height: 100%;
}

.doctor-action-buttons {
  display: flex;
  justify-content: space-between;
}

.doctor-action-buttons .test {
  width: 100px;
}

.share-title {
  margin-bottom: 6px;
}

.share-buttons svg {
  margin-right: 10px;
  cursor: pointer;
  transition: 0.3s ease;
}

.share-buttons svg:hover path {
  fill: #1a9a8e;
  transition: 0.3s ease;
}

/*  form */
.send-message-form {
  display: flex;
  flex-direction: column;
}

input[type='checkbox'] {
  margin-right: 5px;
}

.send-message-form-input {
  border: unset;
  border: 1px solid #aaaaaa;
  border-radius: 10px;
  padding: 0 20px;
  height: 46px;
  font-size: 14px;
  line-height: 17px;
}

textarea.send-message-form-input {
  height: 120px;
  margin-top: 20px;
  padding-top: 20px;
}

.input-info-texts {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
}

.input-info-texts span {
  font-size: 14px;
}

.send-message-form-title {
  color: #1aaa9d;
  font-size: 14px;
  margin-bottom: 15px;
}
.thanks-container.active .thanks-container__modal-counter {
  flex-direction: row;
}

.send-form {
  /*background-color: #0E4E8C;*/
  background: linear-gradient(to right, #0e4e8c, #005956);
  border-radius: 10px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: 16px;
  margin-top: 40px;
  transition: 0.2s ease;
}

.send-form:hover {
  background: linear-gradient(to right, #005956, #0e4e8c);
  transition: 0.2s ease;
}


.send-form svg {
  margin-right: 7px;
  transition: 0.2s ease;
}

.thank-you-svg {
  background-color: #f5f7f8;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  margin-right: 10px;
  height: 60px;
}
.doctor-info-thank-you {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.doctor-info-thank-you span {
  max-width: 300px;
  color: #1aaa9d;
  font-size: 14px;
}
.descr {
  text-align: center;
}

.thanks-container__doctor-dashboard {
  width: 230px;
}

@media screen and (max-width: 1359px) {
  .top-sector {
    flex-direction: column;
  }
  .doctor-image {
    margin: auto;
    margin-bottom: 10px;
  }

  .doctor-info {
    padding-right: 25px;
  }
  .bottom-sector,
  .other-messages.padding-center {
    padding-left: 25px;
    padding-right: 25px;
  }

  .main-content {
    height: unset;
    min-height: unset;
    max-height: unset;
  }
}
@media screen and (max-width: 767px) {
  .doctor-action-buttons {
    flex-direction: column;
    align-items: center;
  }
  .thanks-container__doctor-dashboard-inner {
    margin-bottom: 15px;
  }
  .doctor-info {
    padding-right: 15px;
  }
  .popup-wrapper .cross {
    top: 5px;
    right: 5px;
  }
  .popup-wrapper {
    width: 95vw;
    height: 95vh;
  }
  .bottom-sector,
  .other-messages.padding-center {
    padding-left: 15px;
    padding-right: 15px;
  }
}
</style>

<style>
.popup-wrapper .swiper-wrapper {
  height: 120px !important;
}
</style>
