<template>
  <transition name="fade" mode="out-in">

  <div v-if="_.size($store.state.main.indx.menus)>0">
<!--     v-show="$store.state.main.showSite"-->
<!--    <Header />-->

    <router-view />
  </div>
  </transition>
</template>

<script>
// import Header from './components/modules/Header.vue';
// import SideMenu from './components/modules/SideMenu.vue';
// import Footer from './components/modules/Footer.vue';
/**
 *     <meta property="og:url"           content="https://miceremadloba.ge/" />
 <meta property="og:type"          content="website" />
 <meta property="og:title"         content="madloba" />
 <meta property="og:description"   content="madloba" />
 <meta property="og:image"         content="https://miceremadloba.ge/path/image.jpg" />

 */
export default {
  components: {
    // Header,
    // SideMenu,
    // Footer,

  },

  metaInfo() {
    let titleData = _.get(this.$store.state.main.indx, 'siteSettings.website_title') ? _.get(this.$store.state.main.indx, 'siteSettings.website_title') : 'madloba ';
    let description  = _.get(this.$store.state.main.selected_menu, ['seo', this.$store.state.main.locale, 'description']) ? _.get(this.$store.state.main.selected_menu, ['seo', this.$store.state.main.locale, 'description']) : _.get(this.$store.state.main.indx, 'siteSettings.website_meta_description');

    // if no subcomponents specify a metaInfo.title, this title will be used
    return {
      title: _.get(this.$store.state.main.selected_menu, ['seo', this.$store.state.main.locale, 'title']) ? _.get(this.$store.state.main.selected_menu, ['seo', this.$store.state.main.locale, 'title']) : _.get(this.$store.state.main.selected_menu, 'title'),
      // all titles will be injected into this template
      titleTemplate: '%s | ' + titleData,
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: _.get(this.$store.state.main.selected_menu, ['seo', this.$store.state.main.locale, 'description']) ? _.get(this.$store.state.main.selected_menu, ['seo', this.$store.state.main.locale, 'description']) : _.get(this.$store.state.main.indx, 'siteSettings.website_meta_description')
        },
        {
          vmid: 'keyword',
          name: 'keyword',
          content: _.get(this.$store.state.main.selected_menu, ['seo', this.$store.state.main.locale, 'keywords']) ? _.get(this.$store.state.main.selected_menu, ['seo', this.$store.state.main.locale, 'keywords']) : _.get(this.$store.state.main.indx, 'siteSettings.website_meta_keywords')
        },

        {
          name: 'og:type',
          content: 'website'
        },
        {
          name: 'og:title',
          content: titleData
        },
        {
          name: 'og:title',
          content: description
        },

      ]

    }
  },


  mounted() {
    // if( this.$route.name.indexOf('PageViewRouter') >=0 ) this.$store.commit('main/setState', {key: 'showSite', val: true });
  },


  methods: {



  },

};
</script>
<style>
  .fade-enter-active, .fade-leave-active {
    transition: opacity .6s;
  }

  .fade-enter, .fade-leave-to
  {
    transition: opacity .4s;
    opacity: 0;
  }
</style>
