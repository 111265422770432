export const HEADER_STYLES = {
 	TRANSPARENT_HEADER: 'transparent_header',
 	LIGHT_HEADER: 'light_header',
 	LOGINED_HEADER: 'logined_header', 
 	CHECKOUT_HEADER: 'checkout_header'
}

export const HEADER_HEIGHT = {
    MOBILE: 60,
    DESKTOP: 70
}


export const LAYOUT = {
    NORMAL: 'normal',
    LONG: 'long'
}

export const CABINET = {
    PROFILE: 'CabinetProfile',
    ADDRESS: 'CabinetAddress',
    ORDER: 'CabinetOrder'
}


export const MODAL_TYPES = {
  NONE: null,
  LOGIN: "LoginForm",
  CART: "CartPopup",
  NEW_ADDRESS: "NewAddress",
  OUT_OF_STOCKS: "OutOfStocks",
  UNFORTUNATELY: "Unfortunately",
  CALL_ME_BACK: "CallMeBack",
  THANKS: "Thanks",
  FEEDBACK: "Feedback",
  QUICK_ORDER: "QuickOrder",
  THANKS2: "Thanks2",
  LEGAL_ENTITY: "LegalEntity",
  FAST_ORDER: "FastOrder"
};
