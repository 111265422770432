<template>
  <div class="thanks-container__doctor">
<!--    {{$store.state.main.indx}}-->
    <div class="thanks-container__doctor-inner">
      <div class="thanks-container__doctor-img-container">
        <div class="thanks-container__doctor-img">
          <img :src=" _.get(itemData,'images.0.devices.desktop')" alt="" />
          <div class="thanks-container__doctor-description" @click="showMoreAction">
            <div class="thanks-container__doctor-description-inner">
              <div class="ttl upper-1">{{_.get(itemData,'title')}}</div>
              <div class="descr">
                {{_.get(itemData,'teaser')}}
              </div>
              <div class="bottom__container">
                <div class="upper-1">{{$fn.tr('ვრცლად')}}</div>
                <a @click.prevent="showSocialAction" class="upper-1">
                  <svg xmlns="http://www.w3.org/2000/svg" width="18.486" height="16.86" viewBox="0 0 18.486 16.86">
                    <path id="share" d="M18.487,8.924,10.8.5V5.526H9.171A9.17,9.17,0,0,0,0,14.7V17.36l.726-.8a13.019,13.019,0,0,1,9.616-4.242H10.8v5.026Zm0,0" transform="translate(-0.001 -0.5)" fill="#1aaa9d"/>
                  </svg>

                  {{$fn.tr('გააზიარე')}}</a>
              </div>
            </div>
          </div>
        </div>
        <div class="thanks-container__doctor-dashboard">
          <div class="thanks-container__doctor-dashboard-inner">
            <div class="thanks-container__doctor-btn-outer" @click="sendApplause">
              <div class="thanks-container__doctor-btn">
                <div class="svgContainer">
                  <ClapAnimation v-if="anim"/>
                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="30.765" height="28" viewBox="0 0 30.765 28">
                    <defs>
                      <clipPath id="clip-path">
                        <rect id="Rectangle_363" data-name="Rectangle 363" width="30.765" height="28" transform="translate(-8258 -10583)" fill="red"/>
                      </clipPath>
                    </defs>
                    <g id="Mask_Group_14" data-name="Mask Group 14" transform="translate(8258 10583)" clip-path="url(#clip-path)">
                      <g id="Group_4816" data-name="Group 4816" transform="translate(-8263.279 -10592.398)">
                        <g id="open-hand" transform="translate(0 23.46) rotate(-60)">
                          <path id="Path_243" data-name="Path 243" d="M2.139,5.7A2.138,2.138,0,0,1,4.278,7.841V4.989a2.139,2.139,0,1,1,4.277,0V2.139a2.139,2.139,0,0,1,4.277,0V4.989a2.139,2.139,0,1,1,4.277,0V19.957l4.95-2.475a3.565,3.565,0,0,1,1.594-.376,2.01,2.01,0,0,1,2.01,2.01v.012a2.01,2.01,0,0,1-.588,1.426l-9.22,9.218a10.322,10.322,0,0,1-7.3,3.02h0A8.554,8.554,0,0,1,0,24.235V7.841A2.138,2.138,0,0,1,2.139,5.7Z" transform="translate(0.713 0.713)" fill="#fff"/>
                          <path id="Path_247" data-name="Path 247" d="M9.268,34.216a10.96,10.96,0,0,0,7.8-3.232l9.218-9.218a2.729,2.729,0,0,0-1.925-4.658,4.3,4.3,0,0,0-1.912.449l-3.919,1.96V5.7a2.852,2.852,0,0,0-4.277-2.469V2.852a2.852,2.852,0,1,0-5.7,0v.384A2.851,2.851,0,0,0,4.277,5.7v.383A2.851,2.851,0,0,0,0,8.556V24.95a9.278,9.278,0,0,0,9.268,9.267Zm15.1-15.682a1.3,1.3,0,0,1,.918,2.224l-9.22,9.219a9.543,9.543,0,0,1-6.8,2.814A7.851,7.851,0,0,1,1.427,24.95V8.555a1.426,1.426,0,1,1,2.851,0v6.414a.713.713,0,1,0,1.426,0V5.7a1.426,1.426,0,0,1,2.851,0v9.264a.713.713,0,1,0,1.426,0V2.852a1.426,1.426,0,0,1,2.851,0V14.969a.713.713,0,1,0,1.426,0V5.7a1.426,1.426,0,0,1,2.851,0V20.672a.713.713,0,0,0,1.032.637L23.1,18.835a2.863,2.863,0,0,1,1.27-.3Z" transform="translate(0 0)" fill="#1aaa9d"/>
                        </g>
                      </g>
                    </g>
                  </svg>
                  <div class="clapsInner">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="30.765" height="28" viewBox="0 0 30.765 28">
                      <defs>
                        <clipPath id="clip-path">
                          <rect id="Rectangle_363" data-name="Rectangle 363" width="30.765" height="28" transform="translate(-8258 -10583)" fill="red"/>
                        </clipPath>
                      </defs>
                      <g id="Mask_Group_14" data-name="Mask Group 14" transform="translate(8258 10583)" clip-path="url(#clip-path)">
                        <g id="Group_4816" data-name="Group 4816" transform="translate(-8263.279 -10592.398)">
                          <g id="open-hand" transform="translate(0 23.46) rotate(-60)">
                            <path id="Path_243" data-name="Path 243" d="M2.139,5.7A2.138,2.138,0,0,1,4.278,7.841V4.989a2.139,2.139,0,1,1,4.277,0V2.139a2.139,2.139,0,0,1,4.277,0V4.989a2.139,2.139,0,1,1,4.277,0V19.957l4.95-2.475a3.565,3.565,0,0,1,1.594-.376,2.01,2.01,0,0,1,2.01,2.01v.012a2.01,2.01,0,0,1-.588,1.426l-9.22,9.218a10.322,10.322,0,0,1-7.3,3.02h0A8.554,8.554,0,0,1,0,24.235V7.841A2.138,2.138,0,0,1,2.139,5.7Z" transform="translate(0.713 0.713)" fill="#fff"/>
                            <path id="Path_247" data-name="Path 247" d="M9.268,34.216a10.96,10.96,0,0,0,7.8-3.232l9.218-9.218a2.729,2.729,0,0,0-1.925-4.658,4.3,4.3,0,0,0-1.912.449l-3.919,1.96V5.7a2.852,2.852,0,0,0-4.277-2.469V2.852a2.852,2.852,0,1,0-5.7,0v.384A2.851,2.851,0,0,0,4.277,5.7v.383A2.851,2.851,0,0,0,0,8.556V24.95a9.278,9.278,0,0,0,9.268,9.267Zm15.1-15.682a1.3,1.3,0,0,1,.918,2.224l-9.22,9.219a9.543,9.543,0,0,1-6.8,2.814A7.851,7.851,0,0,1,1.427,24.95V8.555a1.426,1.426,0,1,1,2.851,0v6.414a.713.713,0,1,0,1.426,0V5.7a1.426,1.426,0,0,1,2.851,0v9.264a.713.713,0,1,0,1.426,0V2.852a1.426,1.426,0,0,1,2.851,0V14.969a.713.713,0,1,0,1.426,0V5.7a1.426,1.426,0,0,1,2.851,0V20.672a.713.713,0,0,0,1.032.637L23.1,18.835a2.863,2.863,0,0,1,1.27-.3Z" transform="translate(0 0)" fill="#1aaa9d"/>
                          </g>
                        </g>
                      </g>
                    </svg>
                  </div>
                </div>
                <div class="thanks-container__doctor-btn-stat upper-1">
                   {{applause}}
                </div>
                <div class="tooltip">
                  <div>{{$fn.tr('დაუკარი ტაში')}}</div>
                  <span><i></i></span>
                </div>
              </div>
            </div>
            <div class="thanks-container__doctor-btn-outer"  @click="showFormAction('sms')">
              <div class="thanks-container__doctor-btn">
                <div class="svgContainer">
                  <svg xmlns="http://www.w3.org/2000/svg" width="18.306" height="28.5" viewBox="0 0 18.306 28.5">
                    <g id="smartphone_2_" data-name="smartphone (2)" transform="translate(-62.95 0.25)">
                      <path id="Path_243" data-name="Path 243" d="M83.555,6.4H71.684A2.485,2.485,0,0,0,69.2,8.884V30.948a2.485,2.485,0,0,0,2.484,2.484H83.555a2.485,2.485,0,0,0,2.484-2.484V8.852A2.5,2.5,0,0,0,83.555,6.4Z" transform="translate(-5.516 -5.884)" fill="#fff"/>
                      <g id="Group_4816" data-name="Group 4816" transform="translate(63.2)">
                        <path id="Path_246" data-name="Path 246" d="M78.039,0H66.168A2.96,2.96,0,0,0,63.2,2.968V25.032A2.96,2.96,0,0,0,66.168,28H78.039a2.96,2.96,0,0,0,2.968-2.968V2.968A2.981,2.981,0,0,0,78.039,0ZM64.2,4.935H80.006V21.774H64.2ZM66.168,1H78.039a1.955,1.955,0,0,1,1.968,1.968v1H64.2v-1A1.955,1.955,0,0,1,66.168,1ZM78.039,27H66.168A1.955,1.955,0,0,1,64.2,25.032V22.774H80.006v2.258A1.955,1.955,0,0,1,78.039,27Z" transform="translate(-63.2)" fill="#1aaa9d" stroke="#1aaa9d" stroke-width="0.5"/>
                      </g>
                    </g>
                  </svg>
                  <svg xmlns="http://www.w3.org/2000/svg" width="11.238" height="10.883" viewBox="0 0 11.238 10.883" class="hearth">
                    <g id="Group_4816" data-name="Group 4816" transform="translate(8077.25 10853.25)">
                      <path id="Icon_ionic-ios-heart" data-name="Icon ionic-ios-heart" d="M10.812,3.938h-.024A2.784,2.784,0,0,0,8.464,5.21,2.784,2.784,0,0,0,6.139,3.938H6.115A2.766,2.766,0,0,0,3.375,6.7,5.955,5.955,0,0,0,4.544,9.948a20.493,20.493,0,0,0,3.919,3.775,20.493,20.493,0,0,0,3.919-3.775A5.955,5.955,0,0,0,13.552,6.7,2.766,2.766,0,0,0,10.812,3.938Z" transform="translate(-8080.095 -10856.668)" fill="#fff"/>
                      <path id="Icon_ionic-ios-heart-empty" data-name="Icon ionic-ios-heart-empty" d="M11.222,3.938H11.2A2.937,2.937,0,0,0,8.744,5.28,2.937,2.937,0,0,0,6.292,3.938H6.266A2.918,2.918,0,0,0,3.375,6.854,6.283,6.283,0,0,0,4.609,10.28a21.622,21.622,0,0,0,4.135,3.983,21.622,21.622,0,0,0,4.135-3.983,6.283,6.283,0,0,0,1.234-3.425A2.918,2.918,0,0,0,11.222,3.938ZM12.3,9.854a19.8,19.8,0,0,1-3.552,3.5,19.827,19.827,0,0,1-3.552-3.5,5.569,5.569,0,0,1-1.094-3A2.19,2.19,0,0,1,6.271,4.663h.023a2.164,2.164,0,0,1,1.061.279,2.255,2.255,0,0,1,.785.736.725.725,0,0,0,1.213,0,2.278,2.278,0,0,1,.785-.736A2.164,2.164,0,0,1,11.2,4.663h.023A2.19,2.19,0,0,1,13.4,6.854,5.639,5.639,0,0,1,12.3,9.854Z" transform="translate(-8080.375 -10856.938)" fill="#1aaa9d" stroke="#1aaa9d" stroke-width="0.5"/>
                    </g>
                  </svg>
                </div>
                <div class="thanks-container__doctor-btn-stat upper-1">
                   {{_.get(itemData,'sms_thanks')}}
                </div>
                <div class="tooltip">
                  <div>{{$fn.tr('გაგზავნე სმს')}}</div>
                  <span><i></i></span>
                </div>
              </div>
            </div>
            <div class="thanks-container__doctor-btn-outer" @click="showFormAction('email')" >
              <div class="thanks-container__doctor-btn">
                <div class="svgContainer smsSvg">
                  <svg xmlns="http://www.w3.org/2000/svg" width="23.251" height="28.686" viewBox="0 0 23.251 28.686">
                    <g id="Group_4817" data-name="Group 4817" transform="translate(8032.25 10875.936)">
                      <rect id="Rectangle_363" data-name="Rectangle 363" width="21.52" height="26.811" transform="translate(-8031.227 -10875.936)" fill="#fff"/>
                      <g id="to-do-list" transform="translate(-8032 -10875.5)">
                        <g id="Group_4824" data-name="Group 4824" transform="translate(0 0)">
                          <path id="Path_254" data-name="Path 254" d="M21.165,20.5H1.586A1.588,1.588,0,0,0,0,22.086V46.914A1.588,1.588,0,0,0,1.586,48.5H21.165a1.588,1.588,0,0,0,1.586-1.586V22.086A1.588,1.588,0,0,0,21.165,20.5Zm.4,26.414a.4.4,0,0,1-.4.4H1.586a.4.4,0,0,1-.4-.4V22.086a.4.4,0,0,1,.4-.4H21.165a.4.4,0,0,1,.4.4Z" transform="translate(0 -20.5)" fill="#1aaa9d" stroke="#1aaa9d" stroke-width="0.5"/>
                          <path class="smsAnim" id="Path_255" data-name="Path 255" d="M92.842,89.7c0-.578-.266-1.047-.594-1.047h-12.1c-.328,0-.594.469-.594,1.047s.266,1.047.594,1.047h12.1C92.576,90.742,92.842,90.274,92.842,89.7Z" transform="translate(-74.82 -84.598)" fill="#1aaa9d"/>
                          <path class="smsAnim" id="Path_257" data-name="Path 257" d="M92.842,89.7c0-.578-.266-1.047-.594-1.047h-12.1c-.328,0-.594.469-.594,1.047s.266,1.047.594,1.047h12.1C92.576,90.742,92.842,90.274,92.842,89.7Z" transform="translate(-74.82 -72.598)" fill="#1aaa9d"/>
                          <path class="smsAnim" id="Path_256" data-name="Path 256" d="M87.1,89.7c0-.578-.151-1.047-.338-1.047H79.887c-.187,0-.338.469-.338,1.047s.151,1.047.338,1.047h6.877C86.95,90.742,87.1,90.274,87.1,89.7Z" transform="translate(-74.82 -78.598)" fill="#1aaa9d"/>
                        </g>
                      </g>
                    </g>
                  </svg>
                </div>
                <div class="thanks-container__doctor-btn-stat upper-1">
                  {{_.get(itemData,'sent_thanks')}}
                </div>
                <div class="tooltip">
                  <div>{{$fn.tr('გაგზავნე ემაილი')}}</div>
                  <span><i></i></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import popupWrapper from "../components/popupWrapper";
import ClapAnimation from "../components/ClapAnimation";
import url from "@/mixins/url";

export default {
  components: {
    popupWrapper,
    ClapAnimation
  },
  mixins: [url],
  data() {
    return {
      applause: this.itemData.applause,
      anim: false,
    };
  },
  props:['itemData'],

  mounted() {
    // console.log(this.itemData);
    // console.log(parseInt(this.$route.params.id) ,'==', parseInt(this.itemData.id));
    if(parseInt(this.$route.params.id) == parseInt(this.itemData.id)){
      this.showMoreAction();
    }
  },

  methods: {
    showMoreAction() {

      this.smartSingleUrl('story', this.itemData );

      this.$store.commit('main/setState', {key: 'showMorePopup', val: true});
      this.$store.commit('main/setState', {key: 'popupDoctorInfo', val: this.itemData});

    },
    showFormAction(to) {
      // this.smartSingleUrl('story', this.itemData, to );

      this.$store.commit('main/setState', {key: 'popupDoctorInfo', val: this.itemData});
      this.$store.commit('main/setState', {key: 'showFormPopup', val: {state: true, to: to}});
    },
    showSocialAction(){
      this.$store.commit('main/setState', {key: 'showSocialPopup', val: true});
      this.$store.commit('main/setState', {key: 'popupDoctorInfo', val: this.itemData});
    },
    async sendApplause(){

      let tmp = await this.$fn.postData('main/applause', {id: this.itemData.id });
      this.$store.dispatch('main/getFeedbackCounts')

      if(tmp.status == 200){
        this.applause = tmp.data
        this.anim = true
        setTimeout(() => this.anim = false, 1600);

      }else{

      }
    }

  },

};
</script>
