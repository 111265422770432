import Vue from 'vue'
import fn from "../../helpers/func";
import _ from 'lodash';

export default {
  getPagedContent: async function (context, params) {
    /// params::::
    // id: menu id;
    // place: primary or one of secondary place name;
    // page: selected page id;
    // terms: selected terms ids array;
    // search: searched word

    /// request for content
    let res = await fn.postData('main/getDataList', params);

    if (res.status != 200) {
      Vue.set(context.state, 'current_content_request_status', 'error');
      return false;
    }

    // console.log(context.state.current_content);
    /// set paged content to primary
    if (params.placeName && params.placeName == 'primary') {
      Vue.set(context.state.current_content, 'primary', res.data.list);
      Vue.set(context.state.current_content.primary_conf, 'listCount', res.data.listCount);

    } else if (_.get(context.state.current_content, ['secondary', params.placeName])) {
      /// set paged content to secondary place
      Vue.set(context.state.current_content.secondary[params.placeName], 'data', res.data.list);
      Vue.set(context.state.current_content.secondary.conf, 'listCount', res.data.listCount);
    }

  },

  /// get paged content depend just on request params
  getJustPagedContent: async function (context, params) {
    /// params::::
    /// request for content
    let res = await fn.postData('main/getDataList', params);

    if (res.status != 200) {
      Vue.set(context.state, 'current_content_request_status', 'error');
      return false;
    }

    // console.log(context.state.current_content);
    /// set paged content to primary
    if (params.placeName && params.placeName == 'primary') {
      Vue.set(context.state.current_content, 'primary', res.data.list);
      Vue.set(context.state.current_content.primary_conf, 'listCount', res.data.listCount);

    } else if (_.get(context.state.current_content, ['secondary', params.placeName])) {
      /// set paged content to secondary place
      Vue.set(context.state.current_content.secondary[params.placeName], 'data', res.data.list);
      Vue.set(context.state.current_content.secondary.conf, 'listCount', res.data.listCount);
    }

  },

  getFeedbackCounts: async function (context) {
    if (!context.state.indx.feedback_counts);

    let tmp = await fn.postData('main/getfeedbackcounts');
    if (tmp.status != 200) return false;

    Vue.set(context.state.indx, 'feedback_counts', tmp.data)

  },

  //// get comparable products
  getComparables: async function (context, params) {
    /// params::::
    // id: product ids;

    if (_.get(context.state.cache, 'comparable_' + context.state.breadcrumbsLeveled.lvl2)) return false;
    /// request for content
    let res = await fn.postData('main/getComparables', params);

    if (res.status != 200) {
      return false;
    }
    Vue.set(context.state.cache, 'comparable_' + context.state.breadcrumbsLeveled.lvl2, res.data);

  },

  getCurrentContent: async function (context, params) {

    /// check content id. if false leave empty content
    if (!params || !params.id) {
      return false;
    }

    let request = { contentid: params.id, selectedlan: context.state.locale };
    if (params.routeParams) {
      for (let i in params.routeParams) {
        request[i] = params.routeParams[i];
      }
    }

    let current_content_last_request = JSON.stringify(request);
    if (current_content_last_request == context.state.current_content_last_request) return false;

    Vue.set(context.state, 'current_content_last_request', current_content_last_request);
    Vue.set(context.state, 'current_content_request_status', 'pending');

    /// request for content
    let res = await fn.postData('main/getCurrentContent', request);
    // console.log(res);
    /// if response failed leave empty content and return false
    if (res.status != 200) {
      Vue.set(context.state, 'current_content', {});
      Vue.set(context.state, 'current_content_request_status', 'error');

      return false;
    }

    // Vue.set(context.state, 'current_content_request_status', 'changing');

    /// if success response set content
    Vue.set(context.state, 'current_content', res.data);
    // console.log(context.state.current_content_request_status);
    Vue.set(context.state, 'current_content_request_status', 'done');

  },

  getCurrentContentProto: async function (context, params) {

    /// check content id. if false leave empty content
    if (!params || !params.id) {
      return false;
    }

    let request = { contentid: params.id, selectedlan: context.state.locale };
    if (params.routeParams) {
      for (let i in params.routeParams) {
        request[i] = params.routeParams[i];
      }
    }

    let current_content_last_request = JSON.stringify(request);
    if (current_content_last_request == context.state.current_content_last_request) return false;

    Vue.set(context.state, 'current_content_last_request', current_content_last_request);
    Vue.set(context.state, 'current_content_request_status', 'pending');

    /// request for content
    let res = await fn.postData('main/getCurrentContent', request);
    // console.log(res);
    /// if response failed leave empty content and return false
    if (res.status != 200) {
      Vue.set(context.state, 'current_content', {});
      Vue.set(context.state, 'current_content_request_status', 'error');

      return false;
    }

    // Vue.set(context.state, 'current_content_request_status', 'changing');

    /// if success response set content
    Vue.set(context.state, 'current_content_buffer', res.data);
    // console.log(context.state.current_content_request_status);
    Vue.set(context.state, 'current_content_request_status', 'done');

  },

  getTranslations: async function (context) {
    if (context.state.translations) return false;
    let tmp = await fn.postData('main/getTranslations');
    if (tmp.status != 200) return false;
    context.state.translations = tmp.data;
  },

  loadXrates: async function (context) {
    if (context.state.getXrates) return false;
    let tmp = await fn.postData('main/getXrates');
    if (tmp.status != 200) return false;
    context.state.getXrates = tmp.data;

  },

  indx: async function (context) {

    if (context.state.indx.locale !== undefined) {
      return;
    }

    let tmp = await fn.postData('main/indx');
    if (tmp.status != 200) return false;
    context.state.indx = tmp.data;

    if (!_.get(context.state.indx.locales, context.state.locale)) {
      context.state.locale = _.get(tmp.data, 'locale');
    }
    context.commit('setMenuFullUrls');
  },

  indxTranslatable: async function (context) {

    let tmp = await fn.postData('main/indxTranslatable');
    if (tmp.status != 200) return false;
    // context.state.indx = tmp.data;

    for (let i in tmp.data) {
      Vue.set(context.state.indx, i, tmp.data[i]);
    }

  },

}
