import Vue from 'vue';
import VueScrollTo from 'vue-scrollto';
import vClickOutside from 'v-click-outside';
import PortalVue from 'portal-vue';
import VueMq from 'vue-mq';
import VueMeta from 'vue-meta';
import ElementUI from 'element-ui';
import locale from 'element-ui/lib/locale/lang/en';
import VueAwesomeSwiper from 'vue-awesome-swiper';
import Swiper, { Navigation, Pagination,Autoplay } from 'swiper';
import get from 'lodash/get';
import LightGallery from 'vue-light-gallery';
import lightgallery from 'lightgallery.js'
import 'lightgallery.js/dist/css/lightgallery.min.css'
import 'lg-video.js'
import moment from 'moment';
import VueSocialSharing from 'vue-social-sharing'
 



import fn from '@/helpers/func';
import Breakpoints from '@/mixins/Breakpoints';



Vue.use(VueScrollTo, {
  container: 'body',
  duration: 500,
  easing: 'ease',
  offset: 0,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true
})
Vue.use(vClickOutside);
Vue.use(PortalVue);
Vue.use(VueMq, {
  breakpoints: {
    phone: 767,
    tablet: 1023,
    md: 1366,
    lg: 1367,
  },
  defaultBreakpoint: 'sm' // customize this for SSR
});
Vue.use(VueMeta);
Vue.use(ElementUI, { locale });
Vue.use(ElementUI, { en: 'en' });
Vue.use(VueAwesomeSwiper);
Swiper.use([Navigation, Pagination,Autoplay]);
Vue.use(LightGallery);
Vue.mixin(Breakpoints);
Vue.use(VueSocialSharing);


Vue.prototype.get = get;
Vue.prototype.$fn = fn;

