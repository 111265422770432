<template>
  <div class="tab-width" @click="tabClicker(2)" :class="{ current: $store.state.layout.tab == 2 }">
    <div class="doctor-tab-title noto-bold f-14" >
      <div class="title-content">
        <div class="row">
          {{$fn.tr('მედპერსონალი')}}
        </div>
        <div class="row input-search">
          <div class="relative">
            <input type="text" :placeholder="$fn.tr('ძიება')" v-model="filterWord">
          </div>
          <div class="search-icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="10.906" height="10.906" viewBox="0 0 10.906 10.906">
              <g id="Icon_feather-search" data-name="Icon feather-search" transform="translate(-3.5 -3.5)">
                <path id="Path_258" data-name="Path 258" d="M12.049,8.274A3.774,3.774,0,1,1,8.274,4.5,3.774,3.774,0,0,1,12.049,8.274Z" transform="translate(0 0)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <path id="Path_259" data-name="Path 259" d="M27.027,27.027l-2.052-2.052" transform="translate(-14.035 -14.035)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
              </g>
            </svg>
          </div>
        </div>

      </div>

    </div>
    <div class="stuff-list">

        <virtual-list style="height: 100%; overflow-y: auto; overflow-x: hidden;"
          :data-key="'id'"
          :data-sources="personal"
          :data-component="itemComponent"
        />

      <popupWrapper
        @closePopup="closePopup"
        :infoData="$store.state.main.popupDoctorInfo"
        :is-form="$store.state.main.showFormPopup.state"
      />
    </div>
  </div>
</template>
<script>
  import popupWrapper from "./popupWrapper";
  import VirtualList from 'vue-virtual-scroll-list';
  import PersonalListItem from "./PersonalListItem";
  import { mapMutations } from "vuex";
  import url from "@/mixins/url";
  export default {
    components: {
      popupWrapper,
      'virtual-list': VirtualList,
    },
    mixins: [url],

    data() {
      return {
        itemComponent: PersonalListItem,
        showMore: false,
        isForm: false,
        filterWord: '',
      }
    },
    computed:{
      personal(){
        let personal = _.get(this.$store.state.main.indx,'personal');

        let res = [];

        for (let i in personal){
          if(_.get(personal[i], 'title').toLowerCase().indexOf(this.filterWord.toLowerCase()) < 0 ) continue;
          res.push(personal[i]);
        }

        return res;

      }

    },
    methods: {
      closePopup() {
        this.$store.commit('main/setState', {key: 'showMorePopup', val: false});
        this.$store.commit('main/setState', {key: 'showSocialPopup', val: false});
        this.$store.commit('main/setState', {key: 'showFormPopup', val: {state: false, to: null}});
        this.$store.commit('main/setState', {key: 'popupDoctorInfo', val: {}});

        this.smartSingleUrl('doctor');
      },
      ...mapMutations({
        setTab: "layout/SET_TAB"
      }),
      tabClicker(num){
        if(this.$store.state.layout.tab != 2){
          this.setTab(num)
        }
      }
    }
  }
</script>
<style >
.thanks-container__doctor-btn .tooltip{
  top: calc(-100% - 10px);
}

@media(min-width: 1900px){
  .stuff-list-svg .svg:last-child .thanks-container__doctor-btn .tooltip{
    left: -356%;
  }
  .stuff-list-svg .svg:last-child .tooltip span{
    left: 25px;
  }
}
@media(max-width: 1900px){
  .thanks-container__doctor-btn .tooltip{
    left: -65%;
    width: 100px;
    font-size: 12px;
  }
  .tooltip span {
    width: 100%;
    display: flex;
    justify-content: center;
    position: absolute;
    left: -15px;
    bottom: -6px;
    z-index: 1;
  }
}
.stuff-list {
  border: 2px solid #d4d4d4;
  opacity: 0.5;
  border-radius: 10px;
  padding: 0 0 10px 20px;
  width: 100%;
  max-height: 800px;
  height: 100%;
  overflow: hidden;
}
.tab-width.current .stuff-list {
  border: 2px solid #1aaa9d;
  opacity: 1;
}
.stuff-list > div{
  padding: 0 10px;
}
.stuff-list > div::-webkit-scrollbar {
  width: 10px;
  border-radius: 10px;
}
.stuff-list > div::-webkit-scrollbar-thumb {
  background: linear-gradient(
    62deg,
    rgba(14, 78, 140, 1) 0%,
    rgba(0, 89, 86, 1) 100%
  );
  border-radius: 10px;
}
.stuff-list::-webkit-scrollbar-track {
  background: #d9d9d9;
  border-radius: 10px;
}
.doctor-tab-title {
  opacity: 0.5;
}
.tab-width.current .doctor-tab-title {
  opacity: 1;
}
.stuff-list .ul {
  padding-right: 10px;
  padding-top: 10px;
  overflow-y: auto;
}
.stuff-list .listitem {
  padding: 30px 20px;
  border-bottom: 2px solid #f7f8f8;
  display: flex;
  justify-content: space-between;
}
.tab-width.current .stuff-list .listitem {
  pointer-events: all;
}
.stuff-list-svg {
  display: flex;
}
.stuff-list-svg {
  display: flex;
  margin-top: 5px;
}
.stuff-list-svg .svg {
  padding: 0 30px;
  border-left: 1px solid #f7f8f8;
  cursor: pointer;
}
.stuff-list-svg .svg:nth-child(1) {
  padding: 0 30px 0 0;
  border-left: none;
}
.stuff-list-svg .svg:nth-child(3) {
  padding: 0 0 0 30px;
}
.svg {
  cursor: pointer;
}
@media (max-width: 1919px) {
  .stuff-list .listitem {
    display: flex;
    flex-direction: column;
  }
  .stuff-list-svg {
    margin-top: 15px;
  }
}
@media (max-width: 1599px) {
  .stuff-list-svg .svg {
    padding: 0 15px;
  }
  .stuff-list-svg .svg:nth-child(1) {
    padding: 0 15px 0 0;
  }
  .stuff-list-svg .svg:nth-child(3) {
    padding: 0 0 0 15px;
  }
  .stuff-list {
    padding: 0;
  }
  .stuff-list .ul {
    padding: 0 10px;
  }
  .stuff-list .listitem {
    padding: 20px 10px;
  }
}
@media (max-width: 1365px) {
  .stuff-list {
    padding: 0 0 0 20px;
  }
  .stuff-list .listitem{
    padding: 20px 15px 20px 10px;
  }
}
@media (max-width: 1023px) {
  .stuff-list {
    border: none;
    padding: 0;
  }
  .stuff-list.current{
    border: none;
  }
  .stuff-list .listitem {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }
  .stuff-list .listitem:first-child .stuff-list-svg .svg .thanks-container__doctor-btn .tooltip{
    top: calc(-100% - -70px);
  }
  .stuff-list .listitem:first-child .tooltip span{
    bottom: 15px;
  }
  .stuff-list .ul{
    padding: 0;
  }
  .stuff-list-svg {
    margin-top: 0;
  }
}
</style>

<style scoped>
  .title-content {
    display: flex;
    justify-content: space-between;

  }
  .title-content .input-search {
    width: 60%;
    max-width: 250px;
  }
  .title-content .input-search {
    position: relative;
  }
  .title-content .input-search input{
    width: 100%;
    font-size: 12px;
    background: transparent;
    border: 1px solid #d4d4d4;
    border-radius: 6px;
    padding: 4px 6px;
    padding-left: 10px;

  }
  .search-icon svg{
    width: 10px;
  }
  .search-icon {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 0px;
    width: 16px;
    background: rgba(14, 78, 140, 1) 100%;
    height: 70%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 4px;


  }
</style>
