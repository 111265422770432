<template>

  <div :class="{open: !$store.state.layout.sidebar}" class="thanks-container__doctorsContainer">
    <Doctor v-for="item in  itemData" :itemData="item"/>

    <popupWrapper
      @closePopup="closePopup"
      :infoData="$store.state.main.popupDoctorInfo"
      :showMore="$store.state.main.showMorePopup"
      :is-form="$store.state.main.showFormPopup.state"
      :is-social="$store.state.main.showSocialPopup"
    />

    <div class="gradBtn thanks-container__button" @click="loadMore" v-if="storyListCount > _.size(storyRawList)">
      <svg xmlns="http://www.w3.org/2000/svg" width="19.051" height="17" viewBox="0 0 19.051 17"><path id="Icon_feather-heart" data-name="Icon feather-heart" d="M17.137,5.735a4.223,4.223,0,0,0-5.974,0l-.814.814-.814-.814A4.224,4.224,0,1,0,3.56,11.709l.814.814L10.348,18.5l5.974-5.974.814-.814a4.223,4.223,0,0,0,0-5.974Z" transform="translate(-0.823 -2.997)" fill="none" stroke="#00aea9" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"></path></svg>
      <span class="upper-1"> {{$fn.tr('მეტი ისტორია')}} </span>
    </div>

  </div>
</template>
<script>
import Doctor from "../components/Doctor";
import popupWrapper from "../components/popupWrapper";
import url from "@/mixins/url";
import fn from "@/helpers/func";

export default {
  components: {
    Doctor,
    popupWrapper
  },
  mixins: [url],
  data() {
    return {
      popupData: {},
      currentPageNum: 1,
      storyRawList: [],
      storyListCount: 0,
    }
  },

  mounted() {
    this.loadSingle();
  },

  watch:{
    "$store.state.main.indx": {
      immediate: true,
      handler: function (){

        this.storyRawList = _.get(this.$store.state.main.indx,'home_content.home_content.original.primary.data.list');
        this.storyListCount = _.get(this.$store.state.main.indx,'home_content.home_content.original.primary.data.listCount');
      }
    }
  },
  computed: {
    itemData(){
      // let arr =  _.get(this.$store.state.main.current_content.primary,'data.list');
      // let arr =  _.get(this.$store.state.main.indx,'home_content.home_content.original.primary.data.list');
      let arr = this.storyRawList;
      let personals = _.get(this.$store.state.main.indx,'personal');
      for(let i in arr){
          arr[i]['sent_thanks'] = 0;
          arr[i]['sms_thanks'] = 0;
          arr[i]['applause'] = 0;

        let setStatistic = function(){

          let applauseCount = 0;
          let thanksCount = 0;
          let smsCount = 0;

let relatedPersonal = [];
          if(_.get(arr[i], 'relations.personal'))relatedPersonal = arr[i].relations.personal;
          if(!_.size(relatedPersonal) && _.get(arr[i], 'personal')) relatedPersonal = arr[i].relations.personal;
          if(!_.size(relatedPersonal)) return false;

          if(!personals) return false;

          for(let j in relatedPersonal){
              let result = personals.find(item => item.id == relatedPersonal[j]);
              applauseCount = applauseCount + parseInt(result.applause);
              thanksCount = thanksCount + parseInt(result.sent_thanks);
              smsCount = smsCount + parseFloat(result.sms_thanks);
          }

          arr[i]['sent_thanks'] = parseInt(thanksCount)?thanksCount:0;
          arr[i]['sms_thanks'] = parseInt(smsCount)?smsCount:0;
          arr[i]['applause'] = parseInt(applauseCount)?applauseCount:0;
        };
        setStatistic();

      }

      return arr;
    }
  },
  methods: {
    closePopup(){
      this.$store.commit('main/setState', {key: 'showMorePopup', val: false});
      this.$store.commit('main/setState', {key: 'showSocialPopup', val: false});
      this.$store.commit('main/setState', {key: 'showFormPopup', val: {state: false, to: null}});
      this.$store.commit('main/setState', {key: 'popupDoctorInfo', val: {}});


      this.smartSingleUrl('story');

    },
    setStatistic(){

    },

    /// loads next portion
    async loadMore(){
      let arr =  _.get(this.$store.state.main.indx,'home_content.home_content.original.primary.conf');

      let params = {
        pageNumber: (this.currentPageNum + 1),
        perPage: _.get(arr, 'page_count'),
        orderBy: 'date',
        orderDirection: _.get(arr, 'page_order'),
        contentType: _.get(arr, 'contentType'),

      };


      let res = await this.$fn.postData('main/getContentPerPage', params);
      if(res.status != 200)return false;

      let singleInsertedId = _.get(_.last(this.storyRawList), 'id');

      this.currentPageNum++;
      for(let i in res.data.list){
        if(singleInsertedId === res.data.list[i].id)continue;
        this.storyRawList.push(res.data.list[i])
      }
    },

    /// loads single content
    async loadSingle(){

      let id = parseInt(this.$route.params.id);

      /// if single story id does not exists in url do nothing
      if(!id)return false;

      /// find single story
      let story = _.find(this.storyRawList, {id: id})

      /// if story found do nothing
      if(story)return false;

      let params = {
        id: id,
      };
      let res = await this.$fn.postData('main/getSingleContent', params);
      if(res.status != 200)return false;

      for(let i in res.data.list){
        this.storyRawList.push(_.get(res.data, 'list.0'))
      }

    }



  }
};
</script>
<style scoped>
.tabs {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 767px) {
  .tabs {
    flex-direction: column;
  }
}
</style>
