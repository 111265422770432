export default {
  data() {
    return {
      menuStatus: { level1: 0, level2: 0, level3: 0, level4: 0 },
      nestedMenus: {},
      rootMenus: [],
      topMenus: [],
      footerMenus: [],
      footerBottom:[],
      menuTitles: {},
      pathEndKeywords: ['paging', 'singleview', 'content-category', 'productview'],
    }
  },

  watch: {

    "$store.state.main.selected_menu": {
      handler() {
        this.getActiveLevel()
      }
    }

  },

  methods: {

    getMenuFlat: function (menuName, pid) {
      // console.log('runed getMenuFlat; pid:', pid, menuName);

      if (!this.$store.state.main.indx.menus || !menuName) return [];

      let menus = this.$store.getters['main/getList']({ key: 'indx.menus' });
      let locale = this.$store.state.main.locale;

      let res = [];
      for (let i in menus) {
        /// if requested by menu name
        if (menus[i].menu_type != menuName) continue;

        /// if requested menu under parent id
        // if (parseInt(pid) != NaN && menus[i].pid != pid) continue;
        if (pid != undefined && menus[i].pid != pid) continue;

        res.push(this.localizedMenuItem(menus[i]));

      }
      res = _.orderBy(res, ['sort'], ['asc']);


      return res;
    },

    getMenuFlat2: function (menuName, pid) {

      if (!this.$store.state.main.indx.menus || !menuName) return [];
      let menus = this.$store.getters['main/getList']({ key: 'indx.menus' });
      let locale = this.$store.state.main.locale;
      // console.log(menus);
      let res = [];
      for (let i in menus) {
        /// if requested by menu name
        if (menus[i].key != menuName) continue;

        /// if requested menu under parent id
        if (pid != undefined && menus[i].pid != pid) continue;

        res.push(this.localizedMenuItem(menus[i]));
        // res.push(menus[i]);

      }
      res = _.orderBy(res, ['sort'], ['asc']);


      return res;
    },

    makeMenuTree: function () {

      let data = this.getMenuFlat('main_menu');

      data = _.orderBy(data, ['sort'], ['asc']);

      if (typeof data != 'object') return [];
      const idMapping = data.reduce((acc, el, i) => {
        acc[el.id] = i;
        return acc;
      }, {});
      let root = [];
      data.forEach(el => {

        if (idMapping[el.pid] == undefined) {
          el.pid = 0;
        }
        // Handle the root element
        if (!el.pid || el.pid === null || el.pid == 0) {
          root.push(el);
          return;
        }


        // Use our mapping to locate the parent element in our data array
        const parentEl = data[idMapping[el.pid] && el.pid != el.id ? idMapping[el.pid] : 0];
        // Add our current el to its parent's `children` array
        parentEl.children = [...(parentEl.children || []), el];
      });

      return root;
    },

    setNestedMenus() {
      this.nestedMenus = {};
      let locale = this.$store.state.main.locale;

      // let menu = this.getMenuFlat("main");
      let menu = this.$store.getters['main/getList']({ key: 'indx.menus' });

      for (let i in menu) {
        if (!this.nestedMenus[menu[i].pid]) this.nestedMenus[menu[i].pid] = [];
        this.nestedMenus[menu[i].pid].push(this.localizedMenuItem(menu[i]));

        // this.menuTitles[menu[i].id] = {title: _.get(menu[i], ['title_' + locale]), page: _.get(menu[i], ['primary_content', 'page']) };
      }
      // console.log(this.menuTitles);
      this.rootMenus = this.getMenuFlat('main', 0);
      this.topMenus = this.getMenuFlat('topmenu', 0);
      this.footerMenus = this.getMenuFlat('footer', 0);
      this.footerBottom = this.getMenuFlat('footer_bottom', 0);
    },

    getMenuById: function (id) {
      let menu = _.find(this.$store.state.main.indx.menus, { id: id });
      return this.localizedMenuItem(menu);

    },

    localizedMenuItem(menuItem) {

      let locale = this.$store.state.main.locale;
      return {
        title: _.get(menuItem, ['titles',locale, 'title']),
        teaser: _.get(menuItem, ['titles',locale, 'teaser']),
        url: _.get(menuItem, ['url_slug']),
        // full_url: '/'+locale+'/'+this.getFullUrl(menuItem, locale),
        full_url: _.get(menuItem, ['full_url']),
        full_url_clean: _.get(menuItem, ['full_url_clean']),
        image: _.get(menuItem, ['image_' + locale]),
        css: _.get(menuItem, ['configs']),
        id: _.get(menuItem, ['id']),
        sort: _.get(menuItem, ['sort']),
        pid: _.get(menuItem, ['pid']),

        // content_type: _.get(menuItem, ['primary_data', 'content_type']),
        // post_type: _.get(menuItem, ['post_type']),
        // single_slug: _.get(menuItem, ['single_slug']),
        primary_content: _.get(menuItem, ['primary_data']),
        secondary_content: _.get(menuItem, ['secondary_data']),
        secondary_template: _.get(menuItem, ['secondary_template']),
        media: _.get(menuItem, ['media']),
        seo: _.get(menuItem, ['seo', locale]),
        shortlink: _.get(menuItem, ['shortlink']),
      }
    },

    hasChildren(id) {
      let result = 0;
      for (let i in this.$store.state.main.indx.menus) {
        if (id == this.$store.state.main.indx.menus[i].pid) result++
      }
      return result

    },

    getFullUrl(menu, locale) {

      if (menu.full_url) return menu.full_url;
      if (!menu.id) return false;
      let tmp = menu;
      tmp = this.$store.getters['main/getFromList']({ key: 'indx.menus', val: menu.id });

      return _.get(tmp, 'full_url');
      //
      // let paths = [_.get(menu, 'url_' + locale)];
      // while (loop++ < 20) {
      //   tmp = this.$store.getters.getFromList({ key: 'indx.menus', val: tmp.pid });
      //   if (!tmp) break;
      //   paths.unshift(_.get(tmp, 'url_' + locale))
      //   if (!tmp.pid) break;
      // }
      //
      // return paths.join('/');
    },

    getFullUrl2(menuId) {

      // let loop = 0;
      let tmp = this.$store.getters['main/getFromList']({ key: 'indx.menus', val: menuId });
      return _.get(tmp, 'full_url');
      //
      // let paths = [_.get(tmp, 'url_' + this.$store.state.locale)];
      // while (loop++ < 20) {
      //   tmp = this.$store.getters.getFromList({ key: 'indx.menus', val: tmp.pid });
      //   if (!tmp) break;
      //   paths.unshift(_.get(tmp, 'url_' + this.$store.state.locale))
      //   if (!tmp.pid) break;
      // }
      //
      // return paths.join('/');
    },

    getMenuFromPath() {
      let menus = this.$store.getters['main/getList']({ key: 'indx.menus' });
      let locale = this.$store.state.main.locale;
      let path = this.getPath(1);
      let pathNoLan = this.getPath();

      // console.log('getMenuFromPath: ', path, pathNoLan, locale );
      let selectedMenu = {};
      for (let i in menus) {
        // console.log('LOOPED ',pathNoLan, menus[i].full_url, menus[i].regular_path );
        // if (path == menus[i].regular_path_localized && !_.get(menus[i], 'shortlink')) {
        if (pathNoLan == '/' && _.get(menus[i], 'set_home') == 1) {
          selectedMenu = this.localizedMenuItem(menus[i]);
          break;
        } else if (pathNoLan == menus[i].regular_path) { /// && !_.get(menus[i], 'shortlink')
          selectedMenu = this.localizedMenuItem(menus[i]);
          break;
          // return menus[i];
        }
      }

      if (_.get(selectedMenu, 'shortlink') == 1) {
        // console.log('REDIRECTED to:', selectedMenu.full_url);
        this.$router.push(selectedMenu.full_url);
        return false;
      }
      // console.log('found menu with path: ', selectedMenu.full_url, locale);

      return selectedMenu;

    },

    getBreadCrumbs(menu) {
      if (!menu || !menu.id) return false;
      let menus = this.$store.getters['main/getList']({ key: 'indx.menus' });
      let ret = [menu];

      if (!menu.pid) return ret;

      let lastMenu = menu;
      let j = 0;

      while (true) {
        for (let i in menus) {
          if (menus[i].id != lastMenu.pid) continue;
          lastMenu = menus[i];
          ret.unshift(this.localizedMenuItem(lastMenu));
          break;
        }
        if (++j > 50) break;
        if (!lastMenu.pid) break;
      }

      return ret;
    },

    getPath(withLan) {
      let path = [];
      // console.log(this.$route.params);

      for (let i in this.$route.params) {
        // console.log(this.$route.params[i]);
        // console.log(this.pathEndKeywords.indexOf(this.$route.params[i]));

        if (parseInt(this.$route.params[i])) break;
        if (this.pathEndKeywords.indexOf(this.$route.params[i]) >= 0) break;
        if (!this.$route.params[i]) break;

        path.push(this.$route.params[i]);
      }

      if (withLan) path.unshift(this.$route.name);

      return '/' + path.join('/');
    },

    setUrl(data){
      let params = Object.values(JSON.parse(JSON.stringify(this.$route.params)));

      let additional = [];
      for (let i in data){
        let index = _.indexOf(params, i);
        if( index >=0 ){
          params[index+1] = data[i];
        }else{
          additional.push('/'+i+'/'+data[i]);
        }
      }

      return'/'+this.$route.name+'/'+params.join('/')+additional.join('/');

    },

    getActiveLevel() {
      // return false;
      // console.log(this.$store.state.selected_menu)

      // console.log(this.getMenuFlat('main', this.$store.state.selected_menu.pid))
      let result = 0;
      let secLvl = null;
      for (let i in this.$store.state.main.indx.menus) {

        if (this.$store.state.main.indx.menus[i].id == this.$store.state.main.selected_menu.pid) {
          if (this.$store.state.main.indx.menus[i].pid == 0) {
            this.$store.commit('main/setMenuStatus', { level: 1, val: this.$store.state.main.indx.menus[i].id })
            secLvl = this.$store.state.main.selected_menu.id;
            this.$store.commit('main/setMenuStatus', { level: 2, val: secLvl })
          } else {
            secLvl = this.$store.state.main.indx.menus[i].pid;
            for (let e in this.$store.state.main.indx.menus) {
              if (this.$store.state.main.indx.menus[e].id == secLvl) {
                this.$store.commit('main/setMenuStatus', { level: 1, val: this.$store.state.main.indx.menus[e].pid })
                this.$store.commit('main/setMenuStatus', { level: 2, val: secLvl })

              }
            }
          }
        }

      }
      return
    },


  }

};
