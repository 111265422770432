<template>
  <div class="popup" :class="{ show: showPopup }">
    <div class="popup-wrapper stl_scrl">
      <div class="cross" @click="$emit('closePopup')">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="15.424"
          height="15.424"
          viewBox="0 0 15.424 15.424"
        >
          <path
            id="Icon_metro-cancel"
            data-name="Icon metro-cancel"
            d="M22.815,9.64l-4.82,4.82-4.82-4.82-2.892,2.892,4.82,4.82-4.82,4.82,2.892,2.892,4.82-4.82,4.82,4.82,2.892-2.892-4.82-4.82,4.82-4.82Z"
            transform="translate(-10.283 -9.64)"
            fill="#1aaa9d"
          />
        </svg>
      </div>

      <div class="main-content max-width-container padding-center">
        <div class="main-content-title noto-bold">{{$fn.tr('მადლობა')}}</div>
        <div class="main-content-sub-title noto-bold">{{$fn.tr('პროექტში ჩართულობისთვის')}}</div>
        <div class="name-list" v-html="_.get($store.state.main.indx.home_content.home_content.original.secondary.usingInstruction,'data.list.0.content.tabs.0.content')">
<!--          <div class="name-list-item noto-bold">ვერა ნადირაშვილს</div>-->
<!--          <div class="name-list-item noto-bold">გიორგი ხაჭაპურიძეს</div>-->
<!--          <div class="name-list-item noto-bold">ექიმებს</div>-->
<!--          <div class="name-list-item noto-bold">ექთნებს</div>-->
<!--          <div class="name-list-item noto-bold">მედპერსონალს </div>-->
<!--          <div class="name-list-item noto-bold">გიორგი ხაჭაპურიძე</div>-->
<!--          <div class="name-list-item noto-bold">გიორგი ხაჭაპურიძე</div>-->
<!--          <div class="name-list-item noto-bold">გიორგი ხაჭაპურიძე</div>-->
<!--          <div class="name-list-item noto-bold">ექიმებს</div>-->
<!--          <div class="name-list-item noto-bold">ექთნებს</div>-->
<!--          <div class="name-list-item noto-bold">მედპერსონალს </div>-->
<!--          <div class="name-list-item noto-bold">გიორგი ხაჭაპურიძე</div>-->
<!--          <div class="name-list-item noto-bold">გიორგი ხაჭაპურიძე</div>-->
<!--          <div class="name-list-item noto-bold">ექიმებს</div>-->
<!--          <div class="name-list-item noto-bold">ექთნებს</div>-->
<!--          <div class="name-list-item noto-bold">მედპერსონალს </div>-->
<!--          <div class="name-list-item noto-bold">გიორგი ხაჭაპურიძე</div>-->
<!--          <div class="name-list-item noto-bold">ექიმებს</div>-->
<!--          <div class="name-list-item noto-bold">ექთნებს</div>-->
<!--          <div class="name-list-item noto-bold">მედპერსონალს </div>-->
<!--          <div class="name-list-item noto-bold">გიორგი ხაჭაპურიძე</div>-->
        </div>
      </div>

    </div>
    <div @click="$emit('closePopup')" class="dark-bakcground" />
  </div>
</template>

<script>
  export default {
    props: ['showPopup'],
  }
</script>

<style scoped>
  .popup {
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    visibility: hidden;
    transition: 0.4s ease-in;
  }
  .dark-bakcground {
    opacity: 0;
    visibility: hidden;
    transition: 0.4s ease-in;
  }
  .show.popup {
    opacity: 1;
    visibility: visible;
    transition: 0.4s ease-in;
  }
  .main-content-title {
    font-size: 20px;
    color: #000000;
    padding-bottom: 10px;
  }
  .name-list-item {
    color: #000000;
    font-size: 14px;
    line-height: 17px;
    padding: 24px 0;
  }
  .main-content-sub-title {
    font-size: 14px;
    color: #1AAA9D;
    padding-bottom: 20px;
    border-bottom: 2px solid #F7F8F8;
  }
  .show .dark-bakcground {
    opacity: 0.7;
    visibility: visible;
    transition: 0.4s ease-in;
  }

  .cross svg {
    transition: 0.3s ease;
  }

  .cross:hover svg path {
    fill: #1a9a8e;
    transition: 0.3s ease;
  }

  .dark-bakcground {
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    background-color: #000000;
    opacity: 0.7;
    z-index: 9;
  }

  .padding-center {
    padding: 0 15%;
  }

  .popup-wrapper {
    width: 80vw;
    margin: auto;
    display: flex;
    flex-direction: column;
    height: 90vh;
    overflow: auto;
    background-color: #ffffff;
    z-index: 10;
    position: relative;
  }

  .popup-wrapper .cross {
    position: absolute;
    right: 25px;
    top: 25px;
    cursor: pointer;
  }

  .main-content {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 25px;
    height: 620px;
    min-height: 620px;
    max-height: 620px;
    width: 100%;
  }

  .max-width-container {
    max-width: 840px;
    margin: 0 auto auto;
  }



  @media screen and (max-width: 1359px) {

    .main-content {
      height: unset;
      min-height: unset;
      max-height: unset;
    }
  }
  @media screen and (max-width: 767px) {
    .popup-wrapper .cross {
      top: 5px;
      right: 5px;
    }
    .popup-wrapper {
      width: 95vw;
      height: 95vh;
    }
  }
</style>
